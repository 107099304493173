import { Button } from "theme-ui";
import { Link } from "gatsby";
import Infographic from "components/infographic";
import { UrbanHealthOne, UrbanHealthTwo, UrbanHealthFive, UrbanHealthSix } from "components/library/urban-health";
import UrbanHealth3 from "components/svgs/urban-health-icon-4";
import UrbanHealth6 from "components/svgs/urban-health-icon-6";
import * as React from 'react';
export default {
  Button,
  Link,
  Infographic,
  UrbanHealthOne,
  UrbanHealthTwo,
  UrbanHealthFive,
  UrbanHealthSix,
  UrbanHealth3,
  UrbanHealth6,
  React
};