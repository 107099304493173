/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="203"
    height="79"
    viewBox="0 0 203 79"
    fill="none"
    className={className}
  >
    <path
      fill="#E31B23"
      d="M0 57.727V4.652c0-.403.064-.807.223-1.177C2.008-.677 5.552-.022 7.433.996c.223.12.414.276.595.453 8.263 8.117 24.713 24.363 26.492 26.462a5.23 5.23 0 011.028 4.976c-.115.362-.349.668-.617.937l-3.884 3.878L9.396 15.875V57.74c0 .645-.17 1.279-.545 1.803-3.213 4.487-6.554 2.263-8.295-.015-.392-.513-.556-1.155-.556-1.8z"
    ></path>
    <path
      fill="#FBB034"
      d="M49.426 4.84v53.076c0 .403-.063.808-.222 1.178-1.786 4.15-5.33 3.496-7.211 2.478a2.648 2.648 0 01-.595-.453c-8.263-8.117-24.713-24.363-26.491-26.462a5.23 5.23 0 01-1.029-4.976c.115-.362.349-.668.617-.936l3.884-3.88L40.03 46.694V4.829c0-.645.17-1.278.545-1.803 3.213-4.487 6.555-2.263 8.295.015.392.513.556 1.155.556 1.8z"
    ></path>
    <path
      fill="#FBB034"
      d="M31.545 24.877L25.01 18.37c.887-.934 2.658-2.743 3.815-3.82a2.804 2.804 0 011.58-.744c5.3-.616 6.14 3.022 5.813 5.652-.08.638-.421 1.204-.878 1.656l-3.795 3.762z"
    ></path>
    <path
      fill="#E31B23"
      d="M17.882 37.79l6.535 6.506c-.887.934-2.658 2.743-3.815 3.82a2.804 2.804 0 01-1.581.744c-5.3.616-6.139-3.022-5.812-5.652.08-.638.421-1.204.877-1.656l3.796-3.762z"
    ></path>
    <path
      fill="#E21C25"
      d="M97.874 33.108c-.734-.217-1.408-.276-1.92-.595-.807-.507-.965-1.43-.163-1.924 1.4-.855 3.073-.821 4.49-.123 1.255.617.879 1.94.576 3.047-.657 2.387-2.292 3.706-4.733 3.868-2.287.153-4.289-1.093-5.215-3.243-1.016-2.357-.59-5.558 1.067-7.158 1.75-1.69 3.875-1.94 6.141-1.264.91.272 1.648.787 2.032 1.728.222.545.034.97-.27 1.379-.336.447-.789.574-1.314.336a12.563 12.563 0 01-.764-.366c-1.276-.694-2.62-1.09-3.61.306-1.029 1.452-1.037 3.099.004 4.575.956 1.37 2.33 1.17 3.679-.566z"
    ></path>
    <path
      fill="#E21C24"
      d="M68.865 30.925c0 1.924.022 3.239-.008 4.558-.021.954-.41 1.677-1.49 1.681-1.037.009-1.318-.791-1.331-1.591a254.387 254.387 0 01-.009-8.27c.009-.689.116-1.468 1.025-1.646.802-.158 1.344.276 1.796.872 1.264 1.677 2.531 3.35 4.166 5.511 0-1.915-.021-3.276.008-4.643.017-.923.312-1.736 1.409-1.766 1.11-.03 1.464.822 1.485 1.707.055 2.758.034 5.515.017 8.269-.004.668-.252 1.276-.939 1.523-.79.285-1.417-.136-1.865-.698-1.323-1.634-2.586-3.323-4.264-5.507zm4.23 18.257c-.606-.098-1.033-.115-1.43-.238-.713-.226-1.319-.587-1.208-1.485.094-.77.696-1.154 1.328-1.15 1.463.01 3.243-.808 4.19 1.013.722 1.38-.418 4.23-1.92 5.256-1.797 1.226-4.584 1.196-6.419-.064-1.78-1.225-2.65-4.162-1.993-6.707.657-2.528 2.083-3.838 4.516-4.072 1.416-.136 2.867-.192 4.105.65.798.546 1.374 1.367.824 2.354-.56 1-1.366.639-2.19.217-1.25-.647-2.667-1.055-3.644.294-.982 1.349-.96 2.966-.111 4.409.905 1.52 2.343 1.396 3.952-.477z"
    ></path>
    <path
      fill="#E31D26"
      d="M73.556 15.188c0-1.132-.043-2.264.013-3.388.051-1.051.034-2.332 1.553-2.272 1.353.05 1.456 1.22 1.349 2.217-.175 1.613.333 2.068 2.044 2.166 2.22.128 2.21-.975 2.134-2.52-.051-.987.235-1.889 1.472-1.898 1.178-.008 1.358.966 1.383 1.788.077 2.609.081 5.226.017 7.835-.021.893-.17 1.962-1.48 1.898-1.23-.06-1.465-.988-1.396-1.966.11-1.57.047-2.613-2.138-2.528-1.772.068-2.186.617-2.036 2.196.102 1.08.008 2.32-1.494 2.28-1.323-.033-1.395-1.187-1.417-2.204-.025-1.204-.008-2.404-.004-3.604z"
    ></path>
    <path
      fill="#E21D26"
      d="M157.2 31.32c0-1.204-.051-2.412.017-3.612.056-.996.154-2.132 1.567-2.056 1.246.068 1.451 1.098 1.318 2.03-.268 1.894.419 2.43 2.309 2.34 1.464-.072 2.113-.39 1.865-1.902-.055-.34-.008-.706.03-1.06.086-.782.448-1.344 1.302-1.43 1.033-.097 1.481.622 1.502 1.422.077 2.903.073 5.805.009 8.708-.017.706-.423 1.4-1.319 1.408-.947.004-1.4-.57-1.498-1.455-.038-.35-.085-.72-.021-1.06.354-1.843-.636-1.996-2.156-2.026-1.57-.034-2.283.353-1.997 1.992.06.34.013.706-.021 1.06-.081.89-.521 1.49-1.464 1.49-.871 0-1.306-.656-1.349-1.388-.085-1.481-.025-2.97-.025-4.46h-.069z"
    ></path>
    <path
      fill="#E21C24"
      d="M102.423 31.279c0-1.345-.025-2.686.009-4.03.021-.818.222-1.567 1.276-1.622 1.003-.051 1.357.596 1.545 1.417.047.204.055.421.077.63.2 2.17 1.109 2.766 3.294 2.306.692-.144.73-.506.734-1.025.009-.634-.038-1.285.069-1.902.145-.818.537-1.494 1.54-1.41.862.078 1.268.695 1.281 1.444.047 2.898.051 5.796 0 8.694-.013.753-.5 1.336-1.345 1.383-.866.051-1.259-.532-1.468-1.264-.077-.268-.064-.557-.081-.838-.141-2.273-.973-2.86-3.226-2.4-.658.132-.794.45-.798 1.004 0 .634.038 1.28-.043 1.907-.12.91-.508 1.663-1.605 1.595-1.011-.063-1.246-.808-1.263-1.642-.026-1.413-.009-2.826-.009-4.243.005-.005.009-.005.013-.005z"
    ></path>
    <path
      fill="#E21D25"
      d="M99 46.11c0-.92-.03-1.843.01-2.762.037-.852.336-1.52 1.361-1.55 1.041-.03 1.395.669 1.446 1.486.094 1.553.056 3.11.09 4.668.03 1.332.154 2.724 1.916 2.737 1.767.008 1.899-1.426 1.955-2.728.064-1.485.004-2.975.034-4.46.017-.928.294-1.685 1.443-1.703 1.066-.017 1.344.745 1.387 1.541.119 2.188.294 4.392-.154 6.558-.521 2.498-2.249 3.703-4.981 3.575-2.577-.12-4.13-1.451-4.442-3.97-.137-1.116-.022-2.26-.022-3.392h-.042z"
    ></path>
    <path
      fill="#E21C24"
      d="M136.002 30.159c0-.779-.03-1.558.008-2.332.043-.983-.042-2.132 1.392-2.188 1.498-.055 1.395 1.12 1.421 2.094.038 1.413-.03 2.83.03 4.239.055 1.268.384 2.528 1.942 2.57 1.698.047 2.044-1.264 2.095-2.621.056-1.481.009-2.966.03-4.452.013-.902.2-1.745 1.323-1.83 1.144-.085 1.489.753 1.524 1.639.076 2.043.247 4.102-.107 6.132-.478 2.733-2.356 4.145-5.134 3.95-2.698-.188-4.277-1.733-4.477-4.452-.069-.915-.009-1.838-.009-2.753a.136.136 0 01-.038.004z"
    ></path>
    <path
      fill="#E31D26"
      d="M77.751 31.346V30.07c0-4.482.363-4.796 4.908-4.367.922.09 1.763.324 1.827 1.384.072 1.187-.922 1.26-1.767 1.374-.418.06-.85.004-1.276 0-.55-.008-.722.298-.73.8-.009.553.265.754.777.74.286-.008.572-.02.853 0 .892.069 1.797.252 1.827 1.358.025 1.115-.9 1.264-1.767 1.451-.589.128-1.647-.493-1.643.69 0 1.34 1.152.626 1.788.749.935.174 2.006.23 1.997 1.447-.008 1.226-1.07 1.353-2.023 1.366-5.855.064-4.673.187-4.771-5.715z"
    ></path>
    <path
      fill="#E21E26"
      d="M85.442 15.277v-1.273c0-4.609.222-4.796 4.81-4.438.956.076 1.77.361 1.84 1.361.076 1.094-.85 1.337-1.742 1.434-.423.047-.85.005-1.276.022-.41.017-.632.225-.657.655-.03.507.153.813.7.817.213.005.426-.008.64.009.943.064 2.001.119 2.023 1.362.02 1.208-.956 1.387-1.947 1.45-.559.035-1.416-.331-1.404.737.009 1.051.858.69 1.421.728 1.085.072 2.373.055 2.28 1.536-.086 1.281-1.29 1.256-2.314 1.277-4.374.098-4.374.102-4.374-4.188v-1.49z"
    ></path>
    <path
      fill="#E31F27"
      d="M67.38 16.324c0-.706-.042-1.417.01-2.12.08-1.08.008-1.914-1.435-1.961-.713-.017-1.391-.485-1.259-1.388.12-.812.755-1.212 1.51-1.242 1.7-.06 3.402-.06 5.105-.022.854.017 1.55.434 1.622 1.354.068.88-.653 1.323-1.357 1.34-1.25.03-1.302.737-1.285 1.669.03 1.693.039 3.391-.013 5.09-.03.94-.2 1.953-1.446 1.961-1.247.013-1.43-.94-1.447-1.923-.017-.92-.005-1.839-.005-2.758zM88.852 31.58c0 1.337.017 2.673-.004 4.013-.017.856-.325 1.537-1.323 1.58-.973.042-1.507-.605-1.524-1.422a171.99 171.99 0 01.004-8.652c.022-.8.474-1.485 1.473-1.477 1.02.009 1.34.673 1.357 1.528.03 1.477.008 2.954.008 4.434 0-.004.005-.004.009-.004z"
    ></path>
    <path
      fill="#E21C24"
      d="M129.092 25.389c-3.239-.013-5.693 2.668-5.633 6.158.06 3.425 2.467 5.902 5.685 5.843 3.286-.06 5.509-2.49 5.488-6.005-.021-3.49-2.326-5.984-5.54-5.997zm.009 9.196c-1.421.034-2.429-1.272-2.424-3.37.149-1.315.358-2.945 2.351-2.967 1.968-.02 2.352 1.55 2.437 3.111.103 1.826-.96 3.196-2.364 3.226zm45.102-9.197c-3.295-.064-5.651 2.49-5.629 6.107.021 3.507 2.296 5.916 5.573 5.894 3.291-.02 5.544-2.438 5.549-5.95 0-3.472-2.279-5.987-5.493-6.05zm-.171 9.18c-1.925-.183-2.368-1.651-2.313-3.196.051-1.49.358-3.052 2.369-3.154 1.438-.072 2.548 1.443 2.513 3.311-.038 1.724-1.203 3.17-2.569 3.039zm12.129-9.179c-3.273-.047-5.629 2.485-5.629 6.056 0 3.557 2.155 5.898 5.484 5.94 3.41.047 5.65-2.344 5.633-6.004-.017-3.46-2.287-5.941-5.488-5.992zm-.043 9.192c-1.4.025-2.522-1.34-2.539-3.094-.021-1.898 1.105-3.387 2.573-3.26 1.929.166 2.237 1.66 2.437 3.013.017 1.915-1.079 3.32-2.471 3.341zm-94.134 7.077c-3.35.009-5.71 2.55-5.629 6.06.081 3.473 2.403 5.801 5.732 5.758 3.397-.042 5.467-2.315 5.433-5.962-.034-3.634-2.143-5.864-5.536-5.856zm-.094 9.04c-1.83-.243-2.364-1.575-2.308-3.21.055-1.582.456-2.991 2.428-3.055 1.587-.051 2.411 1.132 2.368 3.281-.042 1.894-1.045 3.175-2.488 2.983z"
    ></path>
    <path
      fill="#E21D25"
      d="M200.185 26.508c-1.955-1.15-4.208-1.213-6.167-.515-1.848.66-.79 2.74-1.016 4.196-.064.413-.008.847-.008 1.273 0 5.826.418 6.281 5.083 5.553 2.543-.396 4.323-2.123 4.844-4.694.405-2-.82-4.685-2.736-5.813zm-3.081 7.745c-1.404.09-1.191-1.472-1.191-2.97 0-1.498 0-2.655.994-2.784 1.618-.208 2.727 1.03 2.762 2.784.034 1.817-.952 3.034-2.565 2.97zm-74.601-.655c-.457-1.924-1.089-3.745-.879-5.784.076-.762-.722-1.315-1.443-1.68-3.5-1.754-6.047-.256-6.047 3.557v1.694c0 1.268.004 2.536 0 3.804-.005 1.081.499 1.822 1.596 1.847 1.472.035 2.953.175 4.425-.212 1.725-.456 2.719-1.664 2.348-3.226zm-5.476-4.613c.034-.503.265-.754.781-.758.662-.009 1.012.345 1.204.821-.145.673-.508.924-1.059.966-.759.056-.973-.353-.926-1.03zm1.007 5.583c-.747.06-1.003-.298-1.015-1-.013-.787.213-1.26.964-1.085.841-.009 1.511.145 1.532.962.026.936-.73 1.063-1.481 1.123zM85.352 50.957c-.597-.758-1.284-1.447-2.061-2.307 1.822-.843 2.872-2.085 2.258-4.06-.679-2.192-2.45-2.74-4.512-2.74h-.426c-3.005-.018-3.167.136-3.176 3.047-.004 1.698-.076 3.396.026 5.09.073 1.217-.653 3.157 1.319 3.247 2.249.102 1.263-2.022 1.707-3.618 1.016 1.2 1.835 2.234 2.723 3.205.593.65 1.34.804 2.078.204.777-.643.585-1.4.064-2.068zm-4.174-4.035c-.84.047-.896-.48-.887-1.098.008-.642-.09-1.349.909-1.31.764.063 1.472.302 1.446 1.204-.03.847-.665 1.157-1.468 1.204z"
    ></path>
    <path
      fill="#E21C24"
      d="M155.058 34.104c-.385-.553-1.123-.83-1.289-1.643 1.813-.855 2.441-2.306 1.946-4.17-.602-2.26-5.694-3.703-7.46-2.141-.248.217-.513.579-.517.877-.038 2.962-.064 5.924 0 8.886.017.753.598 1.268 1.438 1.268.777 0 1.161-.54 1.298-1.183.154-.74.149-1.511.252-2.698.84 1.098 1.429 1.949 2.104 2.723.683.783 1.468 1.856 2.612.864 1.096-.957.217-1.915-.384-2.783zm-3.722-3.332c-1.067-.017-.696-.83-.747-1.375-.055-.621.107-1.132.913-1.013.747.11 1.37.358 1.328 1.285-.124.869-.7 1.116-1.494 1.103z"
    ></path>
    <path
      fill="#E21D25"
      d="M118.615 44.024c-.982-1.902-2.719-2.298-4.515-2.179-3.363-.012-3.483.102-3.487 3.328 0 2.043-.013 4.086.013 6.133.012.953.162 1.953 1.391 1.987 1.174.034 1.639-.85 1.524-1.851-.18-1.54.64-1.592 1.847-1.702 2.958-.268 4.546-3.158 3.227-5.716zm-4.153 3.052c-1.011.068-.917-.626-.896-1.06-.013-.868-.064-1.566.926-1.473.781.073 1.392.38 1.374 1.286-.012.902-.606 1.196-1.404 1.247z"
    ></path>
    <path
      fill="#FBB138"
      d="M20.857 75.706c-.373-.111-.716-.141-.977-.303-.411-.257-.493-.727-.081-.979.71-.435 1.563-.418 2.287-.064.638.316.45.987.291 1.55-.334 1.214-1.165 1.889-2.411 1.97-1.166.077-2.185-.555-2.656-1.649-.514-1.2-.3-2.828.544-3.643.89-.863 1.974-.987 3.127-.641.462.137.84.401 1.032.88.111.278.017.495-.137.7-.171.227-.403.29-.668.171a6.495 6.495 0 01-.39-.188c-.651-.35-1.336-.555-1.838.158-.522.74-.527 1.576 0 2.328.489.697 1.191.594 1.877-.29zm124.601 0c-.372-.111-.715-.141-.976-.303-.411-.257-.493-.73-.082-.979.711-.435 1.564-.418 2.288-.064.638.316.45.987.291 1.55-.334 1.214-1.165 1.889-2.412 1.97-1.165.077-2.184-.555-2.655-1.649-.514-1.2-.3-2.828.544-3.643.891-.863 1.974-.987 3.127-.641.462.137.839.401 1.032.88.111.278.017.495-.137.7-.171.227-.403.29-.668.171a6.302 6.302 0 01-.39-.188c-.651-.35-1.337-.555-1.838.158-.522.74-.527 1.576 0 2.328.489.697 1.187.594 1.876-.29z"
    ></path>
    <path
      fill="#FBB139"
      d="M154.561 75.42c0-.38-.021-.76.004-1.137.043-.576.005-1.025-.766-1.05-.382-.013-.746-.261-.673-.744.064-.435.403-.649.81-.666a53 53 0 012.733-.013c.458.009.831.235.869.726.039.474-.351.71-.728.718-.673.017-.698.397-.69.893.017.91.022 1.82-.008 2.725-.017.504-.107 1.046-.776 1.05-.668.005-.766-.504-.775-1.029-.009-.491 0-.982 0-1.474zm26.249 0c0-.38-.021-.76.004-1.137.043-.576.005-1.025-.766-1.05-.382-.013-.746-.261-.673-.744.064-.435.403-.649.81-.666a53 53 0 012.733-.013c.458.009.831.235.869.726.039.474-.351.71-.728.718-.673.017-.698.397-.69.893.017.91.022 1.82-.008 2.725-.017.504-.107 1.046-.776 1.05-.668.005-.766-.504-.775-1.029-.004-.491 0-.982 0-1.474z"
    ></path>
    <path
      fill="#FBB23B"
      d="M106.158 75.249c.197-.897.381-1.794.591-2.683.09-.371.368-.692.737-.632.488.077.685.521.574.978a123.272 123.272 0 01-1.127 4.31c-.12.423-.437.756-.934.705-.501-.052-.672-.487-.805-.884-.244-.73-.454-1.47-.72-2.345-.951.619-.552 1.627-.972 2.298-.266.422-.398.956-1.015.927-.484-.026-.737-.39-.84-.833-.325-1.38-.655-2.755-.938-4.144-.086-.414-.034-.922.51-1 .514-.067.715.364.818.791.184.748.347 1.5.54 2.328.942-.965.372-3.05 1.7-3.033 1.645.022.848 2.08 1.5 3.076.055.09.244.094.381.14z"
    ></path>
    <path
      fill="#FBB746"
      d="M30.066 74.98c0 .423.009.85-.004 1.273-.018.628.064 1.482-.677 1.602-.9.145-.887-.735-.887-1.346.004-.893-.488-1.025-1.225-1.025-.72.004-1.028.286-1.032 1-.005.627.004 1.482-.866 1.37-.758-.098-.69-.956-.702-1.58a87.913 87.913 0 010-3.186c.009-.509.103-1.077.694-1.158.642-.09.83.466.848.978.026.773.081 1.341 1.14 1.341 1.023 0 1.156-.504 1.165-1.31.004-.505.18-1.069.826-1.005.604.06.698.624.716 1.132.021.637.004 1.277.004 1.914z"
    ></path>
    <path
      fill="#FBB23B"
      d="M166.705 74.907c-.039-2.405 2.094-3.866 4.159-2.853.454.222 1.041.457.831 1.042-.223.628-.831.41-1.272.192-.844-.414-1.517-.29-2.026.508-.476.748-.39 1.512.094 2.204.398.573.976.91 1.636.415.968-.726-.043-.786-.394-1.081-.227-.188-.381-.483-.133-.73.591-.59 1.345-.36 2.014-.231.595.111.719.68.603 1.192-.321 1.46-1.126 2.456-2.758 2.469-1.649.012-2.729-1.23-2.754-3.127z"
    ></path>
    <path
      fill="#FBB646"
      d="M190.615 75.048c0 .427.025.854-.005 1.277-.042.615.056 1.47-.762 1.525-.745.05-.72-.765-.724-1.295-.009-.854-.437-1.063-1.204-1.072-.818-.013-1.143.3-1.156 1.115-.009.526-.009 1.337-.78 1.226-.664-.094-.6-.863-.604-1.422 0-1.2-.004-2.4.047-3.601.017-.419.163-.91.724-.85.381.038.561.427.596.782.068.777-.047 1.524 1.19 1.529 1.226.004 1.144-.718 1.213-1.508.034-.419.192-.88.762-.807.399.05.583.427.621.773.082.773.09 1.555.125 2.332-.022-.009-.03-.004-.043-.004z"
    ></path>
    <path
      fill="#FBB139"
      d="M138.472 75.369c0-.936-.017-1.406.004-1.871.03-.607-.167-1.504.703-1.504.66.004.6.833.604 1.397.004 1.132.004 2.268 0 3.4 0 .436-.026.927-.501 1.072-.489.15-.75-.299-1.007-.624-.613-.768-1.216-1.55-1.816-2.315-.21.128-.377.167-.411.26-.309.877.496 2.564-.656 2.547-1.379-.022-.698-1.705-.702-2.627-.005-1.043-.716-2.64.557-3.037 1.01-.316 1.422 1.332 2.141 2.071.27.287.523.594 1.084 1.23z"
    ></path>
    <path
      fill="#FBB138"
      d="M1.76 74.685c-.59 1.269.395 3.054-.916 3.063-1.26.004-.63-1.735-.655-2.687-.03-1.004-.609-2.555.424-2.93 1.289-.47 1.512 1.328 2.291 2.046.253.234.45.533.669.803.74-.287.325-.859.402-1.278.12-.644-.24-1.742.716-1.691.84.047.578 1.132.595 1.807.026 1.06 0 2.123-.004 3.182 0 .38-.129.752-.48.876-.368.128-.66-.158-.882-.445-.682-.87-1.363-1.734-2.16-2.746z"
    ></path>
    <path
      fill="#FBB440"
      d="M46.772 74.377c0-.213-.035-.431.004-.636.124-.628-.377-1.73.612-1.743.9-.013.66 1.064.707 1.739.009.14-.004.286.005.427.068.944-.33 2.358 1.053 2.392 1.375.038 1.11-1.359 1.157-2.32.013-.282-.009-.568.008-.854.035-.61-.11-1.46.81-1.414.685.034.672.807.724 1.363.03.354.021.709.013 1.063-.065 2.47-.925 3.648-2.656 3.648-1.76.009-2.429-1-2.437-3.665z"
    ></path>
    <path
      fill="#FBB33D"
      d="M60.615 74.826c0-.427-.02-.854.005-1.281.038-.603-.197-1.491.642-1.56.737-.055.656.786.69 1.333.056.923.685.914 1.362.919.668.008 1.11-.158 1.088-.936-.013-.564-.034-1.375.686-1.328.856.055.655.94.663 1.55.018 1.136 0 2.277-.004 3.413-.004.461-.15.935-.647.94-.488.004-.685-.453-.68-.923.004-.824.012-1.52-1.196-1.512-1.105.009-1.336.517-1.302 1.44.013.427-.081.965-.612.965-.677 0-.66-.62-.677-1.102-.026-.64-.005-1.282-.005-1.918h-.013zm65.058.584c-.073.847-.099 1.27-.15 1.688-.047.372-.227.752-.621.748-.548-.005-.625-.496-.638-.923-.026-.991-.06-1.986-.013-2.977.03-.667-.347-1.696.492-1.88 1.178-.26.489 1.196 1.345 1.803.12-.227.193-.568.403-.718.557-.393.934-1.657 1.748-.931.732.654-.33 1.256-.767 1.666-.955.897-.094 1.307.326 1.888.419.58 1.503 1.2.681 1.913-.771.671-1.033-.602-1.542-.965-.39-.273-.677-.688-1.264-1.311z"
    ></path>
    <path
      fill="#FBB43F"
      d="M92.194 73.079c.192 1.046.865 1.008 1.336 1.26 1.097.58 1.623 1.414.925 2.606-.758 1.298-1.919 1.324-3.157.837-.394-.154-.75-.504-.565-.995.146-.394.54-.385.908-.338.51.06 1.2.62 1.44-.23.218-.77-.558-.855-1.072-1.021-1.028-.334-1.636-.966-1.246-2.06.381-1.071 1.28-1.482 2.394-1.353.497.055 1.097.209 1.097.803 0 .756-.595.59-1.1.5-.254-.048-.523-.01-.96-.01z"
    ></path>
    <path
      fill="#FBB541"
      d="M194.585 75.928c.048.175.048.252.086.299.433.534 1.876-.128 1.576 1.174-.192.838-1.083.364-1.666.458-1.062.17-1.64-.244-1.64-1.355 0-.918-.03-1.84 0-2.759.051-1.572 1.529-2.418 2.925-1.683.163.086.351.278.377.444.073.487-.338.552-.681.603-.411.064-.947-.03-.994.547-.047.614.501.542.904.602.369.055.745.196.664.628-.038.209-.351.414-.587.53-.342.174-.852.063-.964.512zm-18.872 0c.047.175.047.252.085.299.433.534 1.876-.128 1.577 1.174-.193.838-1.084.364-1.667.458-1.062.17-1.64-.244-1.64-1.355 0-.918-.03-1.84 0-2.759.051-1.572 1.529-2.418 2.925-1.683.163.086.352.278.377.444.073.487-.338.552-.681.603-.411.064-.946-.03-.993.547-.048.614.501.542.903.602.369.055.746.196.664.628-.038.209-.351.414-.587.53-.342.174-.856.063-.963.512z"
    ></path>
    <path
      fill="#FBB23B"
      d="M41.52 71.759c-1.748.043-2.922 1.405-2.836 3.293.082 1.76 1.31 3.011 2.939 2.986 1.764-.026 2.818-1.286 2.767-3.306-.047-1.867-1.157-3.012-2.87-2.973zm.073 4.9c-1.007-.21-1.397-.95-1.358-1.863.034-.786.278-1.679 1.255-1.717 1.058-.043 1.443.777 1.49 1.708.052.97-.372 1.64-1.387 1.871z"
    ></path>
    <path
      fill="#FBB33D"
      d="M78.426 71.763c-1.576.086-2.87 1.534-2.814 3.161.06 1.816 1.422 3.187 3.088 3.114 1.55-.068 2.802-1.482 2.797-3.17-.008-1.78-1.405-3.194-3.07-3.105zm.257 4.865c-1.058.112-1.41-.756-1.435-1.67-.021-.88.167-1.79 1.23-1.88 1.083-.089 1.37.753 1.447 1.453.039 1.132-.261 1.995-1.242 2.097z"
    ></path>
    <path
      fill="#FBB23A"
      d="M161.958 71.763c-1.756-.072-3.007 1.218-3.002 3.105.004 1.893 1.225 3.204 2.955 3.17 1.671-.034 2.733-1.235 2.759-3.114.021-1.858-1.037-3.093-2.712-3.16zm-.162 4.865c-.994-.038-1.414-.927-1.35-1.832.06-.85.369-1.79 1.457-1.722 1.019.065 1.315.915 1.379 1.807-.107.936-.488 1.786-1.486 1.747z"
    ></path>
    <path
      fill="#FBB33C"
      d="M70.939 71.763c-1.67-.051-3.041 1.397-3.003 3.178.034 1.692 1.32 3.08 2.87 3.097 1.688.017 3.011-1.388 2.999-3.178-.013-1.691-1.268-3.046-2.866-3.097zm-.163 4.865c-.99-.072-1.306-.922-1.285-1.853.021-.957.42-1.722 1.44-1.696 1.113.026 1.194.978 1.254 1.824-.073.922-.35 1.802-1.409 1.725z"
    ></path>
    <path
      fill="#FBB23B"
      d="M112.745 71.763c-1.64-.055-2.895 1.205-2.951 2.952-.056 1.84 1.182 3.302 2.814 3.319 1.684.017 2.982-1.359 3.003-3.191.021-1.666-1.242-3.024-2.866-3.08zm-.055 4.891c-1.011.004-1.495-.837-1.487-1.76.009-.88.343-1.798 1.401-1.832 1.088-.034 1.345.854 1.452 1.743-.068.922-.287 1.845-1.366 1.849z"
    ></path>
    <path
      fill="#FBB139"
      d="M85.434 72.002c-2.536-.132-1.598 1.683-1.825 2.94.339 1.08-.852 3.002 1.735 2.917 2.095-.069 3.354-1.06 3.393-2.922.042-1.922-1.101-2.82-3.303-2.934zm.493 4.443c-1.406.11-.682-1.077-.986-1.58.223-.68-.364-1.726.827-1.692.87.025 1.375.662 1.384 1.584.008.868-.257 1.61-1.225 1.688z"
    ></path>
    <path
      fill="#FBB239"
      d="M121.484 76.278c-.236-.35-.917-.64-.197-1.17.916-.671 1.019-1.56.359-2.388-.758-.953-1.944-.782-2.972-.645-1.281.17-.759 1.29-.844 2.046-.069.628-.052 1.269-.004 1.9.047.659-.305 1.696.595 1.799 1.024.12.591-1.06 1.019-1.837.399.479.656.808.934 1.11.356.39.638 1.154 1.302.736.861-.526.142-1.051-.192-1.551zm-1.812-1.692c-.471-.012-.476-.427-.471-.773 0-.376.128-.683.612-.675.309.12.643.274.647.684.004.504-.321.773-.788.764z"
    ></path>
    <path
      fill="#FBB33C"
      d="M57.626 75.198c.955-.804 1.083-1.692.312-2.576-.81-.931-2.026-.752-3.01-.487-1.247.333-.596 1.555-.72 2.392-.073.487-.04.995-.01 1.49.044.667-.273 1.713.626 1.79 1.011.09.45-1.136 1.024-1.88.416.505.707.894 1.04 1.244.318.337.57 1 1.157.662.733-.423.193-.948-.034-1.452-.167-.385-1.015-.654-.385-1.183zm-1.465-.543c-.549.017-.686-.376-.673-.829.013-.474.223-.82.758-.73.433.072.759.32.793.807-.12.47-.424.74-.878.752zm-20.038-.406c.261-1.888-.625-2.149-1.855-2.247-1.615-.128-2.12.726-1.82 2.042.308 1.358-1.328 3.72 1.769 3.797 3.568.081 2.184-2.225 1.906-3.592zm-1.735-1.188c.339.043.433.308.514.581-.098.372-.287.641-.72.586-.342-.039-.488-.308-.48-.59.014-.389.245-.632.686-.577zm.043 3.631c-.411.043-.677-.23-.72-.602-.055-.47.167-.807.711-.863.424.06.831.214.849.726.017.53-.407.697-.84.74z"
    ></path>
    <path
      fill="#FBB13A"
      d="M202.292 76.214c-.313-.41-.686-.7-.043-1.192.797-.61.912-1.508.312-2.264-.826-1.038-2.09-.867-3.174-.627-1.058.234-.591 1.28-.664 1.994-.06.632-.051 1.282-.004 1.914.052.658-.3 1.696.638 1.781.994.09.54-1.11 1.011-1.794.373.449.583.735.831.982.412.406.669 1.35 1.457.765.809-.602-.017-1.102-.364-1.56zm-1.62-1.61c-.449.05-.561-.3-.578-.735.043-.273-.004-.71.484-.705.459.004.806.256.78.769-.017.406-.334.628-.686.67z"
    ></path>
    <path
      fill="#FBB138"
      d="M7.796 74.928v-.624c0-2.19.18-2.345 2.399-2.135.45.042.86.158.895.675.034.58-.45.615-.865.675-.206.03-.416.004-.626 0-.27-.005-.355.145-.355.392-.004.27.128.368.381.364.137-.005.279-.009.416 0 .436.034.878.123.89.662.014.546-.44.619-.865.709-.287.064-.805-.244-.805.337 0 .658.561.308.874.367.454.086.98.112.976.71-.004.597-.522.662-.989.666-2.857.025-2.279.085-2.326-2.798zm7.034.082c0 .67.008 1.34-.005 2.011-.008.432-.162.77-.664.79-.488.022-.754-.303-.766-.713a91.528 91.528 0 010-4.34c.012-.401.24-.747.74-.743.51.004.673.338.682.769.017.743.004 1.482.004 2.225h.009zm117.461.025c0 .67.009 1.341-.004 2.012-.009.431-.163.769-.664.79-.489.022-.754-.303-.767-.713a92.336 92.336 0 010-4.34c.009-.402.24-.748.737-.739.51.004.672.337.681.769.017.743.004 1.482.004 2.225.009-.004.009-.004.013-.004z"
    ></path>
  </svg>
)

export default Icon
