/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#292929"
      d="M100.559 46.964c1.226-5.19-1.988-10.392-7.178-11.617-5.19-1.226-10.391 1.988-11.617 7.178-1.226 5.19 1.988 10.392 7.179 11.617 5.19 1.226 10.39-1.988 11.616-7.178zm-37.413 61.808c-.093 0-.175 0-.269-.012a3.5 3.5 0 01-3.226-3.764L61.72 77.71l-7.003-1.17a3.515 3.515 0 01-2.887-4.033 3.515 3.515 0 014.033-2.887l10.17 1.695a3.508 3.508 0 012.923 3.73l-2.314 30.489a3.514 3.514 0 01-3.496 3.238zm63.586 14.59a4.675 4.675 0 01-3.987-2.233l-12.181-19.815a4.67 4.67 0 011.531-6.43 4.67 4.67 0 016.43 1.531l10.791 17.548 4.045-.059a4.661 4.661 0 014.746 4.607 4.675 4.675 0 01-4.606 4.746l-6.711.105h-.058zm-31.623-16.25a6.606 6.606 0 11-6.605-6.605c3.647-.011 6.605 2.946 6.605 6.605z"
    ></path>
    <path
      fill="#292929"
      d="M88.504 134.433c-15.07 0-27.333-12.264-27.333-27.333 0-15.07 12.263-27.32 27.333-27.32 15.069 0 27.332 12.25 27.332 27.332 0 15.081-12.263 27.321-27.332 27.321zm0-49.136c-12.018 0-21.803 9.785-21.803 21.803s9.785 21.803 21.803 21.803 21.803-9.785 21.803-21.803-9.774-21.803-21.803-21.803z"
    ></path>
    <path
      fill="#292929"
      d="M81.676 83.368l-1.975-.526.093-16.998c0-4.91 3.987-8.896 8.897-8.896s8.896 3.986 8.896 8.896l-.947 17.524H81.676zm-34.57-53.535c.936-5.767-2.98-11.202-8.749-12.138-5.767-.936-11.202 2.98-12.138 8.748-.936 5.768 2.98 11.203 8.748 12.139 5.768.936 11.203-2.98 12.139-8.749zM22.323 90.874c-.666 0-1.356-.058-2.034-.187-6.208-1.11-10.335-7.05-9.212-13.257l5.003-27.508c1.11-6.208 7.05-10.335 13.257-9.224 6.208 1.11 10.335 7.05 9.224 13.257l-5.003 27.508c-.994 5.53-5.81 9.411-11.235 9.411z"
    ></path>
    <path
      fill="#292929"
      d="M30.237 127.279h-2.303l4.267-11.796c.094-.257.164-.514.21-.783a28.07 28.07 0 00-.654-13.246l-4.84-20.096c-.807-2.724-5.728-7.354-8.44-6.547-2.713.807-7.179 7.05-6.36 9.773l9.796 19.793c.772 2.583.936 5.342.48 8.008a1441.15 1441.15 0 00-2.373 6.57c-1.31 3.612-3.227 7.424-3.554 11.282-.188 2.186 1.098 4.232 3.507 4.232h10.276c1.987 0 3.6-1.614 3.6-3.601a3.61 3.61 0 00-3.612-3.589zm14.287-54.678a5.93 5.93 0 01-2.525-.573L23.55 63.19c-2.911-1.391-4.138-4.886-2.747-7.797 1.39-2.911 4.886-4.139 7.797-2.748l18.448 8.838a5.85 5.85 0 012.748 7.798 5.82 5.82 0 01-5.273 3.32zm60.417 9.821a4.675 4.675 0 01-2.783-.924l-14.262-10.58a4.683 4.683 0 01-.97-6.546 4.693 4.693 0 016.546-.97l14.263 10.58a4.683 4.683 0 01-2.794 8.44z"
    ></path>
  </svg>
)

export default Icon
