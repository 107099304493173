/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#292929"
      d="M134.23 48.375H14.77c-1.86 0-3.381-1.498-3.381-3.328v-9.984c0-7.35 6.057-13.313 13.524-13.313h13.523V7.328c0-1.83 1.522-3.328 3.381-3.328h11.27c1.86 0 3.381 1.498 3.381 3.328V21.75h36.063V7.328c0-1.83 1.522-3.328 3.381-3.328h11.27c1.86 0 3.381 1.498 3.381 3.328V21.75h13.524c7.466 0 13.524 5.963 13.524 13.313v9.984c0 1.83-1.522 3.328-3.381 3.328zM14.77 57.25h119.46c1.859 0 3.381 1.498 3.381 3.328v72.11c0 7.349-6.058 13.312-13.524 13.312H24.913c-7.467 0-13.524-5.963-13.524-13.312v-72.11c0-1.83 1.521-3.328 3.38-3.328zm32.682 56.578c0-1.83-1.521-3.328-3.38-3.328h-11.27c-1.86 0-3.382 1.498-3.382 3.328v11.094c0 1.83 1.522 3.328 3.381 3.328h11.27c1.86 0 3.381-1.498 3.381-3.328v-11.094zm0-35.5c0-1.83-1.521-3.328-3.38-3.328h-11.27c-1.86 0-3.382 1.498-3.382 3.328v11.094c0 1.83 1.522 3.328 3.381 3.328h11.27c1.86 0 3.381-1.498 3.381-3.328V78.328zm36.064 35.5c0-1.83-1.522-3.328-3.381-3.328h-11.27c-1.86 0-3.381 1.498-3.381 3.328v11.094c0 1.83 1.521 3.328 3.38 3.328h11.27c1.86 0 3.382-1.498 3.382-3.328v-11.094zm0-35.5c0-1.83-1.522-3.328-3.381-3.328h-11.27c-1.86 0-3.381 1.498-3.381 3.328v11.094c0 1.83 1.521 3.328 3.38 3.328h11.27c1.86 0 3.382-1.498 3.382-3.328V78.328zm36.063 35.5c0-1.83-1.521-3.328-3.381-3.328h-11.27c-1.859 0-3.381 1.498-3.381 3.328v11.094c0 1.83 1.522 3.328 3.381 3.328h11.27c1.86 0 3.381-1.498 3.381-3.328v-11.094zm0-35.5c0-1.83-1.521-3.328-3.381-3.328h-11.27c-1.859 0-3.381 1.498-3.381 3.328v11.094c0 1.83 1.522 3.328 3.381 3.328h11.27c1.86 0 3.381-1.498 3.381-3.328V78.328z"
    ></path>
  </svg>
)

export default Icon
