/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="38"
    viewBox="0 0 26 38"
    fill="none"
    className={className}
  >
    <path
      fill="#E31B23"
      d="M20.596 0H4.753A4.753 4.753 0 000 4.753v28.41a4.753 4.753 0 004.753 4.752h15.843a4.752 4.752 0 004.753-4.753V4.752A4.753 4.753 0 0020.596 0zm-7.922 35.539a1.584 1.584 0 110-3.168 1.584 1.584 0 010 3.168zm9.506-7.13a1.584 1.584 0 01-1.584 1.585H4.753a1.584 1.584 0 01-1.584-1.585V5.545a1.584 1.584 0 011.584-1.584h15.843a1.584 1.584 0 011.584 1.584v22.864z"
    ></path>
  </svg>
)

export default Icon
