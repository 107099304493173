/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { InfographicImage } from "components/infographic"
import Icon from "components/svgs/urban-health-icon-2"

const One = () => {
  const data = useStaticQuery(graphql`
    query UrbanHealthTwoQuery {
      image: file(relativePath: { eq: "urban-health-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <InfographicImage
      bg="secondary"
      color="dark"
      imageSide="right"
      image={data.image.childImageSharp.fluid}
      Icon={Icon}
      value="133%"
    >
      increase in visits to our Overdose Prevention Site between April 1, 2019 and March 31, 2020
    </InfographicImage>
  )
}

export default One
