/** @jsx jsx */
import { jsx } from "theme-ui"
import BackgroundImage from "gatsby-background-image"

import { Container, Row, Col } from "components/layout"
import Panel from "components/panel"
import { Medium } from "components/type"

const ImageOverlap = ({
  bg,
  color,
  image,
  imageSide,
  children,
  imageLeft,
  imageRight,
}) => (
  <Panel bg={bg} color={color} sx={{ my: [6, 6, 7] }}>
    <Container>
      <Row sx={{ alignItems: "stretch" }}>
        <Col
          sx={{
            width: ["100%", "50%"],
            py: 4,
            order: imageSide === "left" ? 2 : 1,
          }}
        >
          <Row sx={{ mb: 4, mt: -6 }}>
            <Col sx={{ width: ["100%", "60%"] }}>
              <BackgroundImage
                fluid={imageLeft}
                backgroundColor="#fff"
                sx={{
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: "200px",
                  width: "100%",
                }}
              />
            </Col>
            <Col sx={{ width: ["100%", "40%"] }}>
              <BackgroundImage
                fluid={imageRight}
                backgroundColor="#fff"
                sx={{
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: "200px",
                  width: "100%",
                }}
              />
            </Col>
          </Row>
          <Medium>{children}</Medium>
        </Col>
        <Col
          sx={{
            width: ["100%", "50%"],
            display: ["none", "flex"],
            order: imageSide === "left" ? 1 : 2,
          }}
        >
          <BackgroundImage
            fluid={image}
            backgroundColor="#fff"
            sx={{
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "100%",
              my: [0, -6],
            }}
          />
        </Col>
      </Row>
    </Container>
  </Panel>
)

ImageOverlap.defaultProps = {
  bg: "primary",
  color: "background",
  imageSide: "left",
}

export default ImageOverlap
