/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

import { Container, Row, Col } from "components/layout"
import { Medium } from "components/type"
import Panel from "components/panel"

import Toronto from "components/svgs/community-development-icon-5"
import Montreal from "components/svgs/community-development-icon-6"

const Five = () => (
  <Panel bg="secondary" color="dark">
    <Container>
      <Row sx={{ alignItems: "center" }}>
        <Col sx={{ width: ["100%", "50%"] }}>
          <Row sx={{ alignItems: "center" }}>
            <Col sx={{ width: "50%", textAlign: "center" }}>
              <Toronto sx={{ height: "auto", width: "100%", mb: 3 }} />
              <Styled.p>Toronto</Styled.p>
              <Medium>$1,675 Per Month</Medium>
            </Col>
            <Col sx={{ width: "50%", textAlign: "center" }}>
              <Montreal sx={{ height: "auto", width: "100%", mb: 3 }} />
              <Styled.p>Montreal</Styled.p>
              <Medium>$175 Per Month</Medium>
            </Col>
          </Row>
        </Col>
        <Col sx={{ width: ["100%", "50%"] }}>
          <Medium>
            the cost of childcare in Toronto is 10 times the cost in Montreal
          </Medium>
        </Col>
      </Row>
    </Container>
  </Panel>
)

export default Five
