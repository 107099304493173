/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

const Medium = props => (
  <Styled.p
    {...props}
    sx={{
      fontSize: [3, 3, 4],
      ...props.sx,
    }}
  >
    {props.children}
  </Styled.p>
)

export default Medium
