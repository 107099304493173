/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="413"
    height="114"
    viewBox="0 0 413 114"
    fill="none"
    className={className}
  >
    <g fill="#fff" clipPath="url(#clip0)">
      <path d="M32.284 4.731a2.717 2.717 0 00-.82-1.938 2.576 2.576 0 00-1.934-.754c-.39.011-.78.029-1.17.05a.745.745 0 01-.784-.744.673.673 0 00-.736-.672 33.854 33.854 0 00-5.49.941.672.672 0 00-.5.651v8.496a.673.673 0 001.149.477l1.738-1.74a.672.672 0 01.952 0l1.74 1.74a.673.673 0 001.147-.477V5.468a.673.673 0 01.628-.672c.226-.015.453-.027.686-.04a.672.672 0 01.706.673l-.017 18.16a.672.672 0 01-.645.672 30.817 30.817 0 00-10.505 2.027.673.673 0 01-.942-.616V8.071c0-.256.344-.619 1.013-1.012a.673.673 0 00.332-.58V4.96a.673.673 0 00-.971-.603 8.989 8.989 0 00-1.32.787.673.673 0 01-.797 0c-2.622-1.94-7.822-2.952-12.99-3.106a2.592 2.592 0 00-1.935.754A2.714 2.714 0 000 4.73v19.505a2.69 2.69 0 002.631 2.69c8.027.227 12.166 2.444 12.166 3.34a1.346 1.346 0 002.69 0c0-.896 4.14-3.113 12.166-3.344a2.69 2.69 0 002.63-2.69V4.73zM3.334 24.257a.672.672 0 01-.644-.672l-.016-18.16a.673.673 0 01.704-.672c7.535.344 11.419 2.453 11.419 3.318v17.603a.672.672 0 01-.942.614 30.855 30.855 0 00-10.522-2.03z"></path>
      <path d="M12.453 10.012A32.365 32.365 0 005.479 8.64a1.009 1.009 0 00-.196 2.008 30.48 30.48 0 016.528 1.277 1.008 1.008 0 10.642-1.913zm0 5.613a32.332 32.332 0 00-6.974-1.366 1.01 1.01 0 00-.196 2.008c2.216.186 4.405.614 6.528 1.277a1.01 1.01 0 00.642-1.913v-.006zm0 5.614a32.363 32.363 0 00-6.974-1.372 1.008 1.008 0 10-.196 2.008c2.216.185 4.405.613 6.528 1.276a1.009 1.009 0 00.642-1.912zm8.02-3.701a30.454 30.454 0 016.528-1.276 1.009 1.009 0 10-.196-2.009c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913zm0 5.613a30.316 30.316 0 016.528-1.276 1.009 1.009 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.912z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip1)">
      <path d="M74.6 4.731a2.713 2.713 0 00-.82-1.938 2.576 2.576 0 00-1.934-.754c-.39.011-.78.029-1.17.05a.745.745 0 01-.785-.744.673.673 0 00-.736-.672 33.853 33.853 0 00-5.49.941.672.672 0 00-.5.651v8.496a.673.673 0 001.15.477l1.737-1.74a.673.673 0 01.952 0l1.74 1.74a.673.673 0 001.147-.477V5.468a.672.672 0 01.628-.672c.226-.015.454-.027.686-.04a.672.672 0 01.707.673l-.018 18.16a.672.672 0 01-.644.672 30.818 30.818 0 00-10.506 2.027.673.673 0 01-.941-.616V8.071c0-.256.344-.619 1.012-1.012a.673.673 0 00.333-.58V4.96a.673.673 0 00-.972-.603 8.994 8.994 0 00-1.32.787.672.672 0 01-.797 0c-2.622-1.94-7.822-2.952-12.99-3.106a2.592 2.592 0 00-1.934.754 2.713 2.713 0 00-.82 1.938v19.505a2.69 2.69 0 002.632 2.69c8.026.227 12.165 2.444 12.165 3.34a1.345 1.345 0 102.69 0c0-.896 4.14-3.113 12.166-3.344a2.69 2.69 0 002.631-2.69V4.73zM45.648 24.257a.672.672 0 01-.643-.672l-.016-18.16a.673.673 0 01.703-.672c7.536.344 11.42 2.453 11.42 3.318v17.603a.672.672 0 01-.942.614 30.856 30.856 0 00-10.522-2.03z"></path>
      <path d="M54.768 10.012a32.364 32.364 0 00-6.973-1.372 1.009 1.009 0 00-.197 2.008 30.48 30.48 0 016.528 1.277 1.008 1.008 0 10.642-1.913zm0 5.613a32.332 32.332 0 00-6.973-1.366 1.01 1.01 0 00-.197 2.008c2.216.186 4.405.614 6.528 1.277a1.01 1.01 0 00.642-1.913v-.006zm0 5.614a32.362 32.362 0 00-6.973-1.372 1.008 1.008 0 10-.197 2.008c2.217.185 4.406.613 6.528 1.276a1.009 1.009 0 00.642-1.912zm8.021-3.701a30.455 30.455 0 016.528-1.276 1.009 1.009 0 10-.196-2.009c-2.368.2-4.707.66-6.974 1.372a1.009 1.009 0 00.642 1.913zm0 5.613a30.315 30.315 0 016.528-1.276 1.009 1.009 0 10-.196-2.008c-2.368.2-4.707.66-6.974 1.372a1.009 1.009 0 00.642 1.912z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip2)">
      <path d="M116.915 4.731a2.717 2.717 0 00-.82-1.938 2.567 2.567 0 00-1.934-.754c-.39.011-.78.029-1.17.05a.746.746 0 01-.784-.744.675.675 0 00-.736-.672 33.854 33.854 0 00-5.49.941.676.676 0 00-.5.651v8.496a.674.674 0 001.149.477l1.738-1.74a.663.663 0 01.476-.197.668.668 0 01.476.197l1.739 1.74a.671.671 0 001.034-.103.671.671 0 00.114-.374V5.468a.668.668 0 01.628-.672c.226-.015.453-.027.686-.04a.675.675 0 01.652.408.672.672 0 01.054.265l-.017 18.16a.673.673 0 01-.645.672 30.811 30.811 0 00-10.505 2.027.677.677 0 01-.638-.053.674.674 0 01-.304-.563V8.071c0-.256.344-.619 1.013-1.012a.671.671 0 00.332-.58V4.96a.669.669 0 00-.318-.571.67.67 0 00-.653-.032 8.99 8.99 0 00-1.321.787.674.674 0 01-.796 0c-2.622-1.94-7.823-2.952-12.99-3.106a2.592 2.592 0 00-1.935.754 2.715 2.715 0 00-.82 1.938v19.505a2.69 2.69 0 002.632 2.69c8.026.227 12.166 2.444 12.166 3.34a1.345 1.345 0 102.69 0c0-.896 4.139-3.113 12.166-3.344a2.691 2.691 0 002.631-2.69V4.73zm-28.95 19.526a.673.673 0 01-.644-.672l-.016-18.16a.673.673 0 01.704-.672c7.535.344 11.419 2.453 11.419 3.318v17.603a.673.673 0 01-.942.614 30.856 30.856 0 00-10.522-2.03z"></path>
      <path d="M97.083 10.012A32.365 32.365 0 0090.11 8.64a1.01 1.01 0 00-.196 2.008 30.5 30.5 0 016.528 1.277 1.008 1.008 0 10.641-1.913zm0 5.613a32.333 32.333 0 00-6.973-1.366 1.01 1.01 0 00-.196 2.008c2.216.186 4.405.614 6.528 1.277a1.01 1.01 0 00.641-1.913v-.006zm0 5.614a32.364 32.364 0 00-6.973-1.372 1.008 1.008 0 10-.196 2.008c2.216.185 4.405.613 6.528 1.276a1.009 1.009 0 00.641-1.912zm8.021-3.701a30.457 30.457 0 016.528-1.276 1.01 1.01 0 10-.196-2.009c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913zm0 5.613a30.323 30.323 0 016.528-1.276 1.01 1.01 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip3)">
      <path d="M159.228 4.731a2.715 2.715 0 00-.819-1.938 2.58 2.58 0 00-1.934-.754 45.64 45.64 0 00-1.171.05.746.746 0 01-.784-.744.67.67 0 00-.459-.64.672.672 0 00-.277-.032 33.86 33.86 0 00-5.489.941.669.669 0 00-.501.651v8.496a.674.674 0 001.149.477l1.738-1.74a.673.673 0 01.952 0l1.74 1.74a.672.672 0 001.147-.477V5.468a.673.673 0 01.628-.672c.226-.015.454-.027.686-.04a.675.675 0 01.652.408.655.655 0 01.054.265l-.017 18.16a.673.673 0 01-.644.672 30.817 30.817 0 00-10.506 2.027.671.671 0 01-.942-.616V8.071c0-.256.345-.619 1.013-1.012a.666.666 0 00.333-.58V4.96a.68.68 0 00-.318-.571.678.678 0 00-.654-.032 8.99 8.99 0 00-1.321.787.672.672 0 01-.796 0c-2.622-1.94-7.822-2.952-12.99-3.106a2.593 2.593 0 00-1.934.754 2.717 2.717 0 00-.82 1.938v19.505a2.69 2.69 0 002.631 2.69c8.027.227 12.166 2.444 12.166 3.34a1.346 1.346 0 002.296.951 1.34 1.34 0 00.394-.95c0-.897 4.139-3.114 12.166-3.345a2.691 2.691 0 002.631-2.69V4.73zm-28.95 19.526a.672.672 0 01-.643-.672l-.016-18.16a.668.668 0 01.703-.672c7.536.344 11.419 2.453 11.419 3.318v17.603a.671.671 0 01-.942.614 30.846 30.846 0 00-10.521-2.03z"></path>
      <path d="M139.397 10.012a32.37 32.37 0 00-6.973-1.372 1.01 1.01 0 00-.878 1.645 1.002 1.002 0 00.681.363 30.5 30.5 0 016.528 1.277 1.008 1.008 0 10.642-1.913zm0 5.613a32.336 32.336 0 00-6.973-1.366 1.008 1.008 0 10-.197 2.008 30.45 30.45 0 016.528 1.277 1.01 1.01 0 00.642-1.913v-.006zm0 5.614a32.37 32.37 0 00-6.973-1.372 1.01 1.01 0 00-.197 2.008 30.31 30.31 0 016.528 1.276 1.01 1.01 0 00.642-1.912zm8.021-3.701a30.457 30.457 0 016.528-1.276 1.01 1.01 0 00-.197-2.009c-2.367.2-4.706.66-6.973 1.372a1.011 1.011 0 00-.623 1.273 1.006 1.006 0 001.265.64zm0 5.613a30.31 30.31 0 016.528-1.276 1.01 1.01 0 00-.197-2.008c-2.367.2-4.706.66-6.973 1.372a1.012 1.012 0 00-.623 1.273 1.006 1.006 0 001.265.64z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip4)">
      <path d="M201.544 4.731a2.716 2.716 0 00-.82-1.938 2.571 2.571 0 00-1.934-.754c-.39.011-.78.029-1.17.05a.746.746 0 01-.785-.744.658.658 0 00-.218-.5.678.678 0 00-.517-.172 33.854 33.854 0 00-5.49.941.676.676 0 00-.5.651v8.496a.666.666 0 00.415.623.672.672 0 00.733-.146l1.738-1.74a.678.678 0 01.734-.146.678.678 0 01.219.146l1.739 1.74a.672.672 0 001.147-.477V5.468a.674.674 0 01.629-.672c.226-.015.453-.027.686-.04a.668.668 0 01.497.185.677.677 0 01.209.488l-.018 18.16a.668.668 0 01-.644.672 30.822 30.822 0 00-10.506 2.027.671.671 0 01-.941-.616V8.071c0-.256.344-.619 1.013-1.012a.671.671 0 00.332-.58V4.96a.669.669 0 00-.318-.571.671.671 0 00-.653-.032 8.99 8.99 0 00-1.321.787.674.674 0 01-.797 0c-2.621-1.94-7.822-2.952-12.99-3.106a2.593 2.593 0 00-1.934.754 2.715 2.715 0 00-.819 1.938v19.505a2.69 2.69 0 002.631 2.69c8.026.227 12.166 2.444 12.166 3.34a1.345 1.345 0 102.69 0c0-.896 4.139-3.113 12.165-3.344a2.691 2.691 0 002.632-2.69V4.73zm-28.951 19.526a.674.674 0 01-.643-.672l-.016-18.16a.67.67 0 01.437-.63.67.67 0 01.266-.042c7.536.344 11.42 2.453 11.42 3.318v17.603a.684.684 0 01-.305.561.678.678 0 01-.637.053 30.856 30.856 0 00-10.522-2.03z"></path>
      <path d="M181.712 10.012a32.359 32.359 0 00-6.973-1.372 1.01 1.01 0 00-.878 1.645 1.006 1.006 0 00.682.363 30.5 30.5 0 016.528 1.277 1.006 1.006 0 001.289-.631.993.993 0 00-.056-.777.999.999 0 00-.592-.505zm0 5.613a32.324 32.324 0 00-6.973-1.366 1.01 1.01 0 00-.878 1.644c.17.207.415.338.682.364a30.45 30.45 0 016.528 1.277 1.008 1.008 0 00.641-1.913v-.006zm0 5.614a32.357 32.357 0 00-6.973-1.372 1.01 1.01 0 10-.196 2.008c2.216.185 4.405.613 6.528 1.276a1.007 1.007 0 001.214-1.404 1.008 1.008 0 00-.573-.508zm8.021-3.701a30.457 30.457 0 016.528-1.276 1.009 1.009 0 00-.196-2.008c-2.368.199-4.707.66-6.973 1.371a1.009 1.009 0 00.641 1.913zm0 5.613a30.323 30.323 0 016.528-1.276 1.009 1.009 0 00-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip5)">
      <path d="M32.284 45.48a2.716 2.716 0 00-.82-1.938 2.575 2.575 0 00-1.934-.753c-.39.01-.78.028-1.17.05a.745.745 0 01-.784-.745.673.673 0 00-.736-.672 33.862 33.862 0 00-5.49.942.673.673 0 00-.5.65v8.497a.673.673 0 001.149.476l1.738-1.74a.672.672 0 01.952 0l1.74 1.74a.673.673 0 001.147-.476v-5.294a.673.673 0 01.628-.672c.226-.015.453-.027.686-.04a.674.674 0 01.706.673l-.017 18.16a.673.673 0 01-.645.673 30.815 30.815 0 00-10.505 2.027.673.673 0 01-.942-.616V48.82c0-.255.344-.618 1.013-1.011a.673.673 0 00.332-.581V45.71a.673.673 0 00-.971-.602 8.982 8.982 0 00-1.32.787.673.673 0 01-.797 0c-2.622-1.94-7.822-2.953-12.99-3.106a2.591 2.591 0 00-1.935.753A2.714 2.714 0 000 45.48v19.505a2.69 2.69 0 002.631 2.69c8.027.228 12.166 2.445 12.166 3.34a1.346 1.346 0 002.69 0c0-.895 4.14-3.112 12.166-3.344a2.69 2.69 0 002.63-2.69v-19.5zM3.334 65.007a.673.673 0 01-.644-.673l-.016-18.16a.673.673 0 01.704-.672c7.535.344 11.419 2.453 11.419 3.318v17.603a.672.672 0 01-.942.615 30.853 30.853 0 00-10.522-2.031z"></path>
      <path d="M12.453 50.761a32.363 32.363 0 00-6.974-1.372 1.008 1.008 0 10-.196 2.009 30.48 30.48 0 016.528 1.276 1.01 1.01 0 10.642-1.913zm0 5.614a32.332 32.332 0 00-6.974-1.367 1.009 1.009 0 10-.196 2.008c2.216.186 4.405.614 6.528 1.277a1.009 1.009 0 00.642-1.913v-.005zm0 5.613a32.369 32.369 0 00-6.974-1.372 1.01 1.01 0 00-.196 2.008c2.216.185 4.405.613 6.528 1.277a1.01 1.01 0 00.642-1.913zm8.02-3.7a30.448 30.448 0 016.528-1.277 1.009 1.009 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913zm0 5.612a30.316 30.316 0 016.528-1.276 1.008 1.008 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip6)">
      <path d="M74.6 45.48a2.713 2.713 0 00-.82-1.938 2.575 2.575 0 00-1.934-.753c-.39.01-.78.028-1.17.05a.745.745 0 01-.785-.745.673.673 0 00-.736-.672 33.86 33.86 0 00-5.49.942.673.673 0 00-.5.65v8.497a.673.673 0 001.15.476l1.737-1.74a.673.673 0 01.952 0l1.74 1.74a.673.673 0 001.147-.476v-5.294a.672.672 0 01.628-.672c.226-.015.454-.027.686-.04a.674.674 0 01.707.673l-.018 18.16a.672.672 0 01-.644.673 30.816 30.816 0 00-10.506 2.027.673.673 0 01-.941-.616V48.82c0-.255.344-.618 1.012-1.011a.672.672 0 00.333-.581V45.71a.673.673 0 00-.972-.602 8.987 8.987 0 00-1.32.787.672.672 0 01-.797 0c-2.622-1.94-7.822-2.953-12.99-3.106a2.591 2.591 0 00-1.934.753 2.713 2.713 0 00-.82 1.938v19.505a2.69 2.69 0 002.632 2.69c8.026.228 12.165 2.445 12.165 3.34a1.345 1.345 0 102.69 0c0-.895 4.14-3.112 12.166-3.344a2.69 2.69 0 002.631-2.69v-19.5zM45.648 65.007a.672.672 0 01-.643-.673l-.016-18.16a.673.673 0 01.703-.672c7.536.344 11.42 2.453 11.42 3.318v17.603a.672.672 0 01-.942.615 30.854 30.854 0 00-10.522-2.031z"></path>
      <path d="M54.768 50.761a32.362 32.362 0 00-6.973-1.372 1.008 1.008 0 10-.197 2.009 30.48 30.48 0 016.528 1.276 1.01 1.01 0 10.642-1.913zm0 5.614a32.332 32.332 0 00-6.973-1.367 1.009 1.009 0 10-.197 2.008c2.216.186 4.405.614 6.528 1.277a1.009 1.009 0 00.642-1.913v-.005zm0 5.613a32.368 32.368 0 00-6.973-1.372 1.01 1.01 0 10-.197 2.008c2.217.185 4.406.613 6.528 1.277a1.01 1.01 0 00.642-1.913zm8.021-3.7a30.449 30.449 0 016.528-1.277 1.009 1.009 0 10-.196-2.008c-2.368.2-4.707.66-6.974 1.372a1.009 1.009 0 00.642 1.913zm0 5.612a30.315 30.315 0 016.528-1.276 1.009 1.009 0 10-.196-2.008c-2.368.2-4.707.66-6.974 1.372a1.009 1.009 0 00.642 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip7)">
      <path d="M116.915 45.48a2.717 2.717 0 00-.82-1.938 2.567 2.567 0 00-1.934-.753c-.39.01-.78.028-1.17.05a.746.746 0 01-.784-.745.675.675 0 00-.736-.672 33.86 33.86 0 00-5.49.942.677.677 0 00-.5.65v8.497a.674.674 0 001.149.476l1.738-1.74a.663.663 0 01.476-.197.668.668 0 01.476.198l1.739 1.739a.672.672 0 001.148-.476v-5.294a.668.668 0 01.628-.672c.226-.015.453-.027.686-.04a.678.678 0 01.497.185.676.676 0 01.209.488l-.017 18.16a.674.674 0 01-.645.673 30.809 30.809 0 00-10.505 2.027.677.677 0 01-.861-.297.673.673 0 01-.081-.32v-17.6c0-.256.344-.62 1.013-1.012a.67.67 0 00.332-.581V45.71a.669.669 0 00-.318-.571.67.67 0 00-.653-.031 8.983 8.983 0 00-1.321.787.674.674 0 01-.796 0c-2.622-1.94-7.823-2.953-12.99-3.106a2.591 2.591 0 00-1.935.753 2.714 2.714 0 00-.82 1.938v19.505a2.69 2.69 0 002.632 2.69c8.026.228 12.166 2.445 12.166 3.34a1.345 1.345 0 102.69 0c0-.895 4.139-3.112 12.166-3.344a2.69 2.69 0 002.631-2.69v-19.5zm-28.95 19.527a.673.673 0 01-.644-.673l-.016-18.16a.673.673 0 01.704-.672c7.535.344 11.419 2.453 11.419 3.318v17.603a.673.673 0 01-.942.615 30.853 30.853 0 00-10.522-2.031z"></path>
      <path d="M97.083 50.761a32.364 32.364 0 00-6.973-1.372 1.008 1.008 0 10-.196 2.009c2.216.186 4.405.614 6.528 1.276a1.01 1.01 0 10.641-1.913zm0 5.614a32.333 32.333 0 00-6.973-1.367 1.009 1.009 0 10-.196 2.008c2.216.186 4.405.614 6.528 1.277a1.009 1.009 0 00.641-1.913v-.005zm0 5.613a32.37 32.37 0 00-6.973-1.372 1.01 1.01 0 00-.196 2.008c2.216.185 4.405.613 6.528 1.277a1.01 1.01 0 00.641-1.913zm8.021-3.7a30.45 30.45 0 016.528-1.277 1.01 1.01 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913zm0 5.612a30.323 30.323 0 016.528-1.276 1.009 1.009 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip8)">
      <path d="M159.228 45.48a2.714 2.714 0 00-.819-1.938 2.58 2.58 0 00-1.934-.753c-.391.01-.781.028-1.171.05a.746.746 0 01-.784-.745.669.669 0 00-.459-.64.672.672 0 00-.277-.032 33.868 33.868 0 00-5.489.942.67.67 0 00-.501.65v8.497a.674.674 0 001.149.476l1.738-1.74a.674.674 0 01.952 0l1.74 1.74a.673.673 0 001.147-.476v-5.294a.673.673 0 01.628-.672c.226-.015.454-.027.686-.04a.678.678 0 01.498.185.673.673 0 01.208.488l-.017 18.16a.674.674 0 01-.644.673 30.815 30.815 0 00-10.506 2.027.671.671 0 01-.861-.297.673.673 0 01-.081-.32v-17.6c0-.256.345-.62 1.013-1.012a.666.666 0 00.333-.581V45.71a.68.68 0 00-.318-.571.678.678 0 00-.654-.031 8.983 8.983 0 00-1.321.787.672.672 0 01-.796 0c-2.622-1.94-7.822-2.953-12.99-3.106a2.592 2.592 0 00-1.934.753 2.717 2.717 0 00-.82 1.938v19.505a2.69 2.69 0 002.631 2.69c8.027.228 12.166 2.445 12.166 3.34a1.346 1.346 0 002.296.952c.253-.253.394-.595.394-.952 0-.895 4.139-3.112 12.166-3.344a2.69 2.69 0 002.631-2.69v-19.5zm-28.95 19.527a.672.672 0 01-.643-.673l-.016-18.16a.669.669 0 01.703-.672c7.536.344 11.419 2.453 11.419 3.318v17.603a.671.671 0 01-.942.615 30.844 30.844 0 00-10.521-2.031z"></path>
      <path d="M139.397 50.761a32.37 32.37 0 00-6.973-1.372 1.01 1.01 0 00-.197 2.009c2.216.186 4.405.614 6.528 1.276a1.01 1.01 0 00.989-1.72 1.016 1.016 0 00-.347-.193zm0 5.614a32.335 32.335 0 00-6.973-1.367 1.007 1.007 0 00-.878 1.645c.17.206.415.337.681.363a30.45 30.45 0 016.528 1.277 1.01 1.01 0 00.642-1.913v-.005zm0 5.613a32.375 32.375 0 00-6.973-1.372 1.011 1.011 0 00-1.103.906 1.018 1.018 0 00.225.739 1.004 1.004 0 00.681.363 30.31 30.31 0 016.528 1.277 1.01 1.01 0 00.642-1.913zm8.021-3.7a30.45 30.45 0 016.528-1.277 1.01 1.01 0 00-.197-2.008c-2.367.2-4.706.66-6.973 1.372a1.012 1.012 0 00-.623 1.273 1.006 1.006 0 001.265.64zm0 5.612a30.31 30.31 0 016.528-1.276 1.009 1.009 0 00-.197-2.008c-2.367.2-4.706.66-6.973 1.372a1.012 1.012 0 00-.623 1.273 1.007 1.007 0 001.265.64z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip9)">
      <path d="M201.544 45.48a2.715 2.715 0 00-.82-1.938 2.571 2.571 0 00-1.934-.753c-.39.01-.78.028-1.17.05a.746.746 0 01-.785-.745.658.658 0 00-.218-.499.677.677 0 00-.517-.173 33.86 33.86 0 00-5.49.942.677.677 0 00-.5.65v8.497a.666.666 0 00.415.622.672.672 0 00.733-.146l1.738-1.74a.678.678 0 01.734-.146.678.678 0 01.219.147l1.739 1.739a.673.673 0 001.147-.476v-5.294a.674.674 0 01.629-.672c.226-.015.453-.027.686-.04a.67.67 0 01.652.408.673.673 0 01.054.265l-.018 18.16a.668.668 0 01-.644.673 30.82 30.82 0 00-10.506 2.027.671.671 0 01-.941-.616V48.82c0-.255.344-.618 1.013-1.011a.67.67 0 00.332-.581V45.71a.669.669 0 00-.318-.571.67.67 0 00-.653-.031 8.983 8.983 0 00-1.321.787.674.674 0 01-.797 0c-2.621-1.94-7.822-2.953-12.99-3.106a2.592 2.592 0 00-1.934.753 2.714 2.714 0 00-.819 1.938v19.505a2.69 2.69 0 002.631 2.69c8.026.228 12.166 2.445 12.166 3.34a1.345 1.345 0 102.69 0c0-.895 4.139-3.112 12.165-3.344a2.69 2.69 0 002.632-2.69v-19.5zm-28.951 19.527a.674.674 0 01-.643-.673l-.016-18.16a.67.67 0 01.703-.672c7.536.344 11.42 2.453 11.42 3.318v17.603a.684.684 0 01-.305.561.677.677 0 01-.637.054 30.854 30.854 0 00-10.522-2.031z"></path>
      <path d="M181.712 50.761a32.357 32.357 0 00-6.973-1.372 1.01 1.01 0 10-.196 2.009c2.216.186 4.405.614 6.528 1.276a1.008 1.008 0 10.641-1.913zm0 5.614a32.324 32.324 0 00-6.973-1.367 1.01 1.01 0 10-.196 2.008 30.45 30.45 0 016.528 1.277 1.007 1.007 0 001.214-1.404 1.009 1.009 0 00-.573-.509v-.005zm0 5.613a32.363 32.363 0 00-6.973-1.372 1.011 1.011 0 00-.988.529 1 1 0 00-.076.769 1.018 1.018 0 00.491.596 1 1 0 00.377.114c2.216.185 4.405.613 6.528 1.277a1.008 1.008 0 00.641-1.913zm8.021-3.7a30.45 30.45 0 016.528-1.277 1.009 1.009 0 00-.196-2.008c-2.368.2-4.707.66-6.973 1.372a1.009 1.009 0 00.641 1.913zm0 5.612a30.323 30.323 0 016.528-1.276 1.009 1.009 0 00-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip10)">
      <path d="M32.284 86.23a2.716 2.716 0 00-.82-1.939 2.575 2.575 0 00-1.934-.753c-.39.01-.78.028-1.17.05a.745.745 0 01-.784-.744.673.673 0 00-.736-.673 33.862 33.862 0 00-5.49.942.673.673 0 00-.5.651v8.496a.673.673 0 001.149.476l1.738-1.739a.672.672 0 01.952 0l1.74 1.74a.673.673 0 001.147-.477v-5.293a.673.673 0 01.628-.673c.226-.014.453-.027.686-.039a.674.674 0 01.706.673l-.017 18.16a.671.671 0 01-.645.672 30.82 30.82 0 00-10.505 2.027.673.673 0 01-.942-.616V89.57c0-.256.344-.619 1.013-1.012a.673.673 0 00.332-.58V86.46a.673.673 0 00-.971-.603 8.99 8.99 0 00-1.32.787.673.673 0 01-.797 0c-2.622-1.94-7.822-2.953-12.99-3.106a2.591 2.591 0 00-1.935.753A2.714 2.714 0 000 86.23v19.505a2.69 2.69 0 002.631 2.69c8.027.227 12.166 2.444 12.166 3.34a1.344 1.344 0 102.69 0c0-.896 4.14-3.113 12.166-3.344a2.693 2.693 0 002.63-2.69V86.23zm-28.95 19.526a.675.675 0 01-.644-.672l-.016-18.16a.673.673 0 01.704-.673c7.535.345 11.419 2.454 11.419 3.319v17.603a.672.672 0 01-.942.614 30.864 30.864 0 00-10.522-2.031z"></path>
      <path d="M12.453 91.51a32.363 32.363 0 00-6.974-1.371 1.008 1.008 0 10-.196 2.008c2.216.186 4.405.614 6.528 1.277a1.01 1.01 0 10.642-1.913zm0 5.614a32.326 32.326 0 00-6.974-1.367 1.009 1.009 0 10-.196 2.009c2.216.186 4.405.614 6.528 1.276a1.01 1.01 0 00.642-1.913v-.005zm0 5.613a32.363 32.363 0 00-6.974-1.372 1.018 1.018 0 00-.739.225 1.008 1.008 0 00.543 1.784 30.37 30.37 0 016.528 1.276 1.008 1.008 0 00.642-1.913zm8.02-3.7a30.448 30.448 0 016.528-1.277 1.009 1.009 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913zm0 5.613a30.37 30.37 0 016.528-1.276 1.008 1.008 0 00-.196-2.009c-2.368.2-4.706.66-6.973 1.372a1.011 1.011 0 00-.623 1.273 1.01 1.01 0 001.264.64z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip11)">
      <path d="M74.6 86.23a2.713 2.713 0 00-.82-1.939 2.575 2.575 0 00-1.934-.753c-.39.01-.78.028-1.17.05a.745.745 0 01-.785-.744.673.673 0 00-.736-.673 33.86 33.86 0 00-5.49.942.673.673 0 00-.5.651v8.496a.673.673 0 001.15.476l1.737-1.739a.673.673 0 01.952 0l1.74 1.74a.673.673 0 001.147-.477v-5.293a.672.672 0 01.628-.673c.226-.014.454-.027.686-.039a.674.674 0 01.707.673l-.018 18.16a.67.67 0 01-.644.672 30.82 30.82 0 00-10.506 2.027.673.673 0 01-.941-.616V89.57c0-.256.344-.619 1.012-1.012a.672.672 0 00.333-.58V86.46a.673.673 0 00-.972-.603 8.996 8.996 0 00-1.32.787.672.672 0 01-.797 0c-2.622-1.94-7.822-2.953-12.99-3.106a2.591 2.591 0 00-1.934.753 2.713 2.713 0 00-.82 1.939v19.505a2.69 2.69 0 002.632 2.69c8.026.227 12.165 2.444 12.165 3.34a1.344 1.344 0 002.296.951c.253-.252.395-.594.395-.951 0-.896 4.139-3.113 12.165-3.344a2.692 2.692 0 002.631-2.69V86.23zm-28.951 19.526a.675.675 0 01-.643-.672l-.016-18.16a.673.673 0 01.703-.673c7.536.345 11.42 2.454 11.42 3.319v17.603a.673.673 0 01-.942.614 30.865 30.865 0 00-10.522-2.031z"></path>
      <path d="M54.768 91.51a32.362 32.362 0 00-6.973-1.371 1.008 1.008 0 10-.197 2.008c2.216.186 4.405.614 6.528 1.277a1.01 1.01 0 10.642-1.913zm0 5.614a32.326 32.326 0 00-6.973-1.367 1.009 1.009 0 10-.197 2.009c2.216.186 4.405.614 6.528 1.276a1.01 1.01 0 00.642-1.913v-.005zm0 5.613a32.362 32.362 0 00-6.973-1.372 1.018 1.018 0 00-.74.225 1.01 1.01 0 00.543 1.784 30.37 30.37 0 016.528 1.276 1.008 1.008 0 00.642-1.913zm8.021-3.7a30.449 30.449 0 016.528-1.277 1.009 1.009 0 10-.196-2.008c-2.368.2-4.707.66-6.974 1.372a1.009 1.009 0 00.642 1.913zm0 5.613a30.369 30.369 0 016.528-1.276 1.008 1.008 0 00-.196-2.009c-2.368.2-4.707.66-6.974 1.372a1.011 1.011 0 00-.623 1.273 1.01 1.01 0 001.265.64z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip12)">
      <path d="M116.915 86.23a2.717 2.717 0 00-.82-1.938 2.567 2.567 0 00-1.934-.754c-.39.01-.78.028-1.17.05a.746.746 0 01-.784-.744.675.675 0 00-.736-.673 33.86 33.86 0 00-5.49.942.677.677 0 00-.5.651v8.496a.674.674 0 001.149.476l1.738-1.739a.663.663 0 01.476-.198.668.668 0 01.476.198l1.739 1.74a.672.672 0 001.148-.477v-5.293a.668.668 0 01.628-.673c.226-.014.453-.027.686-.039a.678.678 0 01.497.185.676.676 0 01.209.488l-.017 18.16a.672.672 0 01-.645.672 30.814 30.814 0 00-10.505 2.027.677.677 0 01-.638-.053.676.676 0 01-.304-.563V89.57c0-.256.344-.619 1.013-1.012a.671.671 0 00.332-.58V86.46a.669.669 0 00-.318-.572.672.672 0 00-.653-.03 8.992 8.992 0 00-1.321.786.674.674 0 01-.796 0c-2.622-1.94-7.823-2.953-12.99-3.106a2.591 2.591 0 00-1.935.753 2.714 2.714 0 00-.82 1.939v19.505a2.69 2.69 0 002.632 2.69c8.026.227 12.166 2.444 12.166 3.34a1.344 1.344 0 002.296.951c.252-.252.394-.594.394-.951 0-.896 4.139-3.113 12.166-3.344a2.693 2.693 0 002.631-2.69V86.23zm-28.95 19.526a.676.676 0 01-.644-.672l-.016-18.16a.673.673 0 01.704-.673c7.535.345 11.419 2.454 11.419 3.319v17.603a.674.674 0 01-.942.614 30.865 30.865 0 00-10.522-2.031z"></path>
      <path d="M97.083 91.51a32.364 32.364 0 00-6.973-1.371 1.008 1.008 0 10-.196 2.008c2.216.186 4.405.614 6.528 1.277a1.01 1.01 0 10.641-1.913zm0 5.614a32.327 32.327 0 00-6.973-1.367 1.009 1.009 0 10-.196 2.009 30.44 30.44 0 016.528 1.276 1.01 1.01 0 00.641-1.913v-.005zm0 5.613a32.363 32.363 0 00-6.973-1.372 1.018 1.018 0 00-.739.225 1.008 1.008 0 00.543 1.784 30.37 30.37 0 016.528 1.276 1.008 1.008 0 00.641-1.913zm8.021-3.7a30.45 30.45 0 016.528-1.277 1.01 1.01 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913zm0 5.613a30.376 30.376 0 016.528-1.276 1.009 1.009 0 10-.196-2.009c-2.368.2-4.706.66-6.973 1.372a1.008 1.008 0 00.641 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip13)">
      <path d="M159.228 86.23a2.714 2.714 0 00-.819-1.939 2.58 2.58 0 00-1.934-.753c-.391.01-.781.028-1.171.05a.746.746 0 01-.784-.744.67.67 0 00-.459-.64.673.673 0 00-.277-.033 33.868 33.868 0 00-5.489.942.67.67 0 00-.501.651v8.496a.674.674 0 001.149.476l1.738-1.739a.674.674 0 01.952 0l1.74 1.74a.673.673 0 001.147-.477v-5.293a.673.673 0 01.628-.673c.226-.014.454-.027.686-.039a.678.678 0 01.498.185.673.673 0 01.208.488l-.017 18.16a.672.672 0 01-.644.672 30.82 30.82 0 00-10.506 2.027.671.671 0 01-.942-.616V89.57c0-.256.345-.619 1.013-1.012a.666.666 0 00.333-.58V86.46a.68.68 0 00-.318-.572.679.679 0 00-.654-.03 8.992 8.992 0 00-1.321.786.672.672 0 01-.796 0c-2.622-1.94-7.822-2.953-12.99-3.106a2.592 2.592 0 00-1.934.753 2.717 2.717 0 00-.82 1.939v19.505a2.69 2.69 0 002.631 2.69c8.027.227 12.166 2.444 12.166 3.34a1.345 1.345 0 002.69 0c0-.896 4.139-3.113 12.166-3.344a2.693 2.693 0 002.631-2.69V86.23zm-28.95 19.526a.675.675 0 01-.643-.672l-.016-18.16a.669.669 0 01.703-.673c7.536.345 11.419 2.454 11.419 3.319v17.603a.672.672 0 01-.942.614 30.855 30.855 0 00-10.521-2.031z"></path>
      <path d="M139.397 91.51a32.37 32.37 0 00-6.973-1.371 1.01 1.01 0 00-.197 2.008c2.216.186 4.405.614 6.528 1.277a1.01 1.01 0 00.989-1.72 1.016 1.016 0 00-.347-.193zm0 5.614a32.33 32.33 0 00-6.973-1.367 1.007 1.007 0 00-.878 1.645c.17.207.415.338.681.364 2.216.186 4.405.614 6.528 1.276a1.01 1.01 0 00.642-1.913v-.005zm0 5.613a32.369 32.369 0 00-6.973-1.372 1.02 1.02 0 00-.739.225 1.01 1.01 0 00.542 1.784c2.216.185 4.406.613 6.528 1.276a1.009 1.009 0 00.642-1.913zm8.021-3.7a30.45 30.45 0 016.528-1.277 1.01 1.01 0 00-.197-2.008c-2.367.2-4.706.66-6.973 1.372a1.012 1.012 0 00-.623 1.273 1.006 1.006 0 001.265.64zm0 5.613a30.364 30.364 0 016.528-1.276 1.008 1.008 0 00-.197-2.009c-2.367.2-4.706.66-6.973 1.372a1.013 1.013 0 00-.122 1.853c.237.12.511.141.764.06z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip14)">
      <path d="M201.544 86.23a2.715 2.715 0 00-.82-1.939 2.571 2.571 0 00-1.934-.753c-.39.01-.78.028-1.17.05a.746.746 0 01-.785-.744.658.658 0 00-.218-.5.677.677 0 00-.517-.173 33.86 33.86 0 00-5.49.942.677.677 0 00-.5.651v8.496a.666.666 0 00.415.623.672.672 0 00.733-.147l1.738-1.739a.678.678 0 01.734-.146.678.678 0 01.219.146l1.739 1.74a.673.673 0 001.147-.477v-5.293a.674.674 0 01.629-.673c.226-.014.453-.027.686-.039a.67.67 0 01.652.407.673.673 0 01.054.266l-.018 18.16a.67.67 0 01-.644.672 30.824 30.824 0 00-10.506 2.027.671.671 0 01-.941-.616V89.57c0-.256.344-.619 1.013-1.012a.671.671 0 00.332-.58V86.46a.669.669 0 00-.318-.572.672.672 0 00-.653-.03 8.992 8.992 0 00-1.321.786.674.674 0 01-.797 0c-2.621-1.94-7.822-2.953-12.99-3.106a2.592 2.592 0 00-1.934.753 2.714 2.714 0 00-.819 1.939v19.505a2.69 2.69 0 002.631 2.69c8.026.227 12.166 2.444 12.166 3.34a1.344 1.344 0 102.69 0c0-.896 4.139-3.113 12.165-3.344a2.693 2.693 0 002.632-2.69V86.23zm-28.951 19.526a.676.676 0 01-.643-.672l-.016-18.16a.67.67 0 01.703-.673c7.536.345 11.42 2.454 11.42 3.319v17.603a.685.685 0 01-.305.561.673.673 0 01-.637.053 30.865 30.865 0 00-10.522-2.031z"></path>
      <path d="M181.712 91.51a32.357 32.357 0 00-6.973-1.371 1.01 1.01 0 10-.196 2.008c2.216.186 4.405.614 6.528 1.277a1.008 1.008 0 10.641-1.913zm0 5.614a32.318 32.318 0 00-6.973-1.367 1.01 1.01 0 00-.196 2.009c2.216.186 4.405.614 6.528 1.276a1.008 1.008 0 00.641-1.913v-.005zm0 5.613a32.357 32.357 0 00-6.973-1.372 1.02 1.02 0 00-.739.225 1.006 1.006 0 00-.363.681 1.019 1.019 0 00.224.739 1.01 1.01 0 00.682.364c2.216.185 4.405.613 6.528 1.276a1.006 1.006 0 001.264-.64 1.011 1.011 0 00-.623-1.273zm8.021-3.7a30.45 30.45 0 016.528-1.277 1.009 1.009 0 00-.196-2.008c-2.368.2-4.707.66-6.973 1.372a1.009 1.009 0 00.641 1.913zm0 5.613a30.376 30.376 0 016.528-1.276 1.009 1.009 0 00-.196-2.009c-2.368.2-4.706.66-6.973 1.372a1.008 1.008 0 00.641 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip15)">
      <path d="M243.74 4.731a2.73 2.73 0 00-.819-1.938 2.58 2.58 0 00-1.935-.754c-.39.011-.78.029-1.17.05a.746.746 0 01-.784-.744.675.675 0 00-.736-.672 33.854 33.854 0 00-5.49.941.676.676 0 00-.5.651v8.496a.674.674 0 001.149.477l1.738-1.74a.663.663 0 01.476-.197.677.677 0 01.476.197l1.739 1.74a.675.675 0 001.035-.103.678.678 0 00.113-.374V5.468a.668.668 0 01.628-.672c.226-.015.453-.027.686-.04a.675.675 0 01.652.408.672.672 0 01.054.265l-.017 18.16a.673.673 0 01-.645.672 30.816 30.816 0 00-10.505 2.027.675.675 0 01-.861-.296.673.673 0 01-.081-.32V8.071c0-.256.344-.619 1.013-1.012a.677.677 0 00.332-.581V4.961a.669.669 0 00-.318-.571.67.67 0 00-.653-.032 8.99 8.99 0 00-1.321.787.672.672 0 01-.796 0c-2.622-1.94-7.822-2.952-12.99-3.106a2.593 2.593 0 00-1.935.754 2.73 2.73 0 00-.819 1.938v19.505a2.69 2.69 0 002.631 2.69c8.027.227 12.166 2.444 12.166 3.34a1.346 1.346 0 002.296.951c.252-.252.394-.594.394-.95 0-.897 4.139-3.114 12.166-3.345a2.691 2.691 0 002.631-2.69V4.73zm-28.951 19.526a.672.672 0 01-.643-.672l-.016-18.16a.67.67 0 01.437-.63.672.672 0 01.267-.042c7.535.344 11.419 2.453 11.419 3.318v17.603a.672.672 0 01-.942.614 30.856 30.856 0 00-10.522-2.03z"></path>
      <path d="M223.909 10.012a32.368 32.368 0 00-6.974-1.372 1.004 1.004 0 00-.739.225 1.007 1.007 0 00-.138 1.42 1.002 1.002 0 00.681.363 30.5 30.5 0 016.528 1.277 1.008 1.008 0 10.642-1.913zm0 5.613a32.344 32.344 0 00-6.974-1.366 1.01 1.01 0 00-1.102.906 1.009 1.009 0 00.906 1.102 30.45 30.45 0 016.528 1.277 1.01 1.01 0 00.642-1.913v-.006zm0 5.614a32.366 32.366 0 00-6.974-1.372 1.002 1.002 0 00-.739.224 1.007 1.007 0 00-.138 1.42 1.005 1.005 0 00.681.364c2.216.185 4.405.613 6.528 1.276a1.01 1.01 0 00.642-1.912zm8.02-3.701a30.454 30.454 0 016.529-1.276 1.01 1.01 0 00-.197-2.009c-2.368.2-4.706.66-6.973 1.372a1.008 1.008 0 00.641 1.913zm0 5.613a30.32 30.32 0 016.529-1.276 1.01 1.01 0 00-.197-2.008c-2.368.2-4.706.66-6.973 1.372a1.008 1.008 0 00.641 1.912z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip16)">
      <path d="M286.055 4.731a2.715 2.715 0 00-.819-1.938 2.571 2.571 0 00-1.934-.754c-.39.011-.78.029-1.171.05a.739.739 0 01-.551-.203.738.738 0 01-.233-.54.674.674 0 00-.736-.673 33.86 33.86 0 00-5.489.941.669.669 0 00-.501.651v8.496a.673.673 0 001.149.476l1.738-1.739a.678.678 0 01.734-.146.678.678 0 01.219.146l1.739 1.74a.672.672 0 001.147-.477V5.468a.674.674 0 01.628-.673c.226-.014.454-.026.686-.038a.67.67 0 01.652.407.673.673 0 01.055.265l-.018 18.16a.673.673 0 01-.644.672 30.822 30.822 0 00-10.506 2.027.671.671 0 01-.941-.616V8.071c0-.256.344-.619 1.012-1.012a.666.666 0 00.333-.58V4.96a.68.68 0 00-.318-.571.678.678 0 00-.654-.032 9.032 9.032 0 00-1.32.787.675.675 0 01-.797 0c-2.622-1.94-7.822-2.952-12.99-3.106a2.593 2.593 0 00-1.934.754 2.716 2.716 0 00-.82 1.938v19.505a2.695 2.695 0 002.632 2.69c8.026.227 12.165 2.444 12.165 3.34a1.346 1.346 0 002.691 0c0-.896 4.139-3.113 12.165-3.344a2.69 2.69 0 002.631-2.69V4.73zm-28.95 19.526a.674.674 0 01-.643-.672l-.016-18.16a.67.67 0 01.436-.63.672.672 0 01.267-.042c7.536.344 11.419 2.453 11.419 3.318v17.603a.671.671 0 01-.941.614 30.856 30.856 0 00-10.522-2.03z"></path>
      <path d="M266.224 10.012a32.37 32.37 0 00-6.973-1.372 1.01 1.01 0 00-.878 1.645 1.006 1.006 0 00.681.363 30.5 30.5 0 016.528 1.277 1.008 1.008 0 10.642-1.913zm0 5.613a32.336 32.336 0 00-6.973-1.366 1.01 1.01 0 00-.197 2.008 30.45 30.45 0 016.528 1.277 1.01 1.01 0 00.642-1.913v-.006zm0 5.614a32.37 32.37 0 00-6.973-1.372 1.01 1.01 0 00-.197 2.008c2.217.185 4.406.613 6.528 1.276a1.01 1.01 0 00.642-1.912zm8.021-3.701a30.457 30.457 0 016.528-1.276 1.01 1.01 0 00-.196-2.009c-2.368.2-4.707.66-6.974 1.372a1.011 1.011 0 00-.623 1.273 1.01 1.01 0 001.265.64zm0 5.613a30.31 30.31 0 016.528-1.276 1.01 1.01 0 00-.196-2.008c-2.368.2-4.707.66-6.974 1.372a1.012 1.012 0 00-.623 1.273 1.01 1.01 0 001.265.64z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip17)">
      <path d="M328.371 4.731a2.717 2.717 0 00-.82-1.938 2.567 2.567 0 00-1.934-.754c-.39.011-.78.029-1.17.05a.746.746 0 01-.784-.744.675.675 0 00-.736-.672 33.854 33.854 0 00-5.49.941.676.676 0 00-.5.651v8.496a.674.674 0 001.149.477l1.738-1.74a.663.663 0 01.476-.197.677.677 0 01.476.197l1.739 1.74a.671.671 0 001.034-.103.671.671 0 00.114-.374V5.468a.668.668 0 01.628-.672c.226-.015.453-.027.686-.04a.675.675 0 01.652.408.672.672 0 01.054.265l-.017 18.16a.673.673 0 01-.645.672 30.811 30.811 0 00-10.505 2.027.677.677 0 01-.638-.053.674.674 0 01-.304-.563V8.071c0-.256.344-.619 1.013-1.012a.671.671 0 00.332-.58V4.96a.669.669 0 00-.318-.571.67.67 0 00-.653-.032 8.99 8.99 0 00-1.321.787.672.672 0 01-.796 0c-2.622-1.94-7.822-2.952-12.991-3.106a2.588 2.588 0 00-1.934.754 2.715 2.715 0 00-.819 1.938v19.505a2.69 2.69 0 002.631 2.69c8.027.227 12.166 2.444 12.166 3.34a1.342 1.342 0 001.345 1.345 1.344 1.344 0 001.345-1.345c0-.896 4.139-3.113 12.166-3.344a2.691 2.691 0 002.631-2.69V4.73zM299.42 24.257a.67.67 0 01-.643-.672l-.016-18.16a.67.67 0 01.437-.63.672.672 0 01.267-.042c7.535.344 11.419 2.453 11.419 3.318v17.603a.684.684 0 01-.305.561.678.678 0 01-.637.053 30.856 30.856 0 00-10.522-2.03z"></path>
      <path d="M308.539 10.012a32.359 32.359 0 00-6.973-1.372 1.01 1.01 0 00-1.102.906.999.999 0 00.224.739 1.019 1.019 0 00.682.363 30.5 30.5 0 016.528 1.277 1.006 1.006 0 001.092-.287 1.011 1.011 0 00-.451-1.626zm0 5.613a32.324 32.324 0 00-6.973-1.366 1.01 1.01 0 00-.878 1.644c.17.207.415.338.682.364a30.45 30.45 0 016.528 1.277 1.008 1.008 0 00.641-1.913v-.006zm0 5.614a32.357 32.357 0 00-6.973-1.372 1.01 1.01 0 10-.196 2.008c2.216.185 4.405.613 6.528 1.276a1.007 1.007 0 001.214-1.404 1.005 1.005 0 00-.573-.508zm8.021-3.701a30.457 30.457 0 016.528-1.276 1.01 1.01 0 10-.196-2.009c-2.368.2-4.706.66-6.973 1.372a1.008 1.008 0 00.641 1.913zm0 5.613a30.323 30.323 0 016.528-1.276 1.01 1.01 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.008 1.008 0 00.641 1.912z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip18)">
      <path d="M370.684 4.731a2.715 2.715 0 00-.819-1.938 2.58 2.58 0 00-1.934-.754c-.39.011-.781.029-1.171.05a.746.746 0 01-.784-.744.674.674 0 00-.736-.672 33.86 33.86 0 00-5.489.941.669.669 0 00-.501.651v8.496a.674.674 0 001.149.477l1.738-1.74a.684.684 0 01.476-.197.673.673 0 01.476.197l1.74 1.74a.672.672 0 001.147-.477V5.468a.673.673 0 01.628-.672c.226-.015.454-.027.686-.04a.673.673 0 01.707.673l-.018 18.16a.673.673 0 01-.644.672 30.817 30.817 0 00-10.506 2.027.671.671 0 01-.942-.616V8.071c0-.256.345-.619 1.013-1.012a.666.666 0 00.333-.58V4.96a.68.68 0 00-.318-.571.678.678 0 00-.654-.032 8.99 8.99 0 00-1.321.787.67.67 0 01-.796 0c-2.622-1.94-7.822-2.952-12.99-3.106a2.593 2.593 0 00-1.934.754 2.716 2.716 0 00-.82 1.938v19.505a2.695 2.695 0 002.632 2.69c8.026.227 12.165 2.444 12.165 3.34a1.346 1.346 0 002.296.951 1.34 1.34 0 00.394-.95c0-.897 4.139-3.114 12.166-3.345a2.691 2.691 0 002.631-2.69V4.73zm-28.95 19.526a.672.672 0 01-.643-.672l-.016-18.16a.671.671 0 01.703-.673c7.536.345 11.419 2.454 11.419 3.319v17.603a.671.671 0 01-.941.614 30.856 30.856 0 00-10.522-2.03z"></path>
      <path d="M350.853 10.012a32.37 32.37 0 00-6.973-1.372 1.01 1.01 0 00-.878 1.645 1.015 1.015 0 00.681.363 30.5 30.5 0 016.528 1.277 1.008 1.008 0 10.642-1.913zm0 5.613a32.336 32.336 0 00-6.973-1.366 1.01 1.01 0 00-.197 2.008 30.45 30.45 0 016.528 1.277 1.01 1.01 0 00.642-1.913v-.006zm0 5.614a32.37 32.37 0 00-6.973-1.372 1.01 1.01 0 00-.197 2.008 30.31 30.31 0 016.528 1.276 1.01 1.01 0 00.642-1.912zm8.021-3.701a30.457 30.457 0 016.528-1.276 1.01 1.01 0 00-.197-2.009c-2.367.2-4.706.66-6.973 1.372a1.011 1.011 0 00-.623 1.273 1.006 1.006 0 001.265.64zm0 5.613a30.31 30.31 0 016.528-1.276 1.01 1.01 0 00-.197-2.008c-2.367.2-4.706.66-6.973 1.372a1.012 1.012 0 00-.623 1.273 1.006 1.006 0 001.265.64z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip19)">
      <path d="M413 4.731a2.716 2.716 0 00-.82-1.938 2.571 2.571 0 00-1.934-.754c-.39.011-.78.029-1.17.05a.746.746 0 01-.784-.744.675.675 0 00-.736-.672 33.854 33.854 0 00-5.49.941.676.676 0 00-.5.651v8.496a.674.674 0 001.149.477l1.737-1.74a.678.678 0 01.734-.146.678.678 0 01.219.146l1.739 1.74a.672.672 0 001.148-.477V5.468a.668.668 0 01.628-.672c.226-.015.453-.027.686-.04a.668.668 0 01.497.185.677.677 0 01.209.488l-.018 18.16a.668.668 0 01-.644.672 30.822 30.822 0 00-10.506 2.027.671.671 0 01-.941-.616V8.071c0-.256.344-.619 1.013-1.012a.671.671 0 00.332-.58V4.96a.669.669 0 00-.318-.571.671.671 0 00-.653-.032 8.99 8.99 0 00-1.321.787.674.674 0 01-.796 0c-2.622-1.94-7.823-2.952-12.991-3.106a2.593 2.593 0 00-1.934.754 2.715 2.715 0 00-.819 1.938v19.505a2.69 2.69 0 002.631 2.69c8.027.227 12.166 2.444 12.166 3.34a1.342 1.342 0 001.345 1.345 1.345 1.345 0 001.345-1.345c0-.896 4.139-3.113 12.165-3.344a2.691 2.691 0 002.632-2.69V4.73zm-28.951 19.526a.67.67 0 01-.643-.672l-.016-18.16a.67.67 0 01.437-.63.672.672 0 01.267-.042c7.535.344 11.419 2.453 11.419 3.318v17.603a.684.684 0 01-.305.561.678.678 0 01-.637.053 30.856 30.856 0 00-10.522-2.03z"></path>
      <path d="M393.168 10.012a32.359 32.359 0 00-6.973-1.372 1.01 1.01 0 00-1.102.906.999.999 0 00.224.739 1.006 1.006 0 00.682.363 30.5 30.5 0 016.528 1.277 1.006 1.006 0 001.092-.287 1.011 1.011 0 00-.451-1.626zm0 5.613a32.324 32.324 0 00-6.973-1.366 1.01 1.01 0 00-.878 1.644c.17.207.415.338.682.364a30.45 30.45 0 016.528 1.277 1.008 1.008 0 00.641-1.913v-.006zm0 5.614a32.357 32.357 0 00-6.973-1.372 1.01 1.01 0 10-.196 2.008c2.216.185 4.405.613 6.528 1.276a1.007 1.007 0 001.214-1.404 1.008 1.008 0 00-.573-.508zm8.021-3.701a30.457 30.457 0 016.528-1.276 1.01 1.01 0 10-.196-2.009c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913zm0 5.613a30.323 30.323 0 016.528-1.276 1.01 1.01 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip20)">
      <path d="M243.74 45.48a2.73 2.73 0 00-.819-1.938 2.58 2.58 0 00-1.935-.753c-.39.01-.78.028-1.17.05a.746.746 0 01-.784-.745.675.675 0 00-.736-.672 33.86 33.86 0 00-5.49.942.677.677 0 00-.5.65v8.497a.674.674 0 001.149.476l1.738-1.74a.663.663 0 01.476-.197.677.677 0 01.476.198l1.739 1.739a.676.676 0 001.035-.103.679.679 0 00.113-.373v-5.294a.668.668 0 01.628-.672c.226-.015.453-.027.686-.04a.678.678 0 01.497.185.662.662 0 01.209.488l-.017 18.16a.674.674 0 01-.645.673 30.814 30.814 0 00-10.505 2.027.675.675 0 01-.942-.616V48.82c0-.255.344-.618 1.013-1.011a.676.676 0 00.332-.581V45.71a.669.669 0 00-.318-.571.67.67 0 00-.653-.031 8.983 8.983 0 00-1.321.787.672.672 0 01-.796 0c-2.622-1.94-7.822-2.953-12.99-3.106a2.592 2.592 0 00-1.935.753 2.73 2.73 0 00-.819 1.938v19.505a2.69 2.69 0 002.631 2.69c8.027.228 12.166 2.445 12.166 3.34a1.346 1.346 0 002.296.952 1.35 1.35 0 00.394-.952c0-.895 4.139-3.112 12.166-3.344a2.69 2.69 0 002.631-2.69v-19.5zm-28.951 19.527a.672.672 0 01-.643-.673l-.016-18.16a.67.67 0 01.704-.672c7.535.344 11.419 2.453 11.419 3.318v17.603a.672.672 0 01-.942.615 30.854 30.854 0 00-10.522-2.031z"></path>
      <path d="M223.909 50.761a32.366 32.366 0 00-6.974-1.372 1.002 1.002 0 00-.739.225 1.007 1.007 0 00-.138 1.42 1.005 1.005 0 00.681.364c2.216.186 4.405.614 6.528 1.276a1.01 1.01 0 001.234-1.407 1.016 1.016 0 00-.592-.506zm0 5.614a32.344 32.344 0 00-6.974-1.367 1.01 1.01 0 10-.196 2.008 30.45 30.45 0 016.528 1.277 1.01 1.01 0 00.642-1.913v-.005zm0 5.613a32.372 32.372 0 00-6.974-1.372 1.004 1.004 0 00-.988.529 1.018 1.018 0 00-.075.769 1.004 1.004 0 00.867.71c2.216.185 4.405.613 6.528 1.277a1.01 1.01 0 00.642-1.913zm8.02-3.7a30.448 30.448 0 016.529-1.277 1.01 1.01 0 00-.197-2.008c-2.368.2-4.706.66-6.973 1.372a1.008 1.008 0 00.641 1.913zm0 5.612a30.32 30.32 0 016.529-1.276 1.009 1.009 0 00-.197-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip21)">
      <path d="M286.055 45.48a2.714 2.714 0 00-.819-1.938 2.571 2.571 0 00-1.934-.753c-.39.01-.78.028-1.171.05a.738.738 0 01-.551-.204.738.738 0 01-.233-.54.674.674 0 00-.736-.673 33.868 33.868 0 00-5.489.942.67.67 0 00-.501.65v8.497a.673.673 0 001.149.476l1.738-1.74a.678.678 0 01.734-.146.678.678 0 01.219.147l1.739 1.739a.673.673 0 001.147-.476v-5.294a.674.674 0 01.628-.673c.226-.014.454-.026.686-.038a.672.672 0 01.707.672l-.018 18.16a.674.674 0 01-.644.673 30.82 30.82 0 00-10.506 2.027.671.671 0 01-.941-.616V48.82c0-.255.344-.618 1.012-1.011a.666.666 0 00.333-.581V45.71a.68.68 0 00-.318-.571.678.678 0 00-.654-.031 9.025 9.025 0 00-1.32.787.675.675 0 01-.797 0c-2.622-1.94-7.822-2.953-12.99-3.106a2.592 2.592 0 00-1.934.753 2.715 2.715 0 00-.82 1.938v19.505a2.695 2.695 0 002.632 2.69c8.026.228 12.165 2.445 12.165 3.34a1.346 1.346 0 002.691 0c0-.895 4.139-3.112 12.165-3.344a2.688 2.688 0 002.631-2.69v-19.5zm-28.95 19.527a.674.674 0 01-.643-.673l-.016-18.16a.67.67 0 01.703-.672c7.536.344 11.419 2.453 11.419 3.318v17.603a.671.671 0 01-.941.615 30.854 30.854 0 00-10.522-2.031z"></path>
      <path d="M266.224 50.761a32.37 32.37 0 00-6.973-1.372 1.01 1.01 0 00-.197 2.009c2.216.186 4.405.614 6.528 1.276a1.01 1.01 0 00.989-1.72 1.016 1.016 0 00-.347-.193zm0 5.614a32.335 32.335 0 00-6.973-1.367 1.009 1.009 0 10-.197 2.008 30.45 30.45 0 016.528 1.277 1.01 1.01 0 00.642-1.913v-.005zm0 5.613a32.375 32.375 0 00-6.973-1.372 1.011 1.011 0 00-1.103.906 1.018 1.018 0 00.225.739 1.008 1.008 0 00.681.363c2.217.185 4.406.613 6.528 1.277a1.01 1.01 0 00.642-1.913zm8.021-3.7a30.45 30.45 0 016.528-1.277 1.01 1.01 0 00-.196-2.008c-2.368.2-4.707.66-6.974 1.372a1.012 1.012 0 00-.623 1.273 1.01 1.01 0 001.265.64zm0 5.612a30.31 30.31 0 016.528-1.276 1.009 1.009 0 00-.196-2.008c-2.368.2-4.707.66-6.974 1.372a1.012 1.012 0 00-.623 1.273 1.01 1.01 0 001.265.64z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip22)">
      <path d="M328.371 45.48a2.717 2.717 0 00-.82-1.938 2.567 2.567 0 00-1.934-.753c-.39.01-.78.028-1.17.05a.746.746 0 01-.784-.745.675.675 0 00-.736-.672 33.86 33.86 0 00-5.49.942.677.677 0 00-.5.65v8.497a.674.674 0 001.149.476l1.738-1.74a.663.663 0 01.476-.197.677.677 0 01.476.198l1.739 1.739a.672.672 0 001.148-.476v-5.294a.668.668 0 01.628-.672c.226-.015.453-.027.686-.04a.678.678 0 01.497.185.676.676 0 01.209.488l-.017 18.16a.674.674 0 01-.645.673 30.809 30.809 0 00-10.505 2.027.677.677 0 01-.861-.297.673.673 0 01-.081-.32v-17.6c0-.256.344-.62 1.013-1.012a.67.67 0 00.332-.581V45.71a.669.669 0 00-.318-.571.67.67 0 00-.653-.031 8.983 8.983 0 00-1.321.787.672.672 0 01-.796 0c-2.622-1.94-7.822-2.953-12.991-3.106a2.587 2.587 0 00-1.934.753 2.714 2.714 0 00-.819 1.938v19.505a2.69 2.69 0 002.631 2.69c8.027.228 12.166 2.445 12.166 3.34a1.342 1.342 0 001.345 1.346 1.344 1.344 0 001.345-1.346c0-.895 4.139-3.112 12.166-3.344a2.69 2.69 0 002.631-2.69v-19.5zM299.42 65.007a.67.67 0 01-.643-.673l-.016-18.16a.67.67 0 01.704-.672c7.535.344 11.419 2.453 11.419 3.318v17.603a.684.684 0 01-.305.561.677.677 0 01-.637.054 30.854 30.854 0 00-10.522-2.031z"></path>
      <path d="M308.539 50.761a32.357 32.357 0 00-6.973-1.372 1.01 1.01 0 10-.196 2.009c2.216.186 4.405.614 6.528 1.276a1.008 1.008 0 10.641-1.913zm0 5.614a32.324 32.324 0 00-6.973-1.367 1.01 1.01 0 10-.196 2.008 30.45 30.45 0 016.528 1.277 1.007 1.007 0 001.214-1.404 1.006 1.006 0 00-.573-.509v-.005zm0 5.613a32.363 32.363 0 00-6.973-1.372 1.011 1.011 0 00-.988.529 1 1 0 00-.075.769.999.999 0 00.49.596 1 1 0 00.377.114c2.216.185 4.405.613 6.528 1.277a1.008 1.008 0 00.641-1.913zm8.021-3.7a30.45 30.45 0 016.528-1.277 1.01 1.01 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.008 1.008 0 00.641 1.913zm0 5.612a30.323 30.323 0 016.528-1.276 1.009 1.009 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip23)">
      <path d="M370.684 45.48a2.714 2.714 0 00-.819-1.938 2.58 2.58 0 00-1.934-.753c-.39.01-.781.028-1.171.05a.746.746 0 01-.784-.745.674.674 0 00-.736-.672 33.868 33.868 0 00-5.489.942.67.67 0 00-.501.65v8.497a.674.674 0 001.149.476l1.738-1.74a.685.685 0 01.476-.197.673.673 0 01.476.198l1.74 1.739a.673.673 0 001.147-.476v-5.294a.673.673 0 01.628-.672c.226-.015.454-.027.686-.04a.675.675 0 01.707.673l-.018 18.16a.674.674 0 01-.644.673 30.815 30.815 0 00-10.506 2.027.671.671 0 01-.861-.297.673.673 0 01-.081-.32v-17.6c0-.256.345-.62 1.013-1.012a.666.666 0 00.333-.581V45.71a.68.68 0 00-.318-.571.678.678 0 00-.654-.031 8.983 8.983 0 00-1.321.787.67.67 0 01-.796 0c-2.622-1.94-7.822-2.953-12.99-3.106a2.592 2.592 0 00-1.934.753 2.715 2.715 0 00-.82 1.938v19.505a2.695 2.695 0 002.632 2.69c8.026.228 12.165 2.445 12.165 3.34a1.346 1.346 0 002.296.952c.253-.253.394-.595.394-.952 0-.895 4.139-3.112 12.166-3.344a2.69 2.69 0 002.631-2.69v-19.5zm-28.95 19.527a.672.672 0 01-.643-.673l-.016-18.16a.671.671 0 01.703-.673c7.536.345 11.419 2.454 11.419 3.32v17.602a.671.671 0 01-.941.615 30.854 30.854 0 00-10.522-2.031z"></path>
      <path d="M350.853 50.761a32.37 32.37 0 00-6.973-1.372 1.01 1.01 0 00-.197 2.009c2.216.186 4.405.614 6.528 1.276a1.01 1.01 0 00.989-1.72 1.016 1.016 0 00-.347-.193zm0 5.614a32.335 32.335 0 00-6.973-1.367 1.009 1.009 0 10-.197 2.008 30.45 30.45 0 016.528 1.277 1.01 1.01 0 00.642-1.913v-.005zm0 5.613a32.375 32.375 0 00-6.973-1.372 1.011 1.011 0 00-1.103.906 1.018 1.018 0 00.225.739 1.016 1.016 0 00.681.363 30.31 30.31 0 016.528 1.277 1.01 1.01 0 00.642-1.913zm8.021-3.7a30.45 30.45 0 016.528-1.277 1.01 1.01 0 00-.197-2.008c-2.367.2-4.706.66-6.973 1.372a1.012 1.012 0 00-.623 1.273 1.006 1.006 0 001.265.64zm0 5.612a30.31 30.31 0 016.528-1.276 1.009 1.009 0 00-.197-2.008c-2.367.2-4.706.66-6.973 1.372a1.012 1.012 0 00-.623 1.273 1.007 1.007 0 001.265.64z"></path>
    </g>
    <g fill="#fff" clipPath="url(#clip24)">
      <path d="M413 45.48a2.715 2.715 0 00-.82-1.938 2.571 2.571 0 00-1.934-.753c-.39.01-.78.028-1.17.05a.746.746 0 01-.784-.745.675.675 0 00-.736-.672 33.86 33.86 0 00-5.49.942.677.677 0 00-.5.65v8.497a.674.674 0 001.149.476l1.737-1.74a.678.678 0 01.734-.146.678.678 0 01.219.147l1.739 1.739a.673.673 0 001.148-.476v-5.294a.668.668 0 01.628-.672c.226-.015.453-.027.686-.04a.67.67 0 01.652.408.673.673 0 01.054.265l-.018 18.16a.668.668 0 01-.644.673 30.82 30.82 0 00-10.506 2.027.671.671 0 01-.941-.616V48.82c0-.255.344-.618 1.013-1.011a.67.67 0 00.332-.581V45.71a.669.669 0 00-.318-.571.67.67 0 00-.653-.031 8.983 8.983 0 00-1.321.787.674.674 0 01-.796 0c-2.622-1.94-7.823-2.953-12.991-3.106a2.592 2.592 0 00-1.934.753 2.714 2.714 0 00-.819 1.938v19.505a2.69 2.69 0 002.631 2.69c8.027.228 12.166 2.445 12.166 3.34a1.342 1.342 0 001.345 1.346 1.346 1.346 0 001.345-1.346c0-.895 4.139-3.112 12.165-3.344a2.69 2.69 0 002.632-2.69v-19.5zm-28.951 19.527a.67.67 0 01-.643-.673l-.016-18.16a.67.67 0 01.704-.672c7.535.344 11.419 2.453 11.419 3.318v17.603a.684.684 0 01-.305.561.677.677 0 01-.637.054 30.854 30.854 0 00-10.522-2.031z"></path>
      <path d="M393.168 50.761a32.357 32.357 0 00-6.973-1.372 1.01 1.01 0 10-.196 2.009c2.216.186 4.405.614 6.528 1.276a1.008 1.008 0 10.641-1.913zm0 5.614a32.324 32.324 0 00-6.973-1.367 1.01 1.01 0 10-.196 2.008 30.45 30.45 0 016.528 1.277 1.007 1.007 0 001.214-1.404 1.009 1.009 0 00-.573-.509v-.005zm0 5.613a32.363 32.363 0 00-6.973-1.372 1.011 1.011 0 00-.988.529 1 1 0 00-.075.769.999.999 0 00.49.596 1 1 0 00.377.114c2.216.185 4.405.613 6.528 1.277a1.008 1.008 0 00.641-1.913zm8.021-3.7a30.45 30.45 0 016.528-1.277 1.01 1.01 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913zm0 5.612a30.323 30.323 0 016.528-1.276 1.009 1.009 0 10-.196-2.008c-2.368.2-4.706.66-6.973 1.372a1.009 1.009 0 00.641 1.913z"></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h32.284v32.284H0z"></path>
      </clipPath>
      <clipPath id="clip1">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(42.315)"
        ></path>
      </clipPath>
      <clipPath id="clip2">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(84.63)"
        ></path>
      </clipPath>
      <clipPath id="clip3">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(126.944)"
        ></path>
      </clipPath>
      <clipPath id="clip4">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(169.26)"
        ></path>
      </clipPath>
      <clipPath id="clip5">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(0 40.75)"
        ></path>
      </clipPath>
      <clipPath id="clip6">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(42.315 40.75)"
        ></path>
      </clipPath>
      <clipPath id="clip7">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(84.63 40.75)"
        ></path>
      </clipPath>
      <clipPath id="clip8">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(126.944 40.75)"
        ></path>
      </clipPath>
      <clipPath id="clip9">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(169.26 40.75)"
        ></path>
      </clipPath>
      <clipPath id="clip10">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(0 81.499)"
        ></path>
      </clipPath>
      <clipPath id="clip11">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(42.315 81.499)"
        ></path>
      </clipPath>
      <clipPath id="clip12">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(84.63 81.499)"
        ></path>
      </clipPath>
      <clipPath id="clip13">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(126.944 81.499)"
        ></path>
      </clipPath>
      <clipPath id="clip14">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(169.26 81.499)"
        ></path>
      </clipPath>
      <clipPath id="clip15">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(211.456)"
        ></path>
      </clipPath>
      <clipPath id="clip16">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(253.771)"
        ></path>
      </clipPath>
      <clipPath id="clip17">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(296.087)"
        ></path>
      </clipPath>
      <clipPath id="clip18">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(338.4)"
        ></path>
      </clipPath>
      <clipPath id="clip19">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(380.716)"
        ></path>
      </clipPath>
      <clipPath id="clip20">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(211.456 40.75)"
        ></path>
      </clipPath>
      <clipPath id="clip21">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(253.771 40.75)"
        ></path>
      </clipPath>
      <clipPath id="clip22">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(296.087 40.75)"
        ></path>
      </clipPath>
      <clipPath id="clip23">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(338.4 40.75)"
        ></path>
      </clipPath>
      <clipPath id="clip24">
        <path
          fill="#fff"
          d="M0 0h32.284v32.284H0z"
          transform="translate(380.716 40.75)"
        ></path>
      </clipPath>
    </defs>
  </svg>
)

export default Icon
