/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import ImageOverlap from "components/image-overlap"

const One = () => {
  const data = useStaticQuery(graphql`
    query CommunityDevelopmentTwoQuery {
      image: file(relativePath: { eq: "community-development-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ImageOverlap
      bg="secondary"
      color="dark"
      imageSide="left"
      image={data.image.childImageSharp.fluid}
    >
      Catherine came from Cameroon with her four young children. With no family here and Catherine working on her Bachelor of Education degree, life was a struggle. “As a single mother, I rely on the Food Bank to get enough healthy food for my family. I look forward to when I’ll have a job and can give back. But today, I just want to thank the donors for their big hearts.”

    </ImageOverlap>
  )
}

export default One