/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="825"
    height="96"
    viewBox="0 0 825 96"
    fill="none"
    sx={{ width: "100%", height: "auto" }}
    className={className}
  >
    <rect width="825" height="66" y="15" fill="#CD1A22" rx="10"></rect>
    <path
      fill="#E31B23"
      d="M0 25c0-5.523 4.477-10 10-10h321v66H10C4.477 81 0 76.523 0 71V25z"
    ></path>
    <path
      fill="#fff"
      d="M38.64 32C28.9 32 21 39.89 21 49.64c0 9.74 7.9 17.64 17.64 17.64s17.64-7.9 17.64-17.64C56.27 39.89 48.38 32 38.64 32zm0 30.98c-7.36 0-13.34-5.99-13.34-13.34 0-7.36 5.99-13.34 13.34-13.34 7.36 0 13.34 5.99 13.34 13.34s-5.99 13.34-13.34 13.34z"
    ></path>
    <path
      fill="#fff"
      d="M38.67 47.45c-.44-.14-.89-.29-1.12-.41-.59-.3-.83-.74-.69-1.32.15-.62.71-1.24 1.63-1.24 1.68 0 2.55 1.62 2.59 1.69l3.19-1.61c-.52-1.05-1.83-2.66-3.85-3.34v-2.18h-3.58v2.13a5.19 5.19 0 00-3.47 3.73c-.52 2.2.49 4.3 2.57 5.34.47.24 1.02.42 1.61.61 2.21.72 3.19 1.24 3.16 2.4-.03 1.07-1.05 1.36-1.48 1.44-1.07.18-2.46-.24-2.97-1.59l-3.35 1.27c.76 2.01 2.29 3.16 3.92 3.65v2.22h3.58v-2.17c2.28-.62 3.81-2.45 3.87-4.72.12-4.02-3.61-5.25-5.61-5.9zM121.64 32C111.9 32 104 39.89 104 49.64c0 9.74 7.9 17.64 17.64 17.64s17.64-7.9 17.64-17.64c-.01-9.75-7.9-17.64-17.64-17.64zm0 30.98c-7.36 0-13.34-5.99-13.34-13.34 0-7.36 5.99-13.34 13.34-13.34 7.36 0 13.34 5.99 13.34 13.34s-5.99 13.34-13.34 13.34z"
    ></path>
    <path
      fill="#fff"
      d="M121.67 47.45c-.44-.14-.89-.29-1.12-.41-.59-.3-.83-.74-.69-1.32.15-.62.71-1.24 1.63-1.24 1.68 0 2.55 1.62 2.59 1.69l3.19-1.61c-.52-1.05-1.83-2.66-3.85-3.34v-2.18h-3.58v2.13a5.19 5.19 0 00-3.47 3.73c-.52 2.2.49 4.3 2.57 5.34.47.24 1.02.42 1.61.61 2.21.72 3.19 1.24 3.16 2.4-.03 1.07-1.05 1.36-1.48 1.44-1.07.18-2.46-.24-2.97-1.59l-3.35 1.27c.76 2.01 2.29 3.16 3.92 3.65v2.22h3.58v-2.17c2.28-.62 3.81-2.45 3.87-4.72.12-4.02-3.61-5.25-5.61-5.9zM203.64 32C193.9 32 186 39.89 186 49.64c0 9.74 7.9 17.64 17.64 17.64s17.64-7.9 17.64-17.64c-.01-9.75-7.9-17.64-17.64-17.64zm0 30.98c-7.36 0-13.34-5.99-13.34-13.34 0-7.36 5.99-13.34 13.34-13.34 7.36 0 13.34 5.99 13.34 13.34s-5.99 13.34-13.34 13.34z"
    ></path>
    <path
      fill="#fff"
      d="M203.67 47.45c-.44-.14-.89-.29-1.12-.41-.59-.3-.83-.74-.69-1.32.15-.62.71-1.24 1.63-1.24 1.68 0 2.55 1.62 2.59 1.69l3.19-1.61c-.52-1.05-1.83-2.66-3.85-3.34v-2.18h-3.58v2.13a5.19 5.19 0 00-3.47 3.73c-.52 2.2.49 4.3 2.57 5.34.47.24 1.02.42 1.61.61 2.21.72 3.19 1.24 3.16 2.4-.03 1.07-1.05 1.36-1.48 1.44-1.07.18-2.46-.24-2.97-1.59l-3.35 1.27c.76 2.01 2.29 3.16 3.92 3.65v2.22h3.58v-2.17c2.28-.62 3.81-2.45 3.87-4.72.12-4.02-3.61-5.25-5.61-5.9zM285.64 32C275.9 32 268 39.89 268 49.64c0 9.74 7.9 17.64 17.64 17.64s17.64-7.9 17.64-17.64c-.01-9.75-7.9-17.64-17.64-17.64zm0 30.98c-7.36 0-13.34-5.99-13.34-13.34 0-7.36 5.99-13.34 13.34-13.34 7.36 0 13.34 5.99 13.34 13.34s-5.99 13.34-13.34 13.34z"
    ></path>
    <path
      fill="#fff"
      d="M285.67 47.45c-.44-.14-.89-.29-1.12-.41-.59-.3-.83-.74-.69-1.32.15-.62.71-1.24 1.63-1.24 1.68 0 2.55 1.62 2.59 1.69l3.19-1.61c-.52-1.05-1.83-2.66-3.85-3.34v-2.18h-3.58v2.13a5.19 5.19 0 00-3.47 3.73c-.52 2.2.49 4.3 2.57 5.34.47.24 1.02.42 1.61.61 2.21.72 3.19 1.24 3.16 2.4-.03 1.07-1.05 1.36-1.48 1.44-1.07.18-2.46-.24-2.97-1.59l-3.35 1.27c.76 2.01 2.29 3.16 3.92 3.65v2.22h3.58v-2.17c2.28-.62 3.81-2.45 3.87-4.72.12-4.02-3.61-5.25-5.61-5.9z"
    ></path>
    <path
      fill="#B6141B"
      d="M370.64 32C360.9 32 353 39.89 353 49.64c0 9.74 7.9 17.64 17.64 17.64s17.64-7.9 17.64-17.64c-.01-9.75-7.9-17.64-17.64-17.64zm0 30.98c-7.36 0-13.34-5.99-13.34-13.34 0-7.36 5.99-13.34 13.34-13.34 7.36 0 13.34 5.99 13.34 13.34s-5.99 13.34-13.34 13.34z"
    ></path>
    <path
      fill="#B6141B"
      d="M370.67 47.45c-.44-.14-.89-.29-1.12-.41-.59-.3-.83-.74-.69-1.32.15-.62.71-1.24 1.63-1.24 1.68 0 2.55 1.62 2.59 1.69l3.19-1.61c-.52-1.05-1.83-2.66-3.85-3.34v-2.18h-3.58v2.13a5.19 5.19 0 00-3.47 3.73c-.52 2.2.49 4.3 2.57 5.34.47.24 1.02.42 1.61.61 2.21.72 3.19 1.24 3.16 2.4-.03 1.07-1.05 1.36-1.48 1.44-1.07.18-2.46-.24-2.97-1.59l-3.35 1.27c.76 2.01 2.29 3.16 3.92 3.65v2.22h3.58v-2.17c2.28-.62 3.81-2.45 3.87-4.72.12-4.02-3.61-5.25-5.61-5.9zM452.64 32C442.9 32 435 39.89 435 49.64c0 9.74 7.9 17.64 17.64 17.64s17.64-7.9 17.64-17.64c-.01-9.75-7.9-17.64-17.64-17.64zm0 30.98c-7.36 0-13.34-5.99-13.34-13.34 0-7.36 5.99-13.34 13.34-13.34 7.36 0 13.34 5.99 13.34 13.34s-5.99 13.34-13.34 13.34z"
    ></path>
    <path
      fill="#B6141B"
      d="M452.67 47.45c-.44-.14-.89-.29-1.12-.41-.59-.3-.83-.74-.69-1.32.15-.62.71-1.24 1.63-1.24 1.68 0 2.55 1.62 2.59 1.69l3.19-1.61c-.52-1.05-1.83-2.66-3.85-3.34v-2.18h-3.58v2.13a5.19 5.19 0 00-3.47 3.73c-.52 2.2.49 4.3 2.57 5.34.47.24 1.02.42 1.61.61 2.21.72 3.19 1.24 3.16 2.4-.03 1.07-1.05 1.36-1.48 1.44-1.07.18-2.46-.24-2.97-1.59l-3.35 1.27c.76 2.01 2.29 3.16 3.92 3.65v2.22h3.58v-2.17c2.28-.62 3.81-2.45 3.87-4.72.12-4.02-3.61-5.25-5.61-5.9zM535.64 32C525.9 32 518 39.89 518 49.64c0 9.74 7.9 17.64 17.64 17.64s17.64-7.9 17.64-17.64c-.01-9.75-7.9-17.64-17.64-17.64zm0 30.98c-7.36 0-13.34-5.99-13.34-13.34 0-7.36 5.99-13.34 13.34-13.34 7.36 0 13.34 5.99 13.34 13.34s-5.99 13.34-13.34 13.34z"
    ></path>
    <path
      fill="#B6141B"
      d="M535.67 47.45c-.44-.14-.89-.29-1.12-.41-.59-.3-.83-.74-.69-1.32.15-.62.71-1.24 1.63-1.24 1.68 0 2.55 1.62 2.59 1.69l3.19-1.61c-.52-1.05-1.83-2.66-3.85-3.34v-2.18h-3.58v2.13a5.19 5.19 0 00-3.47 3.73c-.52 2.2.49 4.3 2.57 5.34.47.24 1.02.42 1.61.61 2.21.72 3.19 1.24 3.16 2.4-.03 1.07-1.05 1.36-1.48 1.44-1.07.18-2.46-.24-2.97-1.59l-3.35 1.27c.76 2.01 2.29 3.16 3.92 3.65v2.22h3.58v-2.17c2.28-.62 3.81-2.45 3.87-4.72.12-4.02-3.61-5.25-5.61-5.9zM618.64 32C608.9 32 601 39.89 601 49.64c0 9.74 7.9 17.64 17.64 17.64s17.64-7.9 17.64-17.64c-.01-9.75-7.9-17.64-17.64-17.64zm0 30.98c-7.36 0-13.34-5.99-13.34-13.34 0-7.36 5.99-13.34 13.34-13.34 7.36 0 13.34 5.99 13.34 13.34s-5.99 13.34-13.34 13.34z"
    ></path>
    <path
      fill="#B6141B"
      d="M618.67 47.45c-.44-.14-.89-.29-1.12-.41-.59-.3-.83-.74-.69-1.32.15-.62.71-1.24 1.63-1.24 1.68 0 2.55 1.62 2.59 1.69l3.19-1.61c-.52-1.05-1.83-2.66-3.85-3.34v-2.18h-3.58v2.13a5.19 5.19 0 00-3.47 3.73c-.52 2.2.49 4.3 2.57 5.34.47.24 1.02.42 1.61.61 2.21.72 3.19 1.24 3.16 2.4-.03 1.07-1.05 1.36-1.48 1.44-1.07.18-2.46-.24-2.97-1.59l-3.35 1.27c.76 2.01 2.29 3.16 3.92 3.65v2.22h3.58v-2.17c2.28-.62 3.81-2.45 3.87-4.72.12-4.02-3.61-5.25-5.61-5.9zM701.64 32C691.9 32 684 39.89 684 49.64c0 9.74 7.9 17.64 17.64 17.64s17.64-7.9 17.64-17.64c-.01-9.75-7.9-17.64-17.64-17.64zm0 30.98c-7.36 0-13.34-5.99-13.34-13.34 0-7.36 5.99-13.34 13.34-13.34 7.36 0 13.34 5.99 13.34 13.34s-5.99 13.34-13.34 13.34z"
    ></path>
    <path
      fill="#B6141B"
      d="M701.67 47.45c-.44-.14-.89-.29-1.12-.41-.59-.3-.83-.74-.69-1.32.15-.62.71-1.24 1.63-1.24 1.68 0 2.55 1.62 2.59 1.69l3.19-1.61c-.52-1.05-1.83-2.66-3.85-3.34v-2.18h-3.58v2.13a5.19 5.19 0 00-3.47 3.73c-.52 2.2.49 4.3 2.57 5.34.47.24 1.02.42 1.61.61 2.21.72 3.19 1.24 3.16 2.4-.03 1.07-1.05 1.36-1.48 1.44-1.07.18-2.46-.24-2.97-1.59l-3.35 1.27c.76 2.01 2.29 3.16 3.92 3.65v2.22h3.58v-2.17c2.28-.62 3.81-2.45 3.87-4.72.12-4.02-3.61-5.25-5.61-5.9zM785.64 32C775.9 32 768 39.89 768 49.64c0 9.74 7.9 17.64 17.64 17.64s17.64-7.9 17.64-17.64c-.01-9.75-7.9-17.64-17.64-17.64zm0 30.98c-7.36 0-13.34-5.99-13.34-13.34 0-7.36 5.99-13.34 13.34-13.34 7.36 0 13.34 5.99 13.34 13.34s-5.99 13.34-13.34 13.34z"
    ></path>
    <path
      fill="#B6141B"
      d="M785.67 47.45c-.44-.14-.89-.29-1.12-.41-.59-.3-.83-.74-.69-1.32.15-.62.71-1.24 1.63-1.24 1.68 0 2.55 1.62 2.59 1.69l3.19-1.61c-.52-1.05-1.83-2.66-3.85-3.34v-2.18h-3.58v2.13a5.19 5.19 0 00-3.47 3.73c-.52 2.2.49 4.3 2.57 5.34.47.24 1.02.42 1.61.61 2.21.72 3.19 1.24 3.16 2.4-.03 1.07-1.05 1.36-1.48 1.44-1.07.18-2.46-.24-2.97-1.59l-3.35 1.27c.76 2.01 2.29 3.16 3.92 3.65v2.22h3.58v-2.17c2.28-.62 3.81-2.45 3.87-4.72.12-4.02-3.61-5.25-5.61-5.9z"
    ></path>
    <path stroke="#E31B23" strokeWidth="2" d="M821 5H336m485 86H336"></path>
    <path
      fill="#E31B23"
      d="M331 5l6.75-4.33v8.66L331 5zm0 86l6.75-4.33v8.66L331 91z"
    ></path>
  </svg>
)

export default Icon
