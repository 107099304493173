/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#292929"
      d="M75.612 20.96a5.017 5.017 0 00-7.102 0 5.017 5.017 0 000 7.102L82.097 41.65 70.314 53.433l9.888 9.888c1.233 1.234 1.233 3.22 0 4.43a3.11 3.11 0 01-2.215.914 3.11 3.11 0 01-2.215-.913l-9.888-9.889-7.947 7.948 13.108 13.108c1.233 1.233 1.233 3.22 0 4.43a3.11 3.11 0 01-2.216.913 3.11 3.11 0 01-2.215-.913L53.506 70.24l-7.947 7.947 9.888 9.888c1.233 1.233 1.233 3.22 0 4.43a3.11 3.11 0 01-2.215.914 3.11 3.11 0 01-2.215-.914l-9.888-9.888-9.386 9.386a3.101 3.101 0 00-.913 2.26l.32 19.252-22.038 22.037a3.777 3.777 0 000 5.321 3.747 3.747 0 002.672 1.096c.982 0 1.918-.365 2.672-1.096l22.037-22.037 19.251.319h.046c.822 0 1.621-.342 2.215-.913l50.354-50.354 13.588 13.587a4.992 4.992 0 003.563 1.462c1.301 0 2.58-.48 3.562-1.462a5.017 5.017 0 000-7.102L75.612 20.96zm64.901 20.324L108.725 9.473a5.017 5.017 0 00-7.102 0 5.017 5.017 0 000 7.102l5.503 5.504-7.833 7.855a3.777 3.777 0 000 5.321 3.748 3.748 0 002.672 1.096c.982 0 1.919-.365 2.672-1.096l7.833-7.833 10.117 10.117-7.833 7.833a3.776 3.776 0 000 5.32 3.747 3.747 0 002.672 1.097c.981 0 1.918-.366 2.671-1.096l7.833-7.833 5.504 5.503a4.992 4.992 0 003.562 1.462c1.302 0 2.581-.48 3.563-1.462 1.918-1.94 1.918-5.115-.046-7.079z"
    ></path>
  </svg>
)

export default Icon
