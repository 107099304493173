/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import PropTypes from "prop-types"

const SubHead = ({ sm, children }, props) => (
  <Styled.p
    {...props}
    sx={{
      color: "accent",
      fontFamily: "heading",
      fontSize: sm ? [4, 5] : [5, 6],
      ...props.sx,
    }}
  >
    {children}
  </Styled.p>
)

SubHead.propTypes = {
  sm: PropTypes.bool,
}

SubHead.defaultProps = {
  sm: false,
}

export default SubHead
