/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <rect
      width="22.154"
      height="2.769"
      x="1"
      y="10.308"
      fill="currentColor"
      rx="1.385"
    ></rect>
    <rect
      width="22.154"
      height="2.769"
      x="1"
      y="2"
      fill="currentColor"
      rx="1.385"
    ></rect>
    <rect
      width="22.154"
      height="2.769"
      x="1"
      y="18.616"
      fill="currentColor"
      rx="1.385"
    ></rect>
  </svg>
)

export default Icon
