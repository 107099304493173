/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
		height="34"
		viewBox="0 0 39 34"
    fill="none"
    className={className}
  >
    <path
      fill="#E31B23"
      d="M23.292 8.367c0-.88-.728-1.594-1.625-1.594-.898 0-1.625.714-1.625 1.594 0 .88.727 1.594 1.625 1.594.897 0 1.625-.714 1.625-1.594zm-1.625 15.008c.337 0 .674-.134.915-.403 2.387-2.656 8.618-9.97 8.618-13.984C31.2 4.024 26.932 0 21.667 0s-9.534 4.024-9.534 8.988c0 4.013 6.23 11.328 8.618 13.984.241.269.578.403.916.403zm0-20.188c3.464 0 6.283 2.603 6.283 5.801 0 1.421-2.152 5.252-6.283 10.132-4.133-4.88-6.284-8.71-6.284-10.132 0-3.198 2.819-5.8 6.284-5.8zm16.251 7.438c-.133 0-.27.025-.404.077l-4.975 1.803c-.558 1.355-1.367 2.791-2.317 4.237l5.528-2.003v12.814l-8.667 2.957v-9.508a85.198 85.198 0 01-2.875 3.376c-.113.125-.245.23-.375.336v5.407l-8.666-3v-7.507c-1.223-1.6-2.356-3.24-3.25-4.837v11.967l-.044.016-8.623 3.126V17.072l8.366-2.854a16.702 16.702 0 01-1.236-2.953L1.362 14.34a2.16 2.16 0 00-.99.782A2.097 2.097 0 000 16.314v16.623C0 33.547.51 34 1.083 34c.132 0 .268-.025.403-.077L13 29.75l11.646 4.032c.88.287 1.83.29 2.712.01l10.28-3.507a2.16 2.16 0 00.99-.782c.242-.352.372-.767.372-1.191V11.689c0-.61-.51-1.064-1.082-1.064z"
    ></path>
  </svg>
)

export default Icon
