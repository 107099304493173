/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { InfographicImage } from "components/infographic"
import Icon from "components/svgs/urban-health-icon-1"

const One = () => {
  const data = useStaticQuery(graphql`
    query UrbanHealthOneQuery {
      image: file(relativePath: { eq: "urban-health-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <InfographicImage
      bg="accent"
      color="white"
      imageSide="right"
      image={data.image.childImageSharp.fluid}
      Icon={Icon}
      value="1,200+"
    >
      people in COVID recovery hotels improved their mental and physical health through the compassion and dedication of our peer workers
    </InfographicImage>
  )
}

export default One
