/** @jsx jsx */
import { jsx, Flex } from "theme-ui"

import { Container, Row, Col } from "components/layout"
import { Medium, ExtraLarge } from "components/type"
import Panel from "components/panel"

const Infographic = ({ bg, color, Icon, value, children }) => (
  <Panel bg={bg} color={color}>
    <Container>
      <Row sx={{ alignItems: "center" }}>
        <Col>
          <ExtraLarge color={color}>
            <Flex
              sx={{
                alignItems: "center",
                borderRight: ["none", "medium solid"],
                borderRightColor: color,
                pr: [0, 4],
              }}
            >
              {Icon ? <Icon sx={{ mr: 4, mb: 4, width: "100px", height: "auto" }} /> : null}
              {value}
            </Flex>
          </ExtraLarge>
        </Col>
        <Col sx={{ width: ["100%", "50%"] }}>
          <Medium>{children}</Medium>
        </Col>
      </Row>
    </Container>
  </Panel>
)

Infographic.defaultProps = {
  bg: "primary",
  color: "background",
}

export { default as Reverse } from "./reverse"
export { default as InfographicImage } from "./with-image"

export default Infographic
