/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { InfographicImage } from "components/infographic"
import Icon from "components/svgs/independent-living-seniors-icon-3"

const One = () => {
    const data = useStaticQuery(graphql`
    query IndependentLivingThreeQuery {
      image: file(relativePath: { eq: "independent-living-seniors-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <InfographicImage
            bg="accent"
            color="white"
            imageSide="right"
            image={data.image.childImageSharp.fluid}
            Icon={Icon}
            value="1"
        >
            Stoke Survivors is the only Adult Day Program in Toronto exclusively for stroke survivors
        </InfographicImage>
    )
}

export default One
