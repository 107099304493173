/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { InfographicImage } from "components/infographic"
import Icon from "components/svgs/childcare-icon-2"

const One = () => {
  const data = useStaticQuery(graphql`
    query ChildcareOneQuery {
      image: file(relativePath: { eq: "childcare-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <InfographicImage
      bg="secondary"
      color="dark"
      imageSide="right"
      image={data.image.childImageSharp.fluid}
      Icon={Icon}
      value="847"
    >
      children thrive with early childhood education and play-based learning in our ten provincially-licensed childcare centres
    </InfographicImage>
  )
}

export default One
