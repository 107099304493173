/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { InfographicImage } from "components/infographic"
import Icon from "components/svgs/independent-living-seniors-icon-1"

const One = () => {
  const data = useStaticQuery(graphql`
    query IndependentLivingOneQuery {
      image: file(relativePath: { eq: "independent-living-seniors-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <InfographicImage
      bg="primary"
      color="background"
      imageSide="right"
      image={data.image.childImageSharp.fluid}
      Icon={Icon}
      value="24 Hours"
    >
      24-hour on-site support at our accessible Jean Dudley House helps seniors
      maintain their independence and dignity
    </InfographicImage>
  )
}

export default One
