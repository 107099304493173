import { Button } from "theme-ui";
import { Link } from "gatsby";
import { IndependentLivingOne, IndependentLivingTwo, IndependentLivingThree, IndependentLivingFour } from "components/library/independent-living";
import Infographic from "components/infographic";
import IndependentLivingSeniors4 from "components/svgs/independent-living-seniors-icon-4";
import * as React from 'react';
export default {
  Button,
  Link,
  IndependentLivingOne,
  IndependentLivingTwo,
  IndependentLivingThree,
  IndependentLivingFour,
  Infographic,
  IndependentLivingSeniors4,
  React
};