/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { InfographicImage } from "components/infographic"
import Icon from "components/svgs/urban-health-icon-5"

const One = () => {
  const data = useStaticQuery(graphql`
    query UrbanHealthFourQuery {
      image: file(relativePath: { eq: "urban-health-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <InfographicImage
      bg="primary"
      color="white"
      imageSide="right"
      image={data.image.childImageSharp.fluid}
      Icon={Icon}
      value="$0.00 "
    >
      fees for SmartCash cheque cashing helps low-income people improve financial stability
    </InfographicImage>
  )
}

export default One