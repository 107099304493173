/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { InfographicImage } from "components/infographic"
import Icon from "components/svgs/community-development-icon-1"

const One = () => {
    const data = useStaticQuery(graphql`
    query CommunityDevelopmentThreeQuery {
      image: file(relativePath: { eq: "community-development-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <InfographicImage
            bg="accent"
            color="white"
            imageSide="right"
            image={data.image.childImageSharp.fluid}
            Icon={Icon}
            value="1,400"
        >
            people each year maintain healthy diets by receiving nutritious food from the Teesdale Food Bank
        </InfographicImage>
    )
}

export default One
