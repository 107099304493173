/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#292929"
      d="M70.513 94.095a34.011 34.011 0 0128.652-9.704 2.852 2.852 0 002.34-.754l20.392-19.261a11.354 11.354 0 017.838-3.151 2.596 2.596 0 002.53-3.156 2.604 2.604 0 00-.749-1.326L81.382 9.333a8.563 8.563 0 00-11.748 0L7.889 67.715a2.854 2.854 0 001.963 4.927H21.27a2.854 2.854 0 012.854 2.854v57.087a5.71 5.71 0 005.709 5.709h32.111a2.857 2.857 0 002.854-2.766 2.855 2.855 0 00-.325-1.413 34.178 34.178 0 016.04-40.018z"
    ></path>
    <path
      fill="#292929"
      d="M141.149 69.79h-11.418a2.85 2.85 0 00-1.958.776l-23.6 22.264a2.85 2.85 0 01-2.717.679 25.689 25.689 0 1018.074 18.154 2.862 2.862 0 01-.005-1.484c.13-.486.385-.929.741-1.285l22.898-22.835c.531-.53.833-1.248.839-1.998V72.644a2.856 2.856 0 00-2.854-2.855zm-38.483 54.71a9.986 9.986 0 01-10.887 2.166 9.996 9.996 0 01-6.167-9.23 9.99 9.99 0 0117.054-7.065 9.962 9.962 0 012.939 7.068 9.976 9.976 0 01-2.939 7.067v-.006z"
    ></path>
  </svg>
)

export default Icon
