/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="25"
    viewBox="0 0 31 25"
    fill="none"
    className={className}
  >
    <path
      fill="#51A0EC"
      d="M27.595 6.225c.02.274.02.547.02.82 0 8.333-6.343 17.935-17.935 17.935A17.812 17.812 0 010 22.15c.507.058.995.078 1.522.078 2.947 0 5.66-.995 7.826-2.693a6.315 6.315 0 01-5.894-4.372c.39.059.78.098 1.19.098a6.65 6.65 0 001.66-.215A6.304 6.304 0 011.248 8.86v-.078c.84.468 1.815.761 2.85.8a6.299 6.299 0 01-2.811-5.25c0-1.17.312-2.244.859-3.18a17.917 17.917 0 0012.997 6.596 7.12 7.12 0 01-.156-1.445A6.3 6.3 0 0121.29 0c1.815 0 3.454.761 4.606 1.99a12.41 12.41 0 004-1.522 6.286 6.286 0 01-2.77 3.474 12.637 12.637 0 003.63-.976 13.549 13.549 0 01-3.162 3.26z"
    ></path>
  </svg>
)

export default Icon
