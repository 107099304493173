/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="28"
    fill="none"
    className={className}
    viewBox="0 0 14 28"
  >
    <path
      fill="#5067A5"
      d="M4.067 28V15.477H0V10.5h4.067V6.579C4.067 2.319 6.592 0 10.277 0 12.043 0 13.56.137 14 .197v4.451h-2.556c-2.005 0-2.392.985-2.392 2.423V10.5h4.524l-.62 4.977H9.051V28"
    ></path>
  </svg>
)

export default Icon
