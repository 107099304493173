/** @jsx jsx */
import { jsx, Button } from "theme-ui"

import External from "components/svgs/external"

const ExternalButton = ({ href, children, sx, variant }, props) => (
  <Button
    variant={variant}
    href={href}
    as="a"
    target="_blank"
    rel="noreferrer noopener"
    {...props}
    sx={{ display: "inline-flex", alignItems: "center", ...sx }}
  >
    {children}
    <External sx={{ ml: 2, height: "0.8rem", width: "0.8rem" }} />
  </Button>
)

ExternalButton.defaultProps = {
  variant: "primary",
}

export default ExternalButton
