/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Box } from "theme-ui"
import PropTypes from "prop-types"

import Header from "./partials/header"
import Footer from "./partials/footer"
import Head from "./head"
import Container from "./grid/container"

const Layout = ({ children, title, description }) => (
  <Fragment>
    <Head title={title} description={description} />
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        variant: "layout.root",
      }}
    >
      <Header />
      <Box
        id="main"
        as="main"
        sx={{
          width: "100%",
          flex: "1 1 auto",
          variant: "layout.main",
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  </Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export { Container }
export { default as Row } from "./grid/row"
export { default as Col } from "./grid/col"

export default Layout
