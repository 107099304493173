/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="39"
    fill="none"
    viewBox="0 0 38 39"
    className={className}
  >
    <path
      fill="#E31B23"
      d="M29.148 23.96c-.19.122-.41.187-.634.189H9.5a1.194 1.194 0 01-.633-.188L.61 18.656a.396.396 0 00-.61.333v15.848a3.167 3.167 0 003.167 3.166h31.666A3.167 3.167 0 0038 34.837V18.992a.396.396 0 00-.61-.334l-8.242 5.303z"
    ></path>
    <path
      fill="#E31B23"
      d="M8.099 20.651a.395.395 0 00.61-.332V3.566a.396.396 0 01.395-.396h19.792a.396.396 0 01.396.396v16.743a.397.397 0 00.41.397.395.395 0 00.2-.063l6.432-4.129a.396.396 0 00.013-.659l-3.889-2.71V3.496A3.347 3.347 0 0029.292 0H8.708a3.349 3.349 0 00-3.166 3.498v9.647L1.65 15.852a.396.396 0 00.01.659l6.439 4.14z"
    ></path>
    <path
      fill="#E31B23"
      d="M17.801 7.92a1.187 1.187 0 00-1.187-1.188h-3.167a1.187 1.187 0 100 2.375h3.167A1.187 1.187 0 0017.8 7.92zm-5.531 5.542a1.187 1.187 0 001.188 1.187h11.084a1.188 1.188 0 000-2.375H13.458a1.187 1.187 0 00-1.187 1.188zm1.188 6.728h11.084a1.188 1.188 0 000-2.375H13.458a1.187 1.187 0 100 2.375z"
    ></path>
  </svg>
)

export default Icon
