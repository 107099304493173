/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { alpha } from "@theme-ui/color"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { Container } from "components/layout"
import { ExtraLarge, Medium } from "components/type"

const One = () => {
  const data = useStaticQuery(graphql`
    query VolunteersOnceQuery {
      image: file(relativePath: { eq: "services-programs-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage fluid={data.image.childImageSharp.fluid}>
      <Box sx={{ py: 5, bg: alpha("black", 0.7) }}>
        <Container>
          <Medium sx={{ color: "background" }}>
            You gave almost
            <ExtraLarge color="secondary" as="span">
              53,000
            </ExtraLarge>
            hours of your time! We could not do it without you.
          </Medium>
          <Medium sx={{ color: "background" }}>
            On behalf of our staff and the people we serve, thank you for your
            generosity!
          </Medium>
        </Container>
      </Box>
    </BackgroundImage>
  )
}

export default One
