/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    className={className}
  >
    <path
      fill="#E31B23"
      d="M14.193 7.994l-.432 4.915c-.11 1.272-2.14 1.097-2.029-.153l.335-3.828L10.691 9a4.6 4.6 0 01.529 2.14c0 1.288-.54 2.46-1.416 3.329L8.488 13.24c1.939-1.991.438-5.225-2.478-5.225-.867 0-1.657.309-2.25.812L2.444 7.6A5.344 5.344 0 015.17 6.344l2.52-2.678-1.425-.776-1.728 1.438c-1.004.838-2.364-.578-1.356-1.419l2.277-1.896A1.072 1.072 0 016.646.9s4.664 2.528 4.671 2.534c.543.316.693 1.125.204 1.644L9.566 7.156l3.552-.184c.62-.035 1.125.45 1.075 1.022zM12.02 3.18c.941 0 1.705-.712 1.705-1.59C13.725.713 12.96 0 12.02 0c-.94 0-1.704.713-1.704 1.59 0 .879.763 1.591 1.704 1.591zM6.014 14.266c-2.7 0-4.266-2.832-2.77-4.879l-1.329-1.24a4.61 4.61 0 00-1.111 2.99c0 4.085 5.045 6.294 8.417 3.829l-1.33-1.241c-.535.34-1.181.54-1.877.54z"
    ></path>
  </svg>
)

export default Icon
