export default {
  root: {
    fontFamily: "body",
    lineHeight: "body",
    fontWeight: "body",
    "* > ::selection": {
      bg: "secondary",
      color: "dark",
    },
    "a > .gatsby-resp-image-wrapper": {
      ml: '0 !important',
    },
  },
  h1: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "heading",
    mt: [1, 0, 0],
    mb: 0,
    fontSize: [5, 6, 7],
  },
  h2: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    mt: 4,
    mb: 2,
    fontWeight: "heading",
    fontSize: [4, 5, 6],
  },
  h3: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    mt: 3,
    mb: 1,
    fontWeight: "body",
    fontSize: [3, 4, 5],
  },
  h4: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "body",
    mt: 3,
    mb: 1,
    fontSize: [2, 3, 4],
  },
  h5: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "body",
    mt: 2,
    mb: 0,
    fontSize: [1, 2, 3],
  },
  h6: {
    color: "dark",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "body",
    mt: 2,
    mb: 1,
    fontSize: [0, 1, 2],
  },
  p: {
    fontFamily: "body",
    fontWeight: "body",
    fontSize: [3, 3, 3],
    lineHeight: "body",
    mb: 3,
    mt: 0,
  },
  a: {
    color: "primary",
  },
  pre: {
    fontFamily: "monospace",
    overflowX: "auto",
    code: {
      color: "inherit",
    },
  },
  blockquote: {
    border: "thin solid",
    borderBottomColor: "primary",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderTopColor: "primary",
    color: "primary",
    fontSize: [3, 4],
    mx: 0,
    py: 4,
    pr: 3,
    pl: 5,
    position: "relative",
    "&::before": {
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='48' height='33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.807 0A19.695 19.695 0 0 0 .682 20.18v2.522a10.025 10.025 0 1 0 10.023-10.023 9.345 9.345 0 0 0-2.262.284.508.508 0 0 1-.562-.747 13.859 13.859 0 0 1 11.926-7.145 2.536 2.536 0 1 0 0-5.071zM44.804 5.071a2.536 2.536 0 0 0 0-5.071A19.697 19.697 0 0 0 25.68 20.18v2.522a10.026 10.026 0 1 0 10.025-10.023 9.352 9.352 0 0 0-2.264.284.506.506 0 0 1-.562-.747 13.859 13.859 0 0 1 11.926-7.145z' fill='%23E31B23'/%3E%3C/svg%3E")`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      content: '""',
      display: "block",
      left: 1,
      top: "50%",
      transform: "translateY(-50%)",
      position: "absolute",
      height: "2.5rem",
      width: "2.5rem",
    },
  },
  code: {
    fontFamily: "monospace",
    fontSize: "inherit",
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: 0,
  },
  th: {
    textAlign: "left",
    borderBottomStyle: "solid",
  },
  td: {
    textAlign: "left",
    borderBottomStyle: "solid",
  },
  img: {
    maxWidth: "100%",
  },
}
