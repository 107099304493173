/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M24.872 98.939c-9.266 0-16.783 7.517-16.783 16.783 0 4.278 1.62 8.175 4.261 11.137l.277.303c.243.259.485.511.745.753l20.697 20.871v-16.281h58.049c9.266 0 16.783-7.517 16.783-16.783 0-9.266-7.517-16.783-16.783-16.783H24.873zm22.371 19.649a3.623 3.623 0 10.001-7.247 3.623 3.623 0 000 7.247zm18.119-3.623a3.623 3.623 0 11-7.247 0 3.623 3.623 0 017.247 0zm10.871 3.623a3.623 3.623 0 100-7.247 3.623 3.623 0 000 7.247z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M129.217 57.12c9.266 0 16.783 7.516 16.783 16.782 0 4.278-1.619 8.175-4.261 11.137l-.277.303c-.242.26-.485.511-.745.754l-20.697 20.87v-16.28H61.972c-9.267 0-16.784-7.517-16.784-16.784 0-9.266 7.517-16.783 16.784-16.783h67.245zm-51.776 20.4a3.624 3.624 0 100-7.248 3.624 3.624 0 000 7.248zm18.118-3.624a3.624 3.624 0 11-7.247 0 3.624 3.624 0 017.247 0zm10.872 3.624a3.623 3.623 0 100-7.247 3.623 3.623 0 000 7.247zM29.73 16.495h58.048c9.275 0 16.784 7.517 16.784 16.783 0 9.266-7.517 16.783-16.784 16.783H20.542c-9.266 0-16.783-7.517-16.783-16.783a16.71 16.71 0 015.1-12.037L29.73.214v16.281zm11.474 19.956a3.624 3.624 0 100-7.247 3.624 3.624 0 000 7.247zm18.118-3.623a3.624 3.624 0 11-7.247 0 3.624 3.624 0 017.247 0zm10.871 3.623a3.624 3.624 0 100-7.247 3.624 3.624 0 000 7.247z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default Icon
