/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import Img from "gatsby-image"

const LogoInfo = ({ image, As, children, heading, color }) => (
  <Flex sx={{ alignItems: "center", my: 4, flexDirection: ["column", "row"] }}>
    <Box
      sx={{
        mb: [4, 0],
        mr: [0, 4],
      }}
    >
      <Img fixed={image} alt="" />
    </Box>
    <Box
      sx={{
        flex: "1",
        borderTop: ["0.375rem solid", "none"],
        borderLeft: ["none", "0.375rem solid"],
        borderColor: color,
        pl: [0, 4],
        pt: [4, 0],
      }}
    >
      <As sx={{ color, mt: 0 }}>{heading}</As>
      {children}
    </Box>
  </Flex>
)

export default LogoInfo
