/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import ImageOverlap from "components/image-overlap"

const One = () => {
  const data = useStaticQuery(graphql`
    query CommunityDevelopmentOneQuery {
      image: file(relativePath: { eq: "community-development-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ImageOverlap
      bg="primary"
      color="white"
      imageSide="left"
      image={data.image.childImageSharp.fluid}
    >
      Harriet lived alone for 38 years and was grateful to find the Food Bank located right across the street from her apartment. “Walking to the grocery store is really challenging and the prices are so expensive on my limited budget. The staff and volunteers at Teesdale treat me with respect and I get everything I need…milk, eggs, bread, vegetables and other Halal food. The Food Bank meets the needs of the diverse group of people in this community.”

    </ImageOverlap>
  )
}

export default One