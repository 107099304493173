/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import BackgroundImage from "gatsby-background-image"

import { Container, Row, Col } from "components/layout"
import { Medium, ExtraLarge } from "components/type"
import Panel from "components/panel"

const WithImage = ({
  bg,
  color,
  Icon,
  value,
  image,
  children,
  imageSide,
  iconSize,
}) => (
  <Panel bg={bg} color={color} nospace>
    <Container>
      <Row sx={{ alignItems: "stretch", flexDirection: ["column", "row"] }}>
        <Col
          sx={{
            width: ["100%", "50%"],
            py: 4,
            order: imageSide === "left" ? 2 : 1,
          }}
        >
          <ExtraLarge color={color}>
            <Flex
              sx={{
                alignItems: "center",
                pr: [0, 4],
              }}
            >
              {Icon ? (
                <Icon sx={{ mr: 3, width: iconSize, height: "auto" }} />
              ) : null}
              {value}
            </Flex>
          </ExtraLarge>
          <Medium>{children}</Medium>
        </Col>
        <Col
          sx={{
            width: ["100%", "50%"],
            display: ["none", "flex"],
            order: imageSide === "left" ? 1 : 2,
          }}
        >
          <BackgroundImage
            fluid={image}
            backgroundColor="#fff"
            sx={{
              height: "100%",
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "100%",
            }}
          />
        </Col>
      </Row>
    </Container>
  </Panel>
)

WithImage.defaultProps = {
  bg: "primary",
  color: "background",
  imageSide: "right",
  iconSize: "100px",
}

export default WithImage
