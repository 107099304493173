/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
  >
    <path
      fill="#292929"
      d="M139.227 44.005L126.891 56.34a3.214 3.214 0 01-4.549 0L92.639 26.638a3.214 3.214 0 010-4.55l12.336-12.335c5.004-5.004 13.139-5.004 18.17 0l16.082 16.082c5.03 5.004 5.03 13.139 0 18.17zm-57.184-11.32l-70.27 70.27-5.672 32.512c-.776 4.388 3.05 8.188 7.439 7.439l32.512-5.7 70.269-70.27a3.213 3.213 0 000-4.548L86.618 32.685c-1.284-1.257-3.318-1.257-4.575 0zM39.202 96.934a3.73 3.73 0 010-5.299L80.41 50.427a3.731 3.731 0 015.299 0 3.731 3.731 0 010 5.298l-41.21 41.209a3.73 3.73 0 01-5.297 0zm-9.66 22.504h12.844v9.714l-17.26 3.023-8.322-8.322 3.024-17.259h9.714v12.844z"
    ></path>
  </svg>
)

export default Icon
