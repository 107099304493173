/** @jsx jsx */
import { jsx, Flex } from "theme-ui"

import { Container, Row, Col } from "components/layout"
import { Medium, ExtraLarge } from "components/type"
import Panel from "components/panel"

const Reverse = ({ bg, color, Icon, children }) => (
  <Panel bg={bg} color={color}>
    <Container>
      <Row sx={{ alignItems: "center", flexDirection: ["column", "row"] }}>
        <Col sx={{ width: ["100%", "50%"] }}>
          <Flex
            sx={{
              alignItems: "center",
              borderRight: ["none", "medium solid white"],
              pr: [0, 4],
            }}
          >
            <Medium>{children}</Medium>
          </Flex>
        </Col>
        <Col>
          <ExtraLarge color={color}>
            {Icon ? (
              <Icon sx={{ mr: 3, width: "100%", height: "auto" }} />
            ) : null}
          </ExtraLarge>
        </Col>
      </Row>
    </Container>
  </Panel>
)

Reverse.defaultProps = {
  bg: "primary",
  color: "background",
}

export default Reverse
