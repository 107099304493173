/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import ImageOverlap from "components/image-overlap"

const One = () => {
    const data = useStaticQuery(graphql`
      query UrbanHealthThreeQuery {
        image: file(relativePath: { eq: "urban-health-3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 445) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

    return (
        <ImageOverlap
            bg="secondary"
            color="dark"
            image={data.image.childImageSharp.fluid}
            imageSide="left">
            Touhida Choudhury, Easy Access Voice Mail Project Coordinator, helps people stay in touch with friends, family, doctors, case workers and employers.
        </ImageOverlap>
    )
}

export default One