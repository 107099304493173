import leadershipReport from "pdf/leadership-report.pdf"

export const menuItems = [
  {
    label: "Home",
    to: "/",
    subItems: [],
  },
  {
    label: "Leadership Report",
    to: leadershipReport,
    external: true,
    subItems: [],
  },
  {
    label: "Board of Directors",
    to: "/board-directors",
    subItems: [],
  },
  {
    label: "Our Merger",
    to: "/our-merger",
    subItems: [],
  },
  {
    label: "Services & Programs",
    subItems: [
      {
        label: "Overview",
        to: "/services-programs",
      },
      {
        label: "Childcare",
        to: "/childcare",
      },
      {
        label: "Children & Youth Services",
        to: "/children-youth-services ",
      },
      {
        label: "Community Development",
        to: "/community-development",
      },
      {
        label: "Conflict Resolution & Training",
        to: "/conflict-resolution-training",
      },
      {
        label: "Employment Services",
        to: "/employment-services",
      },
      {
        label: "Independent Living & Seniors",
        to: "/independent-living-seniors",
      },
      {
        label: "Newcomer Services",
        to: "/newcomer-services",
      },
      {
        label: "Trustee Services",
        to: "/trustee-services",
      },
      {
        label: "Urban Health",
        to: "/urban-health",
      },
      {
        label: "Women’s & Family Services",
        to: "/womens-family-services",
      },
    ],
  },
  {
    label: "Volunteers & Donors",
    subItems: [
      {
        label: "Volunteers",
        to: "/volunteers",
      },
      {
        label: "Our Donors",
        to: "/donors",
      },
    ],
  },
  {
    label: "Financials",
    to: "/financials",
    subItems: [],
  },
]

export const styles = {
  menuIcon: {
    height: "auto",
    ml: 2,
    width: "1rem",
  },
  menuButton: {
    background: "none",
    color: "dark",
    border: "none",
    borderRadius: "default",
    fontSize: [3, 4, 5],
    fontWeight: 700,
    mb: 2,
    py: 2,
    px: 3,
    "&:hover": {
      bg: "dark",
      color: "background",
      cursor: "pointer",
    },
  },
}
