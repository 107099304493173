/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="953"
    height="267"
    viewBox="0 0 953 267"
    fill="none"
    sx={{ width: "100%", height: "auto" }}
    className={className}
  >
    <circle cx="154.5" cy="124.5" r="29.5" fill="#fff"></circle>
    <circle cx="232.5" cy="53.5" r="29.5" fill="#fff"></circle>
    <circle cx="230.5" cy="124.5" r="29.5" fill="#fff"></circle>
    <circle cx="229.5" cy="190.5" r="29.5" fill="#fff"></circle>
    <circle cx="313.5" cy="229.5" r="29.5" fill="#fff"></circle>
    <circle cx="308.5" cy="125.5" r="29.5" fill="#fff"></circle>
    <circle cx="356.5" cy="53.5" r="29.5" fill="#fff"></circle>
    <circle cx="362.5" cy="172.5" r="29.5" fill="#fff"></circle>
    <circle cx="416.5" cy="93.5" r="29.5" fill="#fff"></circle>
    <circle cx="494.5" cy="117.5" r="29.5" fill="#fff"></circle>
    <circle cx="513.5" cy="53.5" r="29.5" fill="#fff"></circle>
    <circle cx="475.5" cy="180.5" r="29.5" fill="#fff"></circle>
    <circle cx="439.5" cy="237.5" r="29.5" fill="#fff"></circle>
    <circle cx="609.5" cy="226.5" r="29.5" fill="#fff"></circle>
    <circle cx="560.5" cy="173.5" r="29.5" fill="#fff"></circle>
    <circle cx="578.5" cy="105.5" r="29.5" fill="#fff"></circle>
    <circle cx="650.5" cy="127.5" r="29.5" fill="#fff"></circle>
    <circle cx="706.5" cy="75.5" r="29.5" fill="#fff"></circle>
    <circle cx="707.5" cy="180.5" r="29.5" fill="#fff"></circle>
    <circle cx="773.5" cy="211.5" r="29.5" fill="#fff"></circle>
    <circle cx="775.5" cy="131.5" r="29.5" fill="#fff"></circle>
    <circle cx="842.5" cy="77.5" r="29.5" fill="#fff"></circle>
    <circle cx="846.5" cy="181.5" r="29.5" fill="#fff"></circle>
    <circle cx="923.5" cy="199.5" r="29.5" fill="#fff"></circle>
    <circle cx="923.5" cy="112.5" r="29.5" fill="#fff"></circle>
    <circle cx="614.5" cy="29.5" r="29.5" fill="#fff"></circle>
    <path
      fill="#E31B23"
      d="M145.22 108c-2.351 0-4.254 1.922-4.254 4.295 0 2.374 1.903 4.295 4.254 4.295 2.352 0 4.255-1.921 4.255-4.295 0-2.373-1.903-4.295-4.255-4.295z"
    ></path>
    <path
      fill="#E31B23"
      d="M140.84 121.337l.012 3.35-.752 17.409c.125 1.103.883 1.91 2.047 1.91 1.146 0 1.909-.753 2.065-1.855l.596-10.036.71 10.036c.156 1.096.919 1.855 2.065 1.855 1.164 0 1.916-.801 2.047-1.91l-.818-18.987c2.757 1.656 3.969 5.204 3.969 5.204.226.567.877.952 1.718.952s1.492-.385 1.719-.952c0 0 1.211-3.548 3.968-5.204l-.818 18.987c.126 1.103.884 1.91 2.047 1.91 1.146 0 1.91-.753 2.065-1.855l.71-10.036.597 10.036c.155 1.096.919 1.855 2.064 1.855 1.164 0 1.916-.801 2.047-1.91l-.752-17.409.012-3.35c2.011-1.271 5.395-2.891 7.602-8.283.424-.976.281-2.223-.423-2.59-.895-.47-1.802-.325-2.429.711-1.259 2.066-3.449 4.44-5.824 5.464-3.312 1.427-4.41.68-6.809 1.5-2.601.891-4.016 2.638-5.782 5.867-1.766-3.235-3.18-4.976-5.782-5.867-2.399-.826-3.497-.079-6.809-1.5-2.375-1.024-4.565-3.392-5.824-5.464-.62-1.036-1.521-1.181-2.417-.711-.704.367-.847 1.614-.423 2.59 2.208 5.386 5.585 7.012 7.602 8.283z"
    ></path>
    <path
      fill="#E31B23"
      d="M163.778 108c-2.351 0-4.255 1.922-4.255 4.295 0 2.374 1.904 4.295 4.255 4.295s4.254-1.921 4.254-4.295c-.006-2.373-1.909-4.295-4.254-4.295z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M551.22 157c-2.351 0-4.254 1.922-4.254 4.295 0 2.374 1.903 4.295 4.254 4.295 2.352 0 4.255-1.921 4.255-4.295 0-2.373-1.903-4.295-4.255-4.295z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M546.84 170.337l.012 3.35-.752 17.409c.125 1.103.883 1.91 2.047 1.91 1.146 0 1.909-.753 2.065-1.855l.596-10.036.71 10.036c.156 1.096.919 1.855 2.065 1.855 1.164 0 1.916-.801 2.047-1.91l-.818-18.987c2.757 1.656 3.969 5.204 3.969 5.204.226.567.877.952 1.718.952s1.492-.385 1.719-.952c0 0 1.211-3.548 3.968-5.204l-.818 18.987c.126 1.103.884 1.91 2.047 1.91 1.146 0 1.91-.753 2.065-1.855l.71-10.036.597 10.036c.155 1.096.919 1.855 2.064 1.855 1.164 0 1.916-.801 2.047-1.91l-.752-17.409.012-3.35c2.011-1.271 5.395-2.891 7.602-8.283.424-.976.281-2.223-.423-2.59-.895-.47-1.802-.325-2.429.711-1.259 2.066-3.449 4.44-5.824 5.464-3.312 1.427-4.41.68-6.809 1.5-2.601.891-4.016 2.638-5.782 5.867-1.766-3.235-3.18-4.976-5.782-5.867-2.399-.826-3.497-.079-6.809-1.5-2.375-1.024-4.565-3.392-5.824-5.464-.62-1.036-1.521-1.181-2.417-.711-.704.367-.847 1.614-.423 2.59 2.208 5.386 5.585 7.012 7.602 8.283z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M569.778 157c-2.351 0-4.255 1.922-4.255 4.295 0 2.374 1.904 4.295 4.255 4.295s4.254-1.921 4.254-4.295c-.006-2.373-1.909-4.295-4.254-4.295z"
    ></path>
    <path
      fill="#FBB034"
      d="M353.22 155c-2.351 0-4.254 1.922-4.254 4.295 0 2.374 1.903 4.295 4.254 4.295 2.352 0 4.255-1.921 4.255-4.295 0-2.373-1.903-4.295-4.255-4.295z"
    ></path>
    <path
      fill="#FBB034"
      d="M348.84 168.337l.012 3.35-.752 17.409c.125 1.103.883 1.91 2.047 1.91 1.146 0 1.909-.753 2.065-1.855l.596-10.036.71 10.036c.156 1.096.919 1.855 2.065 1.855 1.164 0 1.916-.801 2.047-1.91l-.818-18.987c2.757 1.656 3.969 5.204 3.969 5.204.226.567.877.952 1.718.952s1.492-.385 1.719-.952c0 0 1.211-3.548 3.968-5.204l-.818 18.987c.126 1.103.884 1.91 2.047 1.91 1.146 0 1.91-.753 2.065-1.855l.71-10.036.597 10.036c.155 1.096.919 1.855 2.064 1.855 1.164 0 1.916-.801 2.047-1.91l-.752-17.409.012-3.35c2.011-1.271 5.395-2.891 7.602-8.283.424-.976.281-2.223-.423-2.59-.895-.47-1.802-.325-2.429.711-1.259 2.066-3.449 4.44-5.824 5.464-3.312 1.427-4.41.68-6.809 1.5-2.601.891-4.016 2.638-5.782 5.867-1.766-3.235-3.18-4.976-5.782-5.867-2.399-.826-3.497-.079-6.809-1.5-2.375-1.024-4.565-3.392-5.824-5.464-.62-1.036-1.521-1.181-2.417-.711-.704.367-.847 1.614-.423 2.59 2.208 5.386 5.585 7.012 7.602 8.283z"
    ></path>
    <path
      fill="#FBB034"
      d="M371.778 155c-2.351 0-4.255 1.922-4.255 4.295 0 2.374 1.904 4.295 4.255 4.295s4.254-1.921 4.254-4.295c-.006-2.373-1.909-4.295-4.254-4.295z"
    ></path>
    <path
      fill="#E31B23"
      d="M465.936 165c-2.296 0-4.155 1.815-4.155 4.057 0 2.241 1.859 4.056 4.155 4.056 2.297 0 4.156-1.815 4.156-4.056 0-2.242-1.859-4.057-4.156-4.057z"
    ></path>
    <path
      fill="#E31B23"
      d="M461.658 177.596l.011 3.164-.734 16.442c.122 1.041.863 1.804 1.999 1.804 1.119 0 1.865-.712 2.017-1.753l.583-9.478.693 9.478c.152 1.036.898 1.753 2.017 1.753 1.136 0 1.871-.757 1.999-1.804l-.798-17.933c2.692 1.565 3.876 4.916 3.876 4.916.221.535.856.899 1.678.899s1.457-.364 1.679-.899c0 0 1.183-3.351 3.876-4.916l-.799 17.933c.123 1.041.863 1.804 1.999 1.804 1.119 0 1.865-.712 2.017-1.753l.694-9.478.582 9.478c.152 1.036.898 1.753 2.017 1.753 1.137 0 1.871-.757 1.999-1.804l-.734-16.442.012-3.164c1.964-1.2 5.268-2.731 7.425-7.823.414-.921.274-2.099-.414-2.446-.874-.444-1.76-.307-2.372.671-1.23 1.952-3.369 4.193-5.689 5.161-3.234 1.348-4.307.643-6.65 1.416-2.541.842-3.922 2.492-5.648 5.542-1.725-3.055-3.106-4.7-5.647-5.542-2.344-.779-3.416-.074-6.651-1.416-2.32-.968-4.459-3.204-5.688-5.161-.607-.978-1.487-1.115-2.361-.671-.688.347-.828 1.525-.414 2.446 2.157 5.087 5.456 6.623 7.426 7.823z"
    ></path>
    <path
      fill="#E31B23"
      d="M484.061 165c-2.296 0-4.155 1.815-4.155 4.057 0 2.241 1.859 4.056 4.155 4.056 2.297 0 4.156-1.815 4.156-4.056-.006-2.242-1.865-4.057-4.156-4.057z"
    ></path>
    <circle cx="29.5" cy="125.5" r="29.5" fill="#fff"></circle>
    <circle cx="91.5" cy="81.5" r="29.5" fill="#fff"></circle>
    <circle cx="90.5" cy="180.5" r="29.5" fill="#fff"></circle>
    <path
      fill="#7A68B0"
      d="M347.936 37c-2.296 0-4.155 1.815-4.155 4.057 0 2.241 1.859 4.056 4.155 4.056 2.297 0 4.156-1.815 4.156-4.056 0-2.242-1.859-4.057-4.156-4.057z"
    ></path>
    <path
      fill="#7A68B0"
      d="M343.658 49.596l.011 3.164-.734 16.442c.122 1.041.863 1.804 1.999 1.804 1.119 0 1.865-.712 2.017-1.753l.583-9.478.693 9.478c.152 1.036.898 1.753 2.017 1.753 1.136 0 1.871-.757 1.999-1.804l-.798-17.933c2.692 1.565 3.876 4.916 3.876 4.916.221.534.856.899 1.678.899s1.457-.365 1.679-.9c0 0 1.183-3.35 3.876-4.915l-.799 17.933c.123 1.041.863 1.804 1.999 1.804 1.119 0 1.865-.712 2.017-1.753l.694-9.478.582 9.478c.152 1.036.898 1.753 2.017 1.753 1.137 0 1.871-.757 1.999-1.804l-.734-16.442.012-3.164c1.964-1.2 5.268-2.73 7.425-7.823.414-.921.274-2.099-.414-2.446-.874-.444-1.76-.307-2.372.671-1.23 1.952-3.369 4.193-5.689 5.16-3.234 1.349-4.307.643-6.65 1.417-2.541.842-3.922 2.492-5.648 5.542-1.725-3.055-3.106-4.7-5.647-5.542-2.344-.78-3.416-.074-6.651-1.416-2.32-.968-4.459-3.203-5.688-5.16-.607-.98-1.487-1.116-2.361-.672-.688.347-.828 1.525-.414 2.446 2.157 5.087 5.456 6.623 7.426 7.823z"
    ></path>
    <path
      fill="#7A68B0"
      d="M366.061 37c-2.296 0-4.155 1.815-4.155 4.057 0 2.241 1.859 4.056 4.155 4.056 2.297 0 4.156-1.815 4.156-4.056-.006-2.242-1.865-4.057-4.156-4.057z"
    ></path>
    <path
      fill="#FBB034"
      d="M486.22 101c-2.351 0-4.254 1.922-4.254 4.295 0 2.374 1.903 4.295 4.254 4.295 2.352 0 4.255-1.921 4.255-4.295 0-2.373-1.903-4.295-4.255-4.295z"
    ></path>
    <path
      fill="#FBB034"
      d="M481.84 114.337l.012 3.35-.752 17.409c.125 1.103.883 1.91 2.047 1.91 1.146 0 1.909-.753 2.065-1.855l.596-10.036.71 10.036c.156 1.096.919 1.855 2.065 1.855 1.164 0 1.916-.801 2.047-1.91l-.818-18.987c2.757 1.656 3.969 5.204 3.969 5.204.226.567.877.952 1.718.952s1.492-.385 1.719-.952c0 0 1.211-3.548 3.968-5.204l-.818 18.987c.126 1.103.884 1.91 2.047 1.91 1.146 0 1.91-.753 2.065-1.855l.71-10.036.597 10.036c.155 1.096.919 1.855 2.064 1.855 1.164 0 1.916-.801 2.047-1.91l-.752-17.409.012-3.35c2.011-1.271 5.395-2.891 7.602-8.283.424-.976.281-2.223-.423-2.59-.895-.47-1.802-.325-2.429.711-1.259 2.066-3.449 4.44-5.824 5.464-3.312 1.427-4.41.68-6.809 1.5-2.601.891-4.016 2.638-5.782 5.867-1.766-3.235-3.18-4.976-5.782-5.867-2.399-.826-3.497-.079-6.809-1.5-2.375-1.024-4.565-3.392-5.824-5.464-.62-1.036-1.521-1.181-2.417-.711-.704.367-.847 1.614-.423 2.59 2.208 5.386 5.585 7.012 7.602 8.283z"
    ></path>
    <path
      fill="#FBB034"
      d="M504.778 101c-2.351 0-4.255 1.922-4.255 4.295 0 2.374 1.904 4.295 4.255 4.295s4.254-1.921 4.254-4.295c-.006-2.373-1.909-4.295-4.254-4.295zM224.22 37c-2.351 0-4.254 1.922-4.254 4.295 0 2.374 1.903 4.295 4.254 4.295 2.352 0 4.255-1.921 4.255-4.295 0-2.373-1.903-4.295-4.255-4.295z"
    ></path>
    <path
      fill="#FBB034"
      d="M219.84 50.337l.012 3.35-.752 17.41c.125 1.102.883 1.91 2.047 1.91 1.146 0 1.909-.754 2.065-1.856l.596-10.036.71 10.036c.156 1.096.919 1.855 2.065 1.855 1.164 0 1.916-.801 2.047-1.91l-.818-18.988c2.757 1.657 3.969 5.205 3.969 5.205.226.567.877.952 1.718.952s1.492-.385 1.719-.952c0 0 1.211-3.548 3.968-5.205l-.818 18.989c.126 1.102.884 1.91 2.047 1.91 1.146 0 1.91-.754 2.065-1.856l.71-10.036.597 10.036c.155 1.096.919 1.855 2.064 1.855 1.164 0 1.916-.801 2.047-1.91l-.752-17.41.012-3.349c2.011-1.27 5.395-2.891 7.602-8.283.424-.976.281-2.223-.423-2.59-.895-.47-1.802-.325-2.429.71-1.259 2.067-3.449 4.44-5.824 5.465-3.312 1.427-4.41.68-6.809 1.5-2.601.891-4.016 2.638-5.782 5.867-1.766-3.235-3.18-4.976-5.782-5.867-2.399-.826-3.497-.079-6.809-1.5-2.375-1.024-4.565-3.392-5.824-5.464-.62-1.036-1.521-1.18-2.417-.711-.704.367-.847 1.614-.423 2.59 2.208 5.386 5.585 7.012 7.602 8.283z"
    ></path>
    <path
      fill="#FBB034"
      d="M242.778 37c-2.351 0-4.255 1.922-4.255 4.295 0 2.374 1.904 4.295 4.255 4.295s4.254-1.921 4.254-4.295c-.006-2.373-1.909-4.295-4.254-4.295zM837.22 164c-2.351 0-4.254 1.922-4.254 4.295 0 2.374 1.903 4.295 4.254 4.295 2.352 0 4.255-1.921 4.255-4.295 0-2.373-1.903-4.295-4.255-4.295z"
    ></path>
    <path
      fill="#FBB034"
      d="M832.84 177.337l.012 3.35-.752 17.409c.125 1.103.883 1.91 2.047 1.91 1.146 0 1.909-.753 2.065-1.855l.596-10.036.71 10.036c.156 1.096.919 1.855 2.065 1.855 1.164 0 1.916-.801 2.047-1.91l-.818-18.987c2.757 1.656 3.969 5.204 3.969 5.204.226.567.877.952 1.718.952s1.492-.385 1.719-.952c0 0 1.211-3.548 3.968-5.204l-.818 18.987c.126 1.103.884 1.91 2.047 1.91 1.146 0 1.91-.753 2.065-1.855l.71-10.036.597 10.036c.155 1.096.919 1.855 2.064 1.855 1.164 0 1.916-.801 2.047-1.91l-.752-17.409.012-3.35c2.011-1.271 5.395-2.891 7.602-8.283.424-.976.281-2.223-.423-2.59-.895-.47-1.802-.325-2.429.711-1.259 2.066-3.449 4.44-5.824 5.464-3.312 1.427-4.41.68-6.809 1.5-2.601.891-4.016 2.638-5.782 5.867-1.766-3.235-3.18-4.976-5.782-5.867-2.399-.826-3.497-.079-6.809-1.5-2.375-1.024-4.565-3.392-5.824-5.464-.62-1.036-1.521-1.181-2.417-.711-.704.367-.847 1.614-.423 2.59 2.208 5.386 5.585 7.012 7.602 8.283z"
    ></path>
    <path
      fill="#FBB034"
      d="M855.778 164c-2.351 0-4.255 1.922-4.255 4.295 0 2.374 1.904 4.295 4.255 4.295s4.254-1.921 4.254-4.295c-.006-2.373-1.909-4.295-4.254-4.295z"
    ></path>
    <path
      fill="#E31B23"
      d="M641.22 111c-2.351 0-4.254 1.922-4.254 4.295 0 2.374 1.903 4.295 4.254 4.295 2.352 0 4.255-1.921 4.255-4.295 0-2.373-1.903-4.295-4.255-4.295z"
    ></path>
    <path
      fill="#E31B23"
      d="M636.84 124.337l.012 3.35-.752 17.409c.125 1.103.883 1.91 2.047 1.91 1.146 0 1.909-.753 2.065-1.855l.596-10.036.71 10.036c.156 1.096.919 1.855 2.065 1.855 1.164 0 1.916-.801 2.047-1.91l-.818-18.987c2.757 1.656 3.969 5.204 3.969 5.204.226.567.877.952 1.718.952s1.492-.385 1.719-.952c0 0 1.211-3.548 3.968-5.204l-.818 18.987c.126 1.103.884 1.91 2.047 1.91 1.146 0 1.91-.753 2.065-1.855l.71-10.036.597 10.036c.155 1.096.919 1.855 2.064 1.855 1.164 0 1.916-.801 2.047-1.91l-.752-17.409.012-3.35c2.011-1.271 5.395-2.891 7.602-8.283.424-.976.281-2.223-.423-2.59-.895-.47-1.802-.325-2.429.711-1.259 2.066-3.449 4.44-5.824 5.464-3.312 1.427-4.41.68-6.809 1.5-2.601.891-4.016 2.638-5.782 5.867-1.766-3.235-3.18-4.976-5.782-5.867-2.399-.826-3.497-.079-6.809-1.5-2.375-1.024-4.565-3.392-5.824-5.464-.62-1.036-1.521-1.181-2.417-.711-.704.367-.847 1.614-.423 2.59 2.208 5.386 5.585 7.012 7.602 8.283z"
    ></path>
    <path
      fill="#E31B23"
      d="M659.778 111c-2.351 0-4.255 1.922-4.255 4.295 0 2.374 1.904 4.295 4.255 4.295s4.254-1.921 4.254-4.295c-.006-2.373-1.909-4.295-4.254-4.295z"
    ></path>
    <path
      fill="#FBB034"
      d="M698.22 59c-2.351 0-4.254 1.922-4.254 4.295 0 2.374 1.903 4.295 4.254 4.295 2.352 0 4.255-1.921 4.255-4.295 0-2.373-1.903-4.295-4.255-4.295z"
    ></path>
    <path
      fill="#FBB034"
      d="M693.84 72.338l.012 3.349-.752 17.41c.125 1.102.883 1.91 2.047 1.91 1.146 0 1.909-.754 2.065-1.856l.596-10.036.71 10.036c.156 1.096.919 1.855 2.065 1.855 1.164 0 1.916-.801 2.047-1.91l-.818-18.987c2.757 1.656 3.969 5.204 3.969 5.204.226.567.877.952 1.718.952s1.492-.385 1.719-.952c0 0 1.211-3.548 3.968-5.204l-.818 18.988c.126 1.102.884 1.91 2.047 1.91 1.146 0 1.91-.754 2.065-1.856l.71-10.036.597 10.036c.155 1.096.919 1.855 2.064 1.855 1.164 0 1.916-.801 2.047-1.91l-.752-17.41.012-3.349c2.011-1.27 5.395-2.891 7.602-8.283.424-.976.281-2.223-.423-2.59-.895-.47-1.802-.325-2.429.71-1.259 2.067-3.449 4.44-5.824 5.465-3.312 1.427-4.41.68-6.809 1.5-2.601.891-4.016 2.638-5.782 5.867-1.766-3.235-3.18-4.976-5.782-5.867-2.399-.826-3.497-.079-6.809-1.5-2.375-1.024-4.565-3.392-5.824-5.464-.62-1.036-1.521-1.18-2.417-.711-.704.367-.847 1.614-.423 2.59 2.208 5.386 5.585 7.012 7.602 8.284z"
    ></path>
    <path
      fill="#FBB034"
      d="M716.778 59c-2.351 0-4.255 1.922-4.255 4.295 0 2.374 1.904 4.295 4.255 4.295s4.254-1.921 4.254-4.295c-.006-2.373-1.909-4.295-4.254-4.295zM20.22 109c-2.35 0-4.254 1.868-4.254 4.176 0 2.307 1.903 4.176 4.255 4.176 2.35 0 4.254-1.869 4.254-4.176 0-2.308-1.903-4.176-4.255-4.176z"
    ></path>
    <path
      fill="#FBB034"
      d="M15.84 121.967l.012 3.256-.752 16.926c.125 1.072.883 1.857 2.047 1.857 1.146 0 1.91-.732 2.065-1.804l.596-9.757.71 9.757c.155 1.066.92 1.804 2.065 1.804 1.164 0 1.916-.779 2.047-1.857l-.818-18.46c2.757 1.61 3.968 5.06 3.968 5.06.227.55.878.925 1.72.925.84 0 1.49-.375 1.718-.925 0 0 1.211-3.45 3.968-5.06l-.818 18.46c.126 1.072.883 1.857 2.047 1.857 1.146 0 1.91-.732 2.065-1.804l.71-9.757.597 9.757c.155 1.066.919 1.804 2.064 1.804 1.164 0 1.916-.779 2.047-1.857l-.752-16.926.012-3.256c2.011-1.236 5.395-2.811 7.602-8.053.424-.949.28-2.161-.423-2.519-.895-.456-1.802-.316-2.429.691-1.259 2.009-3.449 4.317-5.824 5.313-3.312 1.388-4.41.661-6.809 1.458-2.601.867-4.016 2.565-5.782 5.704-1.766-3.145-3.18-4.837-5.782-5.704-2.399-.803-3.497-.076-6.809-1.458-2.375-.996-4.565-3.298-5.824-5.313-.62-1.007-1.521-1.147-2.417-.691-.704.358-.847 1.57-.423 2.519 2.208 5.236 5.585 6.817 7.602 8.053z"
    ></path>
    <path
      fill="#FBB034"
      d="M38.778 109c-2.352 0-4.255 1.868-4.255 4.176 0 2.307 1.903 4.176 4.255 4.176 2.35 0 4.254-1.869 4.254-4.176-.006-2.308-1.91-4.176-4.254-4.176z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M231.229 139.955l8.344-1.809c.407-.079.827-.463.948-.858l.541-1.365-4.078-3.763-.547 2.533-5.968 1.931s-1.167.481-.808 1.918c.328 1.328 1.258 1.419 1.568 1.413z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M231.556 125.789c.638.348 1.452.232 1.793-.292l2.65-4.147 3.616.182-1.969 4.745c-.347 1.011-1.04 2.984.109 4.281l5.026 4.683-.395 5.762c-.14 1.51.747 1.9 1.61 1.979 1.307.122 1.89-.396 2.073-1.919l1.136-7.691c.073-.402-.127-.95-.443-1.206l-3.513-3.496 1.386-2.923.468 1.644c.091.274.297.621.601.731 2.036.749 6.618 1.864 6.618 1.864.505.109 1.228-.22 1.55-1.024.407-1.011-.34-1.741-.34-1.741l-4.953-2.479s-.742-3.867-1.137-5.359c-.583-2.187-3.154-3.204-4.527-2.911l-8.636 1.498c-.651.134-.961.645-1.252 1.181l-2.358 4.805c.006-.006-.414 1.121.887 1.833zm16.956-8.91a4.431 4.431 0 004.43-4.439 4.435 4.435 0 00-4.43-4.44 4.432 4.432 0 00-4.43 4.44 4.435 4.435 0 004.43 4.439zm-25.963 24.848c.863.11 1.72-.14 1.836-.834l1.665-7.96c.085-.378-.079-.901-.371-1.157l-3.743-3.898-1.866 4.958 1.732 1.699-.644 5.852c-.006-.006.006 1.157 1.391 1.34z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M217.809 133.968l2.401-6.534 1.732-3.277.279 2.004c.043.402.383.792.772.968l5.622 2.345c.377.17.954-.055 1.422-.95.462-.883-.261-1.504-.261-1.504l-3.938-2.863-.377-6.23c-.079-.828-1.21-2.253-2.948-2.296-2.37-.061-9.493.225-9.493.225a.905.905 0 00-.826.731l-1.289 6.291s-.054.926 1.173 1.176c.547.109 1.313-.006 1.428-.457l1.283-4.087 3.093.415-2.801 4.86-1.204 6.845-5.087 1.857s-1.087.488-.711 1.84c.231.834.912 1.455 1.611 1.278l7.244-1.802c.383-.085.772-.457.875-.835zm9.912-17.107a4.39 4.39 0 004.716-4.05 4.403 4.403 0 00-4.041-4.732 4.391 4.391 0 00-4.717 4.05 4.403 4.403 0 004.042 4.732z"
    ></path>
    <path
      fill="#7A68B0"
      d="M578.229 120.955l8.344-1.809c.407-.079.827-.463.948-.858l.541-1.365-4.078-3.763-.547 2.533-5.968 1.931s-1.167.481-.808 1.918c.328 1.328 1.258 1.419 1.568 1.413z"
    ></path>
    <path
      fill="#7A68B0"
      d="M578.556 106.789c.638.348 1.452.232 1.793-.292l2.65-4.147 3.616.182-1.969 4.745c-.347 1.011-1.04 2.984.109 4.281l5.026 4.683-.395 5.762c-.14 1.51.747 1.9 1.61 1.979 1.307.122 1.89-.396 2.073-1.919l1.136-7.691c.073-.402-.127-.95-.443-1.206l-3.513-3.496 1.386-2.923.468 1.644c.091.274.297.621.601.731 2.036.749 6.618 1.864 6.618 1.864.505.109 1.228-.22 1.55-1.024.407-1.011-.34-1.741-.34-1.741l-4.953-2.479s-.742-3.867-1.137-5.359c-.583-2.187-3.154-3.204-4.527-2.911l-8.636 1.498c-.651.134-.961.645-1.252 1.181l-2.358 4.805c.006-.006-.414 1.121.887 1.833zm16.956-8.909a4.432 4.432 0 004.43-4.44 4.436 4.436 0 00-4.43-4.44 4.432 4.432 0 00-4.43 4.44 4.436 4.436 0 004.43 4.44zm-25.963 24.847c.863.11 1.72-.14 1.836-.834l1.665-7.96c.085-.378-.079-.901-.371-1.157l-3.743-3.898-1.866 4.958 1.732 1.699-.644 5.852c-.006-.006.006 1.157 1.391 1.34z"
    ></path>
    <path
      fill="#7A68B0"
      d="M564.809 114.968l2.401-6.534 1.732-3.277.279 2.004c.043.402.383.792.772.968l5.622 2.345c.377.17.954-.055 1.422-.95.462-.883-.261-1.504-.261-1.504l-3.938-2.863-.377-6.23c-.079-.828-1.21-2.253-2.948-2.296-2.37-.06-9.493.225-9.493.225a.904.904 0 00-.826.731l-1.289 6.291s-.054.926 1.173 1.176c.547.109 1.313-.006 1.428-.457l1.283-4.087 3.093.415-2.801 4.86-1.204 6.845-5.087 1.857s-1.087.488-.711 1.84c.231.834.912 1.455 1.611 1.278l7.244-1.802c.383-.085.772-.457.875-.835zm9.912-17.107a4.39 4.39 0 004.716-4.05 4.403 4.403 0 00-4.041-4.732 4.391 4.391 0 00-4.717 4.05 4.403 4.403 0 004.042 4.732z"
    ></path>
    <path
      fill="#E31B23"
      d="M513.229 68.955l8.344-1.809c.407-.079.827-.463.948-.858l.541-1.365-4.078-3.763-.547 2.533-5.968 1.93s-1.167.482-.808 1.92c.328 1.327 1.258 1.418 1.568 1.412z"
    ></path>
    <path
      fill="#E31B23"
      d="M513.556 54.79c.638.347 1.452.23 1.793-.293l2.65-4.147 3.616.182-1.969 4.745c-.347 1.01-1.04 2.984.109 4.281l5.026 4.683-.395 5.762c-.14 1.51.747 1.9 1.61 1.979 1.307.122 1.89-.396 2.073-1.918l1.136-7.692c.073-.402-.127-.95-.443-1.206l-3.513-3.496 1.386-2.923.468 1.644c.091.274.297.621.601.73 2.036.75 6.618 1.864 6.618 1.864.505.11 1.228-.219 1.55-1.023.407-1.01-.34-1.741-.34-1.741l-4.953-2.48s-.742-3.866-1.137-5.358c-.583-2.187-3.154-3.204-4.527-2.912l-8.636 1.499c-.651.134-.961.645-1.252 1.181l-2.358 4.805c.006-.006-.414 1.12.887 1.833zm16.956-8.91a4.432 4.432 0 004.43-4.44 4.436 4.436 0 00-4.43-4.44 4.432 4.432 0 00-4.43 4.44 4.436 4.436 0 004.43 4.44zm-25.963 24.847c.863.11 1.72-.14 1.836-.834l1.665-7.96c.085-.378-.079-.901-.371-1.157l-3.743-3.898-1.866 4.958 1.732 1.699-.644 5.852c-.006-.006.006 1.157 1.391 1.34z"
    ></path>
    <path
      fill="#E31B23"
      d="M499.809 62.968l2.401-6.534 1.732-3.277.279 2.004c.043.402.383.792.772.968l5.622 2.345c.377.17.954-.055 1.422-.95.462-.883-.261-1.504-.261-1.504l-3.938-2.863-.377-6.23c-.079-.828-1.21-2.253-2.948-2.296-2.37-.06-9.493.225-9.493.225a.904.904 0 00-.826.731l-1.289 6.291s-.054.926 1.173 1.176c.547.11 1.313-.006 1.428-.457l1.283-4.087 3.093.415-2.801 4.86-1.204 6.845-5.087 1.857s-1.087.487-.711 1.84c.231.834.912 1.455 1.611 1.279l7.244-1.803c.383-.085.772-.457.875-.834zm9.912-17.107a4.39 4.39 0 004.716-4.05 4.403 4.403 0 00-4.041-4.732 4.391 4.391 0 00-4.717 4.05 4.403 4.403 0 004.042 4.732z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M615.229 43.955l8.344-1.809c.407-.079.827-.463.948-.858l.541-1.365-4.078-3.763-.547 2.533-5.968 1.93s-1.167.482-.808 1.92c.328 1.327 1.258 1.418 1.568 1.412z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M615.556 29.79c.638.347 1.452.23 1.793-.293l2.65-4.147 3.616.182-1.969 4.745c-.347 1.01-1.04 2.984.109 4.281l5.026 4.683-.395 5.762c-.14 1.51.747 1.9 1.61 1.979 1.307.122 1.89-.396 2.073-1.919l1.136-7.691c.073-.402-.127-.95-.443-1.206l-3.513-3.496 1.386-2.923.468 1.644c.091.274.297.621.601.73 2.036.75 6.618 1.864 6.618 1.864.505.11 1.228-.219 1.55-1.023.407-1.01-.34-1.741-.34-1.741l-4.953-2.48s-.742-3.866-1.137-5.358c-.583-2.187-3.154-3.204-4.527-2.912l-8.636 1.499c-.651.134-.961.645-1.252 1.181l-2.358 4.805c.006-.006-.414 1.12.887 1.833zm16.956-8.91a4.432 4.432 0 004.43-4.44 4.436 4.436 0 00-4.43-4.44 4.432 4.432 0 00-4.43 4.44 4.436 4.436 0 004.43 4.44zm-25.963 24.847c.863.11 1.72-.14 1.836-.834l1.665-7.96c.085-.378-.079-.901-.371-1.157l-3.743-3.898-1.866 4.958 1.732 1.699-.644 5.852c-.006-.006.006 1.157 1.391 1.34z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M601.809 37.968l2.401-6.534 1.732-3.277.279 2.004c.043.402.383.792.772.968l5.622 2.345c.377.17.954-.055 1.422-.95.462-.883-.261-1.504-.261-1.504l-3.938-2.863-.377-6.23c-.079-.828-1.21-2.253-2.948-2.296-2.37-.06-9.493.225-9.493.225a.904.904 0 00-.826.731l-1.289 6.291s-.054.926 1.173 1.176c.547.11 1.313-.006 1.428-.457l1.283-4.087 3.093.415-2.801 4.86-1.204 6.845-5.087 1.857s-1.087.487-.711 1.84c.231.834.912 1.455 1.611 1.279l7.244-1.803c.383-.085.772-.457.875-.834zm9.912-17.107a4.39 4.39 0 004.716-4.05 4.403 4.403 0 00-4.041-4.732 4.391 4.391 0 00-4.717 4.05 4.403 4.403 0 004.042 4.732z"
    ></path>
    <path
      fill="#7A68B0"
      d="M924.229 128.955l8.344-1.809c.407-.079.827-.463.948-.858l.541-1.365-4.078-3.763-.547 2.533-5.968 1.931s-1.167.481-.808 1.918c.328 1.328 1.258 1.419 1.568 1.413z"
    ></path>
    <path
      fill="#7A68B0"
      d="M924.556 114.789c.638.348 1.452.232 1.793-.292l2.65-4.147 3.616.182-1.969 4.745c-.347 1.011-1.04 2.984.109 4.281l5.026 4.683-.395 5.762c-.14 1.51.747 1.9 1.61 1.979 1.307.122 1.89-.396 2.073-1.919l1.136-7.691c.073-.402-.127-.95-.443-1.206l-3.513-3.496 1.386-2.923.468 1.644c.091.274.297.621.601.731 2.036.749 6.618 1.864 6.618 1.864.505.109 1.228-.22 1.55-1.024.407-1.011-.34-1.741-.34-1.741l-4.953-2.479s-.742-3.867-1.137-5.359c-.583-2.187-3.154-3.204-4.527-2.911l-8.636 1.498c-.651.134-.961.645-1.252 1.181l-2.358 4.805c.006-.006-.414 1.121.887 1.833zm16.956-8.91a4.431 4.431 0 004.43-4.439 4.436 4.436 0 00-4.43-4.44 4.432 4.432 0 00-4.43 4.44 4.435 4.435 0 004.43 4.439zm-25.963 24.848c.863.11 1.72-.14 1.836-.834l1.665-7.96c.085-.378-.079-.901-.371-1.157l-3.743-3.898-1.866 4.958 1.732 1.699-.644 5.852c-.006-.006.006 1.157 1.391 1.34z"
    ></path>
    <path
      fill="#7A68B0"
      d="M910.809 122.968l2.401-6.534 1.732-3.277.279 2.004c.043.402.383.792.772.968l5.622 2.345c.377.17.954-.055 1.422-.95.462-.883-.261-1.504-.261-1.504l-3.938-2.863-.377-6.23c-.079-.828-1.21-2.253-2.948-2.296-2.37-.061-9.493.225-9.493.225a.905.905 0 00-.826.731l-1.289 6.291s-.054.926 1.173 1.176c.547.109 1.313-.006 1.428-.457l1.283-4.087 3.093.415-2.801 4.86-1.204 6.845-5.087 1.857s-1.087.488-.711 1.84c.231.834.912 1.455 1.611 1.278l7.244-1.802c.383-.085.772-.457.875-.835zm9.912-17.107a4.39 4.39 0 004.716-4.05 4.403 4.403 0 00-4.041-4.732 4.391 4.391 0 00-4.717 4.05 4.403 4.403 0 004.042 4.732z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M91.219 94.955l7.981-1.809c.39-.079.79-.463.907-.858l.517-1.365-3.9-3.763-.523 2.533-5.709 1.93s-1.116.482-.773 1.92c.314 1.327 1.203 1.418 1.5 1.412z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M91.531 80.79c.61.347 1.39.23 1.715-.293l2.535-4.147 3.459.182-1.884 4.745c-.331 1.01-.994 2.984.105 4.281l4.807 4.683-.378 5.762c-.133 1.51.715 1.9 1.541 1.979 1.25.122 1.808-.396 1.982-1.918l1.087-7.692c.07-.402-.122-.95-.424-1.206l-3.36-3.496 1.325-2.923.448 1.644c.087.274.285.621.575.73 1.948.75 6.331 1.865 6.331 1.865.483.11 1.174-.22 1.482-1.024.39-1.01-.325-1.741-.325-1.741l-4.738-2.48s-.709-3.866-1.087-5.358c-.558-2.187-3.017-3.204-4.331-2.911l-8.26 1.498c-.622.134-.919.645-1.198 1.181l-2.255 4.805c.005-.006-.396 1.12.848 1.833zm16.22-8.91c2.342 0 4.237-1.986 4.237-4.44 0-2.455-1.901-4.44-4.237-4.44-2.343 0-4.238 1.985-4.238 4.44 0 2.454 1.901 4.44 4.238 4.44zM82.917 96.727c.825.11 1.645-.14 1.755-.834l1.593-7.96c.082-.378-.076-.901-.355-1.157l-3.58-3.898-1.785 4.957 1.657 1.7-.617 5.852c-.005-.006.006 1.157 1.332 1.34z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M78.383 88.969l2.296-6.535 1.657-3.277.267 2.004c.04.402.366.792.738.968l5.378 2.345c.36.17.912-.055 1.36-.95.442-.883-.25-1.504-.25-1.504l-3.767-2.863-.36-6.23c-.076-.828-1.157-2.253-2.82-2.296-2.267-.06-9.08.225-9.08.225-.372.013-.733.341-.79.731l-1.233 6.291s-.052.926 1.122 1.176c.523.11 1.256-.006 1.366-.457l1.227-4.087 2.959.415-2.68 4.86-1.151 6.845-4.866 1.857s-1.04.488-.68 1.84c.22.834.872 1.455 1.54 1.279l6.93-1.803c.366-.085.738-.457.837-.834zm9.481-17.108c2.313.189 4.336-1.626 4.51-4.05.18-2.424-1.552-4.543-3.865-4.732-2.314-.189-4.337 1.626-4.511 4.05-.18 2.424 1.552 4.543 3.865 4.732z"
    ></path>
    <path
      fill="#7A68B0"
      d="M788.327 123.564c.152 0 .299-.024.446-.07 1.011-.306.976-1.018.976-1.024l.011-3.355 3.415-.33v1.938a1.536 1.536 0 00-.758.035c-.694.208-1.117.839-.941 1.406.135.433.576.711 1.129.711.146 0 .299-.023.446-.063.993-.278.987-1.007.987-1.013v-4.454c0-.301-.264-.382-.411-.371l-4.378.429c-.188 0-.358.19-.358.41v3.633a1.518 1.518 0 00-.753.029c-.681.203-1.098.827-.928 1.383.141.422.582.706 1.117.706zm-23.654 6.398a4.878 4.878 0 003.097-1.088l5.6 1.869c.564.191.87.844.741 1.533l-1.093 5.108s-.306.902-1.428.636c-1.193-.277-1.111-1.261-1.111-1.261l.423-3.928-1.792-.382.587 1.14-.346 3.633c-.059.694.446 1.487 1.345 1.759a3.11 3.11 0 01-.293.63c-.312.521-1.581 3.194-1.581 3.194l.87 3.355s.523 1.556-1.146 1.932c-1.64.37-2.039-1.348-2.039-1.348l-.882-3.193c-.217-.694-.141-.862.076-1.383l.994-2.904-1.205-.463-.776 1.973-2.315 1.354.517 3.824s.353 1.527-1.363 1.683c-1.581.145-1.734-1.481-1.734-1.481-.182-1.625-.676-4.495-.711-5.698-.012-.382.188-.856.829-1.29l4.125-2.783-1.563-3.997-7.169-3.529c-.294-.203-.424-.625-.283-.949l2.051-5.756s.353-.885 1.464-.555c1.175.347 1.034 1.319 1.034 1.319l-1.134 4.032s1.234.503 2.121.85c.417.654 1.792 2.135 4.09 2.164z"
    ></path>
    <path
      fill="#7A68B0"
      d="M764.674 128.805c2.054 0 3.72-1.639 3.72-3.662 0-2.022-1.666-3.662-3.72-3.662-2.054 0-3.72 1.64-3.72 3.662 0 2.023 1.666 3.662 3.72 3.662zm15.971-6.705c2.48 0 4.496-1.978 4.496-4.425 0-2.442-2.01-4.426-4.496-4.426-2.479 0-4.495 1.984-4.495 4.426 0 2.441 2.01 4.425 4.495 4.425z"
    ></path>
    <path
      fill="#7A68B0"
      d="M775.663 127.035l1.298 6.519-3.778 12.86c-.271 1.001.129 2.1 1.246 2.453 1.228.388 2.28-.127 2.562-1.134l4.237-11.46.528 10.893c.159 1.082.929 1.823 2.087 1.823 1.169 0 1.933-.793 2.062-1.875v-11.506c0-1.319-.305-2.036-.676-3.008-.335-.862-.94-2.123-.94-2.123l2.445 2.175a1.809 1.809 0 001.857.457l6.164-1.984a1.764 1.764 0 001.152-2.233 1.805 1.805 0 00-2.268-1.134l-5.107 1.07-3.873-4.015c-.652-.7-1.351-.954-2.25-1.215-1.029-.3-8.856-2.811-8.856-2.811l1.863-5.103a1.85 1.85 0 00-.882-2.493c-.94-.451-2.08-.063-2.532.868l-2.986 7.058c-.317.648-.393 1.434.224 1.978l6.423 3.94z"
    ></path>
    <path
      fill="#FBB034"
      d="M622.327 218.564c.152 0 .299-.024.446-.07 1.011-.306.976-1.018.976-1.024l.011-3.355 3.415-.33v1.938a1.536 1.536 0 00-.758.035c-.694.208-1.117.839-.941 1.406.135.433.576.711 1.129.711.146 0 .299-.023.446-.063.993-.278.987-1.007.987-1.013v-4.454c0-.301-.264-.382-.411-.371l-4.378.429c-.188 0-.358.19-.358.41v3.633a1.518 1.518 0 00-.753.029c-.681.203-1.098.827-.928 1.383.141.422.582.706 1.117.706zm-23.654 6.398a4.878 4.878 0 003.097-1.088l5.6 1.869c.564.191.87.844.741 1.533l-1.093 5.108s-.306.902-1.428.636c-1.193-.277-1.111-1.261-1.111-1.261l.423-3.928-1.792-.382.587 1.14-.346 3.633c-.059.694.446 1.487 1.345 1.759a3.11 3.11 0 01-.293.63c-.312.521-1.581 3.194-1.581 3.194l.87 3.355s.523 1.556-1.146 1.932c-1.64.37-2.039-1.348-2.039-1.348l-.882-3.193c-.217-.694-.141-.862.076-1.383l.994-2.904-1.205-.463-.776 1.973-2.315 1.354.517 3.824s.353 1.527-1.363 1.683c-1.581.145-1.734-1.481-1.734-1.481-.182-1.625-.676-4.495-.711-5.698-.012-.382.188-.856.829-1.29l4.125-2.783-1.563-3.997-7.169-3.529c-.294-.203-.424-.625-.283-.949l2.051-5.756s.353-.885 1.464-.555c1.175.347 1.034 1.319 1.034 1.319l-1.134 4.032s1.234.503 2.121.85c.417.654 1.792 2.135 4.09 2.164z"
    ></path>
    <path
      fill="#FBB034"
      d="M598.674 223.805c2.054 0 3.72-1.639 3.72-3.662 0-2.022-1.666-3.662-3.72-3.662-2.054 0-3.72 1.64-3.72 3.662 0 2.023 1.666 3.662 3.72 3.662zm15.971-6.705c2.48 0 4.496-1.978 4.496-4.425 0-2.442-2.01-4.426-4.496-4.426-2.479 0-4.495 1.984-4.495 4.426 0 2.441 2.01 4.425 4.495 4.425z"
    ></path>
    <path
      fill="#FBB034"
      d="M609.663 222.035l1.298 6.519-3.778 12.86c-.271 1.001.129 2.1 1.246 2.453 1.228.388 2.28-.127 2.562-1.134l4.237-11.46.528 10.893c.159 1.082.929 1.823 2.087 1.823 1.169 0 1.933-.793 2.062-1.875v-11.506c0-1.319-.305-2.036-.676-3.008-.335-.862-.94-2.123-.94-2.123l2.445 2.175a1.809 1.809 0 001.857.457l6.164-1.984a1.764 1.764 0 001.152-2.233 1.805 1.805 0 00-2.268-1.134l-5.107 1.07-3.873-4.015c-.652-.7-1.351-.954-2.25-1.215-1.029-.3-8.856-2.811-8.856-2.811l1.863-5.103a1.85 1.85 0 00-.882-2.493c-.94-.451-2.08-.063-2.532.868l-2.986 7.058c-.317.648-.393 1.434.224 1.978l6.423 3.94z"
    ></path>
    <path
      fill="#E31B23"
      d="M788.327 204.564c.152 0 .299-.024.446-.07 1.011-.306.976-1.018.976-1.024l.011-3.355 3.415-.33v1.938a1.536 1.536 0 00-.758.035c-.694.208-1.117.839-.941 1.406.135.433.576.711 1.129.711.146 0 .299-.023.446-.063.993-.278.987-1.007.987-1.013v-4.454c0-.301-.264-.382-.411-.371l-4.378.429c-.188 0-.358.19-.358.41v3.633a1.518 1.518 0 00-.753.029c-.681.203-1.098.827-.928 1.383.141.422.582.706 1.117.706zm-23.654 6.398a4.878 4.878 0 003.097-1.088l5.6 1.869c.564.191.87.844.741 1.533l-1.093 5.108s-.306.902-1.428.636c-1.193-.277-1.111-1.261-1.111-1.261l.423-3.928-1.792-.382.587 1.14-.346 3.633c-.059.694.446 1.487 1.345 1.759a3.11 3.11 0 01-.293.63c-.312.521-1.581 3.194-1.581 3.194l.87 3.355s.523 1.556-1.146 1.932c-1.64.37-2.039-1.348-2.039-1.348l-.882-3.193c-.217-.694-.141-.862.076-1.383l.994-2.904-1.205-.463-.776 1.973-2.315 1.354.517 3.824s.353 1.527-1.363 1.683c-1.581.145-1.734-1.481-1.734-1.481-.182-1.625-.676-4.495-.711-5.698-.012-.382.188-.856.829-1.29l4.125-2.783-1.563-3.997-7.169-3.529c-.294-.203-.424-.625-.283-.949l2.051-5.756s.353-.885 1.464-.555c1.175.347 1.034 1.319 1.034 1.319l-1.134 4.032s1.234.503 2.121.85c.417.654 1.792 2.135 4.09 2.164z"
    ></path>
    <path
      fill="#E31B23"
      d="M764.674 209.805c2.054 0 3.72-1.639 3.72-3.662 0-2.022-1.666-3.662-3.72-3.662-2.054 0-3.72 1.64-3.72 3.662 0 2.023 1.666 3.662 3.72 3.662zm15.971-6.705c2.48 0 4.496-1.978 4.496-4.425 0-2.442-2.01-4.426-4.496-4.426-2.479 0-4.495 1.984-4.495 4.426 0 2.441 2.01 4.425 4.495 4.425z"
    ></path>
    <path
      fill="#E31B23"
      d="M775.663 208.035l1.298 6.519-3.778 12.86c-.271 1.001.129 2.1 1.246 2.453 1.228.388 2.28-.127 2.562-1.134l4.237-11.46.528 10.893c.159 1.082.929 1.823 2.087 1.823 1.169 0 1.933-.793 2.062-1.875v-11.506c0-1.319-.305-2.036-.676-3.008-.335-.862-.94-2.123-.94-2.123l2.445 2.175a1.809 1.809 0 001.857.457l6.164-1.984a1.764 1.764 0 001.152-2.233 1.805 1.805 0 00-2.268-1.134l-5.107 1.07-3.873-4.015c-.652-.7-1.351-.954-2.25-1.215-1.029-.3-8.856-2.811-8.856-2.811l1.863-5.103a1.85 1.85 0 00-.882-2.493c-.94-.451-2.08-.063-2.532.868l-2.986 7.058c-.317.648-.393 1.434.224 1.978l6.423 3.94z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M720.514 172.564c.149 0 .292-.024.435-.07.987-.306.952-1.018.952-1.024l.012-3.355 3.331-.33v1.938a1.466 1.466 0 00-.74.035c-.677.208-1.089.839-.917 1.406.132.433.561.711 1.1.711.144 0 .293-.023.436-.063.969-.278.963-1.007.963-1.013v-4.454c0-.301-.258-.382-.401-.371l-4.271.429c-.184 0-.35.19-.35.41v3.633a1.444 1.444 0 00-.734.029c-.665.203-1.072.827-.906 1.383.138.422.568.706 1.09.706zm-23.077 6.398c1.147.012 2.196-.411 3.021-1.087l5.464 1.868c.55.191.848.845.722 1.533l-1.066 5.108s-.298.903-1.393.637c-1.164-.278-1.084-1.261-1.084-1.261l.413-3.929-1.749-.381.574 1.139-.339 3.633c-.057.695.436 1.487 1.313 1.759a3.106 3.106 0 01-.286.631c-.304.52-1.542 3.193-1.542 3.193l.848 3.355s.51 1.556-1.118 1.932c-1.599.371-1.989-1.348-1.989-1.348l-.86-3.193c-.212-.694-.138-.862.074-1.383l.969-2.904-1.175-.462-.757 1.972-2.259 1.354.505 3.824s.344 1.527-1.33 1.683c-1.543.145-1.692-1.481-1.692-1.481-.177-1.625-.659-4.495-.693-5.698-.012-.382.183-.856.808-1.29l4.025-2.783-1.525-3.997-6.995-3.529a.82.82 0 01-.275-.949l2.001-5.756s.344-.885 1.427-.555c1.147.347 1.01 1.319 1.01 1.319l-1.107 4.032s1.204.503 2.07.851c.407.653 1.748 2.134 3.99 2.163z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M697.438 177.805c2.004 0 3.629-1.639 3.629-3.662 0-2.022-1.625-3.662-3.629-3.662-2.005 0-3.629 1.64-3.629 3.662 0 2.023 1.624 3.662 3.629 3.662zm15.582-6.705c2.419 0 4.385-1.978 4.385-4.425 0-2.441-1.96-4.426-4.385-4.426-2.42 0-4.386 1.985-4.386 4.426s1.961 4.425 4.386 4.425z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M708.159 176.035l1.267 6.519-3.687 12.86c-.263 1.001.126 2.1 1.216 2.453 1.198.388 2.224-.127 2.499-1.134l4.134-11.46.516 10.893c.155 1.082.906 1.823 2.035 1.823 1.141 0 1.886-.793 2.013-1.875v-11.506c0-1.319-.299-2.036-.66-3.008-.327-.862-.917-2.123-.917-2.123l2.385 2.175a1.735 1.735 0 001.812.457l6.014-1.984c.923-.301 1.421-1.302 1.123-2.233a1.753 1.753 0 00-2.213-1.134l-4.982 1.07-3.778-4.015c-.636-.7-1.319-.954-2.196-1.215-1.003-.3-8.64-2.811-8.64-2.811l1.818-5.103a1.87 1.87 0 00-.86-2.493 1.838 1.838 0 00-2.471.868l-2.912 7.058c-.31.648-.385 1.434.217 1.978l6.267 3.94zm228.355 14.529c.149 0 .292-.024.435-.07.987-.306.952-1.018.952-1.024l.012-3.355 3.331-.33v1.938a1.466 1.466 0 00-.74.035c-.677.208-1.089.839-.917 1.406.132.433.561.711 1.1.711.144 0 .293-.023.436-.063.969-.278.963-1.007.963-1.013v-4.454c0-.301-.258-.382-.401-.371l-4.271.429c-.184 0-.35.19-.35.41v3.633a1.444 1.444 0 00-.734.029c-.665.203-1.072.827-.906 1.383.138.422.568.706 1.09.706zm-23.077 6.398c1.147.012 2.196-.411 3.021-1.087l5.464 1.868c.55.191.848.845.722 1.533l-1.066 5.108s-.298.903-1.393.637c-1.164-.278-1.084-1.261-1.084-1.261l.413-3.929-1.749-.381.574 1.139-.339 3.633c-.057.695.436 1.487 1.313 1.759a3.106 3.106 0 01-.286.631c-.304.52-1.542 3.193-1.542 3.193l.848 3.355s.51 1.556-1.118 1.932c-1.599.371-1.989-1.348-1.989-1.348l-.86-3.193c-.212-.694-.138-.862.074-1.383l.969-2.904-1.175-.462-.757 1.972-2.259 1.354.505 3.824s.344 1.527-1.33 1.683c-1.543.145-1.692-1.481-1.692-1.481-.177-1.625-.659-4.495-.693-5.698-.012-.382.183-.856.808-1.29l4.025-2.783-1.525-3.997-6.995-3.529a.82.82 0 01-.275-.949l2.001-5.756s.344-.885 1.427-.555c1.147.347 1.01 1.319 1.01 1.319l-1.107 4.032s1.204.503 2.07.851c.407.653 1.748 2.134 3.99 2.163z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M913.438 195.805c2.004 0 3.629-1.639 3.629-3.662 0-2.022-1.625-3.662-3.629-3.662-2.005 0-3.629 1.64-3.629 3.662 0 2.023 1.624 3.662 3.629 3.662zm15.582-6.705c2.419 0 4.385-1.978 4.385-4.425 0-2.441-1.96-4.426-4.385-4.426-2.42 0-4.386 1.985-4.386 4.426s1.961 4.425 4.386 4.425z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M924.159 194.035l1.267 6.519-3.687 12.86c-.263 1.001.126 2.1 1.216 2.453 1.198.388 2.224-.127 2.499-1.134l4.134-11.46.516 10.893c.155 1.082.906 1.823 2.035 1.823 1.141 0 1.886-.793 2.013-1.875v-11.506c0-1.319-.299-2.036-.66-3.008-.327-.862-.917-2.123-.917-2.123l2.385 2.175a1.735 1.735 0 001.812.457l6.014-1.984c.923-.301 1.421-1.302 1.123-2.233a1.753 1.753 0 00-2.213-1.134l-4.982 1.07-3.778-4.015c-.636-.7-1.319-.954-2.196-1.215-1.003-.3-8.64-2.811-8.64-2.811l1.818-5.103a1.87 1.87 0 00-.86-2.493 1.838 1.838 0 00-2.471.868l-2.912 7.058c-.31.648-.385 1.434.217 1.978l6.267 3.94z"
    ></path>
    <path
      fill="#E31B23"
      d="M320.514 116.564c.149 0 .292-.024.435-.07.987-.306.952-1.018.952-1.024l.012-3.355 3.331-.33v1.938a1.466 1.466 0 00-.74.035c-.677.208-1.089.839-.917 1.406.132.433.561.711 1.1.711.144 0 .293-.023.436-.063.969-.278.963-1.007.963-1.013v-4.454c0-.301-.258-.382-.401-.371l-4.271.429c-.184 0-.35.19-.35.41v3.633a1.444 1.444 0 00-.734.029c-.665.203-1.072.827-.906 1.383.138.422.568.706 1.09.706zm-23.077 6.398c1.147.011 2.196-.411 3.021-1.088l5.464 1.869c.55.191.848.844.722 1.533l-1.066 5.108s-.298.902-1.393.636c-1.164-.277-1.084-1.261-1.084-1.261l.413-3.928-1.749-.382.574 1.14-.339 3.633c-.057.694.436 1.487 1.313 1.759a3.1 3.1 0 01-.286.63c-.304.521-1.542 3.194-1.542 3.194l.848 3.355s.51 1.556-1.118 1.932c-1.599.37-1.989-1.348-1.989-1.348l-.86-3.193c-.212-.694-.138-.862.074-1.383l.969-2.904-1.175-.463-.757 1.973-2.259 1.354.505 3.824s.344 1.527-1.33 1.683c-1.543.145-1.692-1.481-1.692-1.481-.177-1.625-.659-4.495-.693-5.698-.012-.382.183-.856.808-1.29l4.025-2.783-1.525-3.997-6.995-3.529c-.286-.203-.413-.625-.275-.949l2.001-5.756s.344-.885 1.427-.555c1.147.347 1.01 1.319 1.01 1.319l-1.107 4.032s1.204.503 2.07.85c.407.654 1.748 2.135 3.99 2.164z"
    ></path>
    <path
      fill="#E31B23"
      d="M297.438 121.805c2.004 0 3.629-1.639 3.629-3.662 0-2.022-1.625-3.662-3.629-3.662-2.005 0-3.629 1.64-3.629 3.662 0 2.023 1.624 3.662 3.629 3.662zm15.582-6.705c2.419 0 4.385-1.978 4.385-4.425 0-2.442-1.96-4.426-4.385-4.426-2.42 0-4.386 1.984-4.386 4.426 0 2.441 1.961 4.425 4.386 4.425z"
    ></path>
    <path
      fill="#E31B23"
      d="M308.159 120.035l1.267 6.519-3.687 12.86c-.263 1.001.126 2.1 1.216 2.453 1.198.388 2.224-.127 2.499-1.134l4.134-11.46.516 10.893c.155 1.082.906 1.823 2.035 1.823 1.141 0 1.886-.793 2.013-1.875v-11.506c0-1.319-.299-2.036-.66-3.008-.327-.862-.917-2.123-.917-2.123l2.385 2.175a1.735 1.735 0 001.812.457l6.014-1.984c.923-.301 1.421-1.302 1.123-2.233a1.753 1.753 0 00-2.213-1.134l-4.982 1.07-3.778-4.015c-.636-.7-1.319-.954-2.196-1.215-1.003-.3-8.64-2.811-8.64-2.811l1.818-5.103a1.87 1.87 0 00-.86-2.493 1.838 1.838 0 00-2.471.868l-2.912 7.058c-.31.648-.385 1.434.217 1.978l6.267 3.94z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M327.514 222.564c.149 0 .292-.024.435-.07.987-.306.952-1.018.952-1.024l.012-3.355 3.331-.33v1.938a1.466 1.466 0 00-.74.035c-.677.208-1.089.839-.917 1.406.132.433.561.711 1.1.711.144 0 .293-.023.436-.063.969-.278.963-1.007.963-1.013v-4.454c0-.301-.258-.382-.401-.371l-4.271.429c-.184 0-.35.19-.35.41v3.633a1.444 1.444 0 00-.734.029c-.665.203-1.072.827-.906 1.383.138.422.568.706 1.09.706zm-23.077 6.398c1.147.011 2.196-.411 3.021-1.088l5.464 1.869c.55.191.848.844.722 1.533l-1.066 5.108s-.298.902-1.393.636c-1.164-.277-1.084-1.261-1.084-1.261l.413-3.928-1.749-.382.574 1.14-.339 3.633c-.057.694.436 1.487 1.313 1.759a3.1 3.1 0 01-.286.63c-.304.521-1.542 3.194-1.542 3.194l.848 3.355s.51 1.556-1.118 1.932c-1.599.37-1.989-1.348-1.989-1.348l-.86-3.193c-.212-.694-.138-.862.074-1.383l.969-2.904-1.175-.463-.757 1.973-2.259 1.354.505 3.824s.344 1.527-1.33 1.683c-1.543.145-1.692-1.481-1.692-1.481-.177-1.625-.659-4.495-.693-5.698-.012-.382.183-.856.808-1.29l4.025-2.783-1.525-3.997-6.995-3.529c-.286-.203-.413-.625-.275-.949l2.001-5.756s.344-.885 1.427-.555c1.147.347 1.01 1.319 1.01 1.319l-1.107 4.032s1.204.503 2.07.85c.407.654 1.748 2.135 3.99 2.164z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M304.438 227.805c2.004 0 3.629-1.639 3.629-3.662 0-2.022-1.625-3.662-3.629-3.662-2.005 0-3.629 1.64-3.629 3.662 0 2.023 1.624 3.662 3.629 3.662zm15.582-6.705c2.419 0 4.385-1.978 4.385-4.425 0-2.442-1.96-4.426-4.385-4.426-2.42 0-4.386 1.984-4.386 4.426 0 2.441 1.961 4.425 4.386 4.425z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M315.159 226.035l1.267 6.519-3.687 12.86c-.263 1.001.126 2.1 1.216 2.453 1.198.388 2.224-.127 2.499-1.134l4.134-11.46.516 10.893c.155 1.082.906 1.823 2.035 1.823 1.141 0 1.886-.793 2.013-1.875v-11.506c0-1.319-.299-2.036-.66-3.008-.327-.862-.917-2.123-.917-2.123l2.385 2.175a1.735 1.735 0 001.812.457l6.014-1.984c.923-.301 1.421-1.302 1.123-2.233a1.753 1.753 0 00-2.213-1.134l-4.982 1.07-3.778-4.015c-.636-.7-1.319-.954-2.196-1.215-1.003-.3-8.64-2.811-8.64-2.811l1.818-5.103a1.87 1.87 0 00-.86-2.493 1.838 1.838 0 00-2.471.868l-2.912 7.058c-.31.648-.385 1.434.217 1.978l6.267 3.94zM823.131 92.763l6.879-4.008c-.914-.951-2.324-2.43-3.237-3.473l-5.743 4.197s-.999.9-.092 2.3c.947 1.467 2.193.984 2.193.984z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M825.442 76.021c.437 1.714 1.122 4.451 1.422 5.566.32 1.18.398 1.316.921 1.955 1.116 1.368 4.516 4.848 4.516 4.848l-3.531 7.182s-.522 1.336.933 2.144c1.097.606 1.997.313 2.434-.274l5.411-8.934c.254-.391.267-1.036.039-1.434l-3.681-6.784-.887-3.598s5.442-1.42 9.293-6.953c.626-.828 1.175-2.02.587-2.718-.62-.743-1.801-.892-2.558-.104-4.262 4.425-7.512 5.142-10.586 4.53-5.286-1.037-6.598-5.788-7.485-8.473-.392-1.18-1.534-1.414-2.389-1.094-.855.325-.998 1.635-.796 2.658 1.175 6.713 5.397 10.532 6.357 11.483z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M830.845 61a4.624 4.624 0 100 9.248 4.624 4.624 0 000-9.248zm34.315 32.057c.861-1.33-.085-2.177-.085-2.177l-5.325-3.767c-.868.984-2.2 2.392-3.061 3.285l6.396 3.584s1.187.456 2.075-.925z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M866.171 64.734c-.809-.306-1.893-.078-2.265 1.036-.841 2.542-2.088 7.039-7.087 8.03-2.911.573-5.984-.105-10.024-4.289-.718-.743-1.834-.6-2.421.098-.555.665-.04 1.792.561 2.574 3.648 5.233 8.797 6.582 8.797 6.582l-.842 3.408-3.485 6.426c-.222.378-.202.99.039 1.356l5.123 8.459c.418.573 1.227.827 2.304.26 1.397-.736.881-2.033.881-2.033l-3.341-6.804s3.217-3.29 4.274-4.594c.49-.606.568-.736.868-1.857.288-1.056.934-3.643 1.345-5.266.907-.9 4.908-4.523 6.024-10.877.195-.951.058-2.203-.751-2.509z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M851.487 68.234a4.398 4.398 0 004.399 4.392 4.398 4.398 0 004.399-4.392 4.398 4.398 0 00-4.399-4.393 4.393 4.393 0 00-4.399 4.393zm-454.356 39.529l6.879-4.008c-.914-.951-2.324-2.43-3.237-3.473l-5.743 4.197s-.999.899-.092 2.3c.947 1.467 2.193.984 2.193.984z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M399.442 91.021c.437 1.714 1.122 4.451 1.422 5.566.32 1.18.398 1.316.921 1.955 1.116 1.368 4.516 4.848 4.516 4.848l-3.531 7.182s-.522 1.336.933 2.144c1.097.606 1.997.313 2.434-.274l5.411-8.934c.254-.391.267-1.037.039-1.434l-3.681-6.784-.887-3.598s5.442-1.42 9.293-6.953c.626-.828 1.175-2.02.587-2.718-.62-.743-1.801-.892-2.558-.104-4.262 4.425-7.512 5.142-10.586 4.53-5.286-1.037-6.598-5.788-7.485-8.472-.392-1.18-1.534-1.415-2.389-1.095-.855.326-.998 1.635-.796 2.659 1.175 6.712 5.397 10.53 6.357 11.482z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M404.845 76a4.624 4.624 0 100 9.248 4.624 4.624 0 000-9.248zm34.315 32.057c.861-1.33-.085-2.177-.085-2.177l-5.325-3.767c-.868.984-2.2 2.392-3.061 3.285l6.396 3.584s1.187.456 2.075-.925z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M440.171 79.734c-.809-.306-1.893-.078-2.265 1.036-.841 2.542-2.088 7.039-7.087 8.03-2.911.573-5.984-.105-10.024-4.289-.718-.743-1.834-.6-2.421.098-.555.665-.04 1.792.561 2.574 3.648 5.233 8.797 6.582 8.797 6.582l-.842 3.408-3.485 6.426c-.222.378-.202.991.039 1.356l5.123 8.459c.418.573 1.227.827 2.304.26 1.397-.736.881-2.033.881-2.033l-3.341-6.804s3.217-3.291 4.274-4.594c.49-.606.568-.736.868-1.857.288-1.056.934-3.643 1.345-5.266.907-.9 4.908-4.523 6.024-10.877.195-.951.058-2.203-.751-2.509z"
    ></path>
    <path
      fill="#A7A7A7"
      d="M425.487 83.234a4.398 4.398 0 004.399 4.392 4.398 4.398 0 004.399-4.392 4.398 4.398 0 00-4.399-4.393 4.393 4.393 0 00-4.399 4.393z"
    ></path>
    <path
      fill="#7A68B0"
      d="M420.131 252.763l6.879-4.008c-.914-.951-2.324-2.43-3.237-3.473l-5.743 4.197s-.999.899-.092 2.3c.947 1.467 2.193.984 2.193.984z"
    ></path>
    <path
      fill="#7A68B0"
      d="M422.442 236.021c.437 1.714 1.122 4.451 1.422 5.566.32 1.179.398 1.316.921 1.955 1.116 1.368 4.516 4.848 4.516 4.848l-3.531 7.182s-.522 1.336.933 2.144c1.097.606 1.997.313 2.434-.274l5.411-8.934c.254-.391.267-1.037.039-1.434l-3.681-6.784-.887-3.597s5.442-1.421 9.293-6.954c.626-.828 1.175-2.02.587-2.718-.62-.742-1.801-.892-2.558-.104-4.262 4.425-7.512 5.142-10.586 4.529-5.286-1.036-6.598-5.787-7.485-8.472-.392-1.179-1.534-1.414-2.389-1.094-.855.325-.998 1.635-.796 2.659 1.175 6.712 5.397 10.531 6.357 11.482z"
    ></path>
    <path
      fill="#7A68B0"
      d="M427.845 221a4.624 4.624 0 100 9.247 4.623 4.623 0 100-9.247zm34.315 32.057c.861-1.33-.085-2.177-.085-2.177l-5.325-3.767c-.868.984-2.2 2.392-3.061 3.285l6.396 3.584s1.187.456 2.075-.925z"
    ></path>
    <path
      fill="#7A68B0"
      d="M463.171 224.734c-.809-.306-1.893-.078-2.265 1.036-.841 2.542-2.088 7.039-7.087 8.029-2.911.574-5.984-.104-10.024-4.288-.718-.743-1.834-.599-2.421.098-.555.665-.04 1.792.561 2.574 3.648 5.233 8.797 6.582 8.797 6.582l-.842 3.408-3.485 6.426c-.222.378-.202.991.039 1.356l5.123 8.459c.418.573 1.227.827 2.304.26 1.397-.736.881-2.033.881-2.033l-3.341-6.804s3.217-3.291 4.274-4.594c.49-.606.568-.737.868-1.857.288-1.056.934-3.643 1.345-5.266.907-.899 4.908-4.523 6.024-10.877.195-.951.058-2.202-.751-2.509z"
    ></path>
    <path
      fill="#7A68B0"
      d="M448.487 228.234a4.398 4.398 0 004.399 4.392 4.398 4.398 0 004.399-4.392 4.398 4.398 0 00-4.399-4.393 4.394 4.394 0 00-4.399 4.393zm-238.356-21.471l6.879-4.008c-.914-.951-2.324-2.43-3.237-3.473l-5.743 4.197s-.999.899-.092 2.3c.947 1.467 2.193.984 2.193.984z"
    ></path>
    <path
      fill="#7A68B0"
      d="M212.442 190.021c.437 1.714 1.122 4.451 1.422 5.566.32 1.179.398 1.316.921 1.955 1.116 1.368 4.516 4.848 4.516 4.848l-3.531 7.182s-.522 1.336.933 2.144c1.097.606 1.997.313 2.434-.274l5.411-8.934c.254-.391.267-1.037.039-1.434l-3.681-6.784-.887-3.597s5.442-1.421 9.293-6.954c.626-.828 1.175-2.02.587-2.718-.62-.742-1.801-.892-2.558-.104-4.262 4.425-7.512 5.142-10.586 4.529-5.286-1.036-6.598-5.787-7.485-8.472-.392-1.179-1.534-1.414-2.389-1.094-.855.325-.998 1.635-.796 2.659 1.175 6.712 5.397 10.531 6.357 11.482z"
    ></path>
    <path
      fill="#7A68B0"
      d="M217.845 175a4.624 4.624 0 100 9.247 4.623 4.623 0 100-9.247zm34.315 32.057c.861-1.33-.085-2.177-.085-2.177l-5.325-3.767c-.868.984-2.2 2.392-3.061 3.285l6.396 3.584s1.187.456 2.075-.925z"
    ></path>
    <path
      fill="#7A68B0"
      d="M253.171 178.734c-.809-.306-1.893-.078-2.265 1.036-.841 2.542-2.088 7.039-7.087 8.029-2.911.574-5.984-.104-10.024-4.288-.718-.743-1.834-.599-2.421.098-.555.665-.04 1.792.561 2.574 3.648 5.233 8.797 6.582 8.797 6.582l-.842 3.408-3.485 6.426c-.222.378-.202.991.039 1.356l5.123 8.459c.418.573 1.227.827 2.304.26 1.397-.736.881-2.033.881-2.033l-3.341-6.804s3.217-3.291 4.274-4.594c.49-.606.568-.737.868-1.857.288-1.056.934-3.643 1.345-5.266.907-.899 4.908-4.523 6.024-10.877.195-.951.058-2.202-.751-2.509z"
    ></path>
    <path
      fill="#7A68B0"
      d="M238.487 182.234a4.398 4.398 0 004.399 4.392 4.398 4.398 0 004.399-4.392 4.398 4.398 0 00-4.399-4.393 4.394 4.394 0 00-4.399 4.393zM71.131 195.763l6.879-4.008c-.914-.951-2.324-2.43-3.237-3.473l-5.743 4.197s-.999.899-.092 2.3c.947 1.467 2.193.984 2.193.984z"
    ></path>
    <path
      fill="#7A68B0"
      d="M73.442 179.021c.437 1.714 1.122 4.451 1.422 5.566.32 1.179.398 1.316.92 1.955 1.116 1.368 4.517 4.848 4.517 4.848l-3.531 7.182s-.522 1.336.933 2.144c1.097.606 1.997.313 2.434-.274l5.41-8.934c.255-.391.268-1.037.04-1.434l-3.68-6.784-.888-3.597s5.442-1.421 9.293-6.954c.626-.828 1.175-2.02.587-2.718-.62-.742-1.801-.892-2.558-.104-4.262 4.425-7.512 5.142-10.586 4.529-5.286-1.036-6.597-5.787-7.485-8.472-.392-1.179-1.534-1.414-2.389-1.094-.855.325-.998 1.635-.796 2.659 1.175 6.712 5.397 10.531 6.357 11.482z"
    ></path>
    <path
      fill="#7A68B0"
      d="M78.845 164a4.624 4.624 0 100 9.247 4.624 4.624 0 000-9.247zm34.315 32.057c.861-1.33-.085-2.177-.085-2.177l-5.325-3.767c-.868.984-2.2 2.392-3.061 3.285l6.396 3.584s1.187.456 2.075-.925z"
    ></path>
    <path
      fill="#7A68B0"
      d="M114.171 167.734c-.809-.306-1.893-.078-2.265 1.036-.841 2.542-2.088 7.039-7.087 8.029-2.911.574-5.984-.104-10.024-4.288-.718-.743-1.834-.599-2.421.098-.555.665-.04 1.792.56 2.574 3.649 5.233 8.798 6.582 8.798 6.582l-.842 3.408-3.485 6.426c-.222.378-.202.991.04 1.356l5.122 8.459c.418.573 1.227.827 2.304.26 1.397-.736.881-2.033.881-2.033l-3.341-6.804s3.217-3.291 4.274-4.594c.49-.606.568-.737.868-1.857.288-1.056.934-3.643 1.345-5.266.907-.899 4.908-4.523 6.024-10.877.195-.951.058-2.202-.751-2.509z"
    ></path>
    <path
      fill="#7A68B0"
      d="M99.487 171.234a4.397 4.397 0 004.399 4.392 4.398 4.398 0 004.399-4.392 4.398 4.398 0 00-4.399-4.393 4.394 4.394 0 00-4.399 4.393z"
    ></path>
  </svg>
)

export default Icon
