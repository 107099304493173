/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { ThreeImageOverlap } from "components/image-overlap"

const One = () => {
  const data = useStaticQuery(graphql`
    query ChildrenYouthOneQuery {
      image: file(relativePath: { eq: "children-youth-services-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 370) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageLeft: file(relativePath: { eq: "children-youth-services-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 390) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageRight: file(relativePath: { eq: "children-youth-services-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ThreeImageOverlap
      bg="secondary"
      color="dark"
      imageSide="right"
      image={data.image.childImageSharp.fluid}
      imageLeft={data.imageLeft.childImageSharp.fluid}
      imageRight={data.imageRight.childImageSharp.fluid}
    >
      Young men feel connected to their community by handing out{" "}
      <strong>379</strong> meals during COVID-19 to people who are homeless
    </ThreeImageOverlap>
  )
}

export default One
