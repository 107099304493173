/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#7A68B0"
      d="M84.497 121.534l15.824 9.926c1.726.968 4.082-.585 5.139-2.078 2.214-3.127-.021-5.286-.021-5.286l-13.183-10.269c-.081.081-.162.182-.244.262-2.193 2.28-5.383 5.407-7.515 7.445zm57.893-24.109c2.071-3.208-.204-5.266-.204-5.266l-13.041-9.563c-2.092 2.38-5.342 5.77-7.455 7.949l15.662 9.12s2.864 1.109 5.038-2.24z"
    ></path>
    <path
      fill="#7A68B0"
      d="M115.921 67.807c.975-4.196.102-9.22-4.184-10.793a9.308 9.308 0 00-3.23-.565c-1.767 0-3.372.524-4.692 1.453-3.433-2.422-7.17-5.77-10.421-10.41-1.442-1.897-2.803-4.722-1.34-6.235 2.112-2.179 4.509-1.654 5.89-.242 8.552 8.796 15.601 12.065 24.356 10.37 12.147-2.34 15.174-13.255 17.205-19.408.894-2.704 3.819-3.208 5.505-2.502 1.93.807 2.295 3.752 1.828 6.093-2.702 15.353-12.432 24.089-14.646 26.268-.995 3.934-2.58 10.188-3.27 12.73-.732 2.703-.914 3.026-2.113 4.479-2.559 3.147-10.38 11.096-10.38 11.096l8.125 16.443s1.199 3.026-2.132 4.902c-1.991 1.13-4.165 1.15-5.607-.625l-12.371-20.478c2.397-2.743 4.469-5.629 6.196-8.655 1.97-3.49 4.123-8.917 5.281-13.92z"
    ></path>
    <path
      fill="#7A68B0"
      d="M86.772 82.01c12.29-1.855 15.763-12.75 17.957-18.883.711-1.977 2.295-2.643 3.778-2.643.65 0 1.28.12 1.828.323 1.971.726 2.174 3.853 1.625 6.173-3.25 15.313-13.305 23.746-15.6 25.865-1.138 3.893-2.945 10.128-3.717 12.67-.833 2.663-1.016 2.985-2.275 4.418C87.686 113 79.56 120.706 79.56 120.706l7.577 16.766s1.097 3.087-2.336 4.842c-.874.444-1.788.686-2.62.686-1.24 0-2.337-.505-2.987-1.493l-11.761-20.942c-.549-.908-.549-2.401.02-3.309l9.04-15.272 2.336-8.191S66.378 90.08 57.927 77.088c-1.38-1.957-2.6-4.781-1.137-6.295.914-.948 1.93-1.351 2.925-1.351 1.076 0 2.153.484 2.966 1.311 5.484 5.588 12.086 13.073 24.091 11.258zM21.363 95.932l-13.772 9.885s-2.397 2.159-.224 5.508c2.255 3.49 5.262 2.34 5.262 2.34l16.494-9.421c-2.174-2.3-5.566-5.83-7.76-8.312z"
    ></path>
    <path
      fill="#7A68B0"
      d="M18.5 73.638c1.035 4.096 2.68 10.652 3.412 13.316.772 2.824.955 3.147 2.214 4.68 2.681 3.289 10.868 11.601 10.868 11.601l-8.491 17.189s-1.341 3.329 2.234 5.124c2.357 1.171 4.347 1.271 5.87-.645l13.001-21.386c.61-.928.65-2.481.102-3.429l-8.857-16.241-2.133-8.595s13.082-3.41 22.365-16.624c1.523-1.977 2.823-4.842 1.422-6.517-1.605-1.876-4.347-2.058-6.155-.262-8.917 8.857-16.088 12.67-25.473 10.834-12.716-2.482-15.763-13.9-17.997-20.296-1.056-2.986-4.083-3.35-5.749-2.623-2.01.888-2.397 3.914-1.91 6.375C6.028 62.2 16.184 71.36 18.5 73.638z"
    ></path>
    <path
      fill="#7A68B0"
      d="M32.535 60c6.257 0 11.315-5.024 11.315-11.238 0-6.194-5.058-11.237-11.315-11.237-6.256 0-11.314 5.023-11.314 11.237C21.22 54.956 26.279 60 32.535 60zm38.697-13.013c11.112 0 20.13-8.958 20.13-19.994C91.363 15.959 82.365 7 71.233 7c-11.11 0-20.13 8.958-20.13 19.994 0 11.035 9.02 19.993 20.13 19.993zm-23.421-7.041c.346 0 .711-.08 1.036-.282a2 2 0 00.711-2.764 17.879 17.879 0 01-2.498-9.1c0-1.048.08-2.077.264-3.086a2.044 2.044 0 00-1.646-2.34c-1.096-.182-2.153.544-2.356 1.634a21.177 21.177 0 00-.325 3.773 21.93 21.93 0 003.047 11.156c.406.646 1.077 1.009 1.767 1.009zm47.046-14.042a2.03 2.03 0 001.99 2.058h.041a2.023 2.023 0 002.031-1.977c.062-3.934-.914-7.808-2.864-11.197a2.038 2.038 0 00-2.762-.767 2.009 2.009 0 00-.772 2.744 17.727 17.727 0 012.336 9.139zm24.335 22.273c5.911 0 10.725-4.761 10.725-10.652 0-5.891-4.814-10.652-10.725-10.652-5.932 0-10.726 4.76-10.726 10.652.021 5.87 4.815 10.652 10.726 10.652zM95.446 68.372c0 6.013-4.895 10.875-10.949 10.875-6.053 0-10.949-4.862-10.949-10.875 0-6.012 4.896-10.874 10.95-10.874 6.052 0 10.948 4.862 10.948 10.874z"
    ></path>
  </svg>
)

export default Icon
