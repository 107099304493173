/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#fff"
      d="M119 50.967a43.82 43.82 0 00-6.815-23.54A43.97 43.97 0 0093.782 11.2a44.096 44.096 0 00-24.255-3.86 44.054 44.054 0 00-22.55 9.713A43.889 43.889 0 0033.16 37.31a43.79 43.79 0 00-.78 24.49 43.871 43.871 0 0012.501 21.09 44.04 44.04 0 0021.887 11.12 1.415 1.415 0 011.135 1.388v15.037a1.415 1.415 0 01-1.42 1.415h-8.515a7.105 7.105 0 00-5.019 2.074 7.07 7.07 0 000 10.012 7.108 7.108 0 005.019 2.073h8.516a1.42 1.42 0 011.42 1.416v8.496c0 1.877.747 3.678 2.078 5.005a7.105 7.105 0 0010.036 0 7.068 7.068 0 002.079-5.005v-8.496a1.416 1.416 0 011.42-1.416h8.515a7.108 7.108 0 005.018-2.073 7.07 7.07 0 000-10.012 7.105 7.105 0 00-5.018-2.074h-8.516a1.416 1.416 0 01-1.42-1.415V95.426a1.408 1.408 0 011.136-1.388 44.055 44.055 0 0025.644-15.123A43.86 43.86 0 00119 50.967zm-73.806 0c0-5.88 1.748-11.63 5.023-16.52a29.786 29.786 0 0113.377-10.95 29.874 29.874 0 0117.22-1.692 29.829 29.829 0 0115.262 8.137 29.675 29.675 0 016.462 32.404A29.75 29.75 0 0191.56 75.69 29.859 29.859 0 0175 80.7a29.878 29.878 0 01-21.066-8.718 29.731 29.731 0 01-8.74-21.015z"
    ></path>
  </svg>
)

export default Icon
