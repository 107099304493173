/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#fff"
      d="M90.514 50.918l-12.926 30.93-14.416-34.536c-3.023-5-8.303-6.992-15.73-6.992H36.488c-11.787 0-16.366 4.978-17.571 19.138-.613 7.213-2.344 29.78-2.344 29.78-.241 4.646 1.577 7.478 5.937 8.32.81.154 1.862.198 2.98.066l-3.528 41.816c.132 3.849 2.739 6.77 7.186 7.279 4.492.509 7.778-1.903 8.764-5.62l1.511-9.624 1.25-26.417h1.664l1.183 24.846 1.753 11.195c.986 3.717 4.272 6.129 8.763 5.62 4.426-.509 7.055-3.43 7.186-7.279l-3.417-45.113-.438-25.444 9.092 21.793c1.686 4.182 5.564 6.217 9.53 4.093a6.558 6.558 0 002.585 2.058c3.615 1.637 7.252-.11 8.851-4.137l8.764-20.997-.439 24.537-3.286 43.453c.11 3.695 2.651 6.527 6.923 7.014 4.316.508 7.493-1.815 8.435-5.421l1.687-10.797 1.117-23.917h1.6l1.205 25.466 1.445 9.27c.943 3.584 4.119 5.907 8.435 5.421 4.272-.487 6.792-3.319 6.923-7.014l-3.395-40.29c1.095.133 2.103.09 2.87-.066 4.206-.796 5.959-3.54 5.718-8.009 0 0-1.665-21.749-2.257-28.696-1.139-13.651-5.565-18.452-16.913-18.452h-10.56c-7.164 0-12.269 1.903-15.183 6.726zM41.307 36.537c8.084 0 14.635-6.616 14.635-14.78C55.92 13.615 49.392 7 41.307 7c-8.084 0-14.635 6.615-14.635 14.757 0 8.186 6.551 14.78 14.635 14.78zm70.283 4.004c7.78 0 14.087-6.37 14.087-14.226 0-7.857-6.307-14.226-14.087-14.226-7.781 0-14.088 6.369-14.088 14.226 0 7.857 6.307 14.226 14.088 14.226z"
    ></path>
  </svg>
)

export default Icon
