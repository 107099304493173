/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Box } from "theme-ui"

const Container = ({ children, wide, spaced, sx }, props) => (
  <Box
    {...props}
    sx={{
      maxWidth: wide ? [992, 1200, 1600] : [768, 992, 1200],
      mx: "auto",
      px: spaced ? 3 : 0,
      variant: "layout.container",
      width: "100%",
      ...sx
    }}
  >
    {children}
  </Box>
)

Container.defaultProps = {
  wide: false,
  spaced: true,
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  wide: PropTypes.bool,
  spaced: PropTypes.bool,
}

export default Container
