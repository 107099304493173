/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

const Large = props => (
  <Styled.p
    {...props}
    sx={{
      fontSize: [4, 4, 5],
      ...props.sx,
    }}
  >
    {props.children}
  </Styled.p>
)

export default Large
