/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="300"
    height="300"
    viewBox="0 0 300 300"
    className={className}
    fill="none"
  >
    <mask
      id="a"
      width="234"
      height="154"
      x="33"
      y="137"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#E31B23"
        d="M267 140.143v73.965a20.85 20.85 0 01-10.383 18.034l-96.132 55.833a20.876 20.876 0 01-20.974 0l-96.128-55.829A20.859 20.859 0 0133 214.111V140.14c38.076-1.987 77.148-3.02 116.975-3.02 39.84 0 78.932 1.036 117.025 3.023z"
      ></path>
    </mask>
    <g mask="url(#a)">
      <path
        fill="#E31B23"
        d="M267 140.143v73.965a20.85 20.85 0 01-10.383 18.034l-96.132 55.833a20.876 20.876 0 01-20.974 0l-96.128-55.829A20.859 20.859 0 0133 214.111V140.14c38.076-1.987 77.148-3.02 116.975-3.02 39.84 0 78.932 1.036 117.025 3.023z"
      ></path>
      <path fill="#FFCA10" d="M90.81 264.822H33.8v9.535h57.01v-9.535z"></path>
      <path
        fill="#FEE79B"
        d="M52.804 264.822h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path fill="#FFA200" d="M90.81 264.822H71.808v9.535H90.81v-9.535z"></path>
      <path fill="#FF6C00" d="M90.81 271.973H33.8v2.384h57.01v-2.384z"></path>
      <path
        fill="#FFCA10"
        d="M95.56 255.287H38.553v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M57.555 255.286h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M95.561 255.286H76.558v9.535h19.003v-9.535z"
      ></path>
      <path fill="#FF6C00" d="M95.56 262.438H38.553v2.383H95.56v-2.383z"></path>
      <path fill="#FFCA10" d="M90.81 245.751H33.8v9.535h57.01v-9.535z"></path>
      <path
        fill="#FEE79B"
        d="M52.804 245.751h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path fill="#FFA200" d="M90.81 245.751H71.808v9.535H90.81v-9.535z"></path>
      <path fill="#FF6C00" d="M90.81 252.903H33.8v2.383h57.01v-2.383z"></path>
      <path
        fill="#FFCA10"
        d="M83.684 236.216H26.676v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M45.678 236.216h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M83.685 236.216H64.682v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M83.684 243.367H26.675v2.384h57.01v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M95.56 226.681H38.553v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M57.555 226.681h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M95.561 226.681H76.558v9.535h19.003v-9.535z"
      ></path>
      <path fill="#FF6C00" d="M95.56 233.832H38.553v2.384H95.56v-2.384z"></path>
      <path fill="#FFCA10" d="M90.81 217.146H33.8v9.535h57.01v-9.535z"></path>
      <path
        fill="#FEE79B"
        d="M52.804 217.146h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path fill="#FFA200" d="M90.81 217.146H71.808v9.535H90.81v-9.535z"></path>
      <path fill="#FF6C00" d="M90.81 224.297H33.8v2.384h57.01v-2.384z"></path>
      <path
        fill="#FFCA10"
        d="M95.56 207.611H38.553v9.535h57.009v-9.535z"
      ></path>
      <path fill="#FEE79B" d="M57.555 207.61h-9.502v9.536h9.502v-9.536z"></path>
      <path
        fill="#FFA200"
        d="M95.561 207.61H76.558v9.536h19.003v-9.536z"
      ></path>
      <path fill="#FF6C00" d="M95.56 214.762H38.553v2.383H95.56v-2.383z"></path>
      <path fill="#FFCA10" d="M90.81 198.076H33.8v9.535h57.01v-9.535z"></path>
      <path
        fill="#FEE79B"
        d="M52.804 198.076h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path fill="#FFA200" d="M90.81 198.076H71.808v9.535H90.81v-9.535z"></path>
      <path fill="#FF6C00" d="M90.81 205.227H33.8v2.384h57.01v-2.384z"></path>
      <path fill="#FFCA10" d="M83.733 188.54h-57.01v9.535h57.01v-9.535z"></path>
      <path fill="#FEE79B" d="M45.727 188.54h-9.502v9.535h9.502v-9.535z"></path>
      <path fill="#FFA200" d="M83.733 188.54H64.73v9.535h19.003v-9.535z"></path>
      <path
        fill="#FF6C00"
        d="M83.733 195.691h-57.01v2.384h57.01v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M90.859 179.005H33.85v9.536h57.009v-9.536z"
      ></path>
      <path
        fill="#FEE79B"
        d="M52.853 179.005h-9.501v9.536h9.501v-9.536z"
      ></path>
      <path fill="#FFA200" d="M90.86 179.005H71.857v9.536H90.86v-9.536z"></path>
      <path fill="#FF6C00" d="M90.86 186.157H33.85v2.384h57.01v-2.384z"></path>
      <path fill="#FFCA10" d="M83.733 169.47h-57.01v9.535h57.01v-9.535z"></path>
      <path fill="#FEE79B" d="M45.727 169.47h-9.502v9.535h9.502v-9.535z"></path>
      <path fill="#FFA200" d="M83.733 169.47H64.73v9.535h19.003v-9.535z"></path>
      <path
        fill="#FF6C00"
        d="M83.733 176.621h-57.01v2.384h57.01v-2.384z"
      ></path>
      <path fill="#FFCA10" d="M95.61 159.935H38.603v9.535H95.61v-9.535z"></path>
      <path
        fill="#FEE79B"
        d="M57.605 159.935h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M95.611 159.935H76.608v9.535h19.003v-9.535z"
      ></path>
      <path fill="#FF6C00" d="M95.61 167.087H38.602v2.383H95.61v-2.383z"></path>
      <path fill="#FFCA10" d="M90.859 150.4H33.85v9.535h57.009V150.4z"></path>
      <path fill="#FEE79B" d="M52.853 150.4h-9.501v9.535h9.501V150.4z"></path>
      <path fill="#FFA200" d="M90.86 150.4H71.857v9.535H90.86V150.4z"></path>
      <path fill="#FF6C00" d="M90.86 157.551H33.85v2.384h57.01v-2.384z"></path>
      <path
        fill="#FFCA10"
        d="M155.318 285.691H98.31v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M117.313 285.691h-9.501v9.536h9.501v-9.536z"
      ></path>
      <path
        fill="#FFA200"
        d="M155.32 285.691h-19.003v9.536h19.003v-9.536z"
      ></path>
      <path
        fill="#FFCA10"
        d="M160.07 276.157h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M122.063 276.157h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M160.07 276.157h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M160.069 283.308h-57.008v2.384h57.008v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M155.318 266.621H98.31v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M117.313 266.621h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M155.32 266.621h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M155.319 273.773H98.31v2.383h57.009v-2.383z"
      ></path>
      <path
        fill="#FFCA10"
        d="M148.193 257.087H91.184v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M110.187 257.087h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M148.194 257.087h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M148.193 264.238H91.184v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M160.07 247.551h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M122.063 247.551h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M160.07 247.551h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M160.069 254.702h-57.008v2.384h57.008v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M155.318 238.016H98.31v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M117.313 238.016h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M155.32 238.016h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M155.319 245.167H98.31v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M160.07 228.481h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M122.063 228.481h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M160.07 228.481h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M160.069 235.632h-57.008v2.384h57.008v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M155.318 218.946H98.31v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M117.313 218.946h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M155.32 218.946h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M155.319 226.097H98.31v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M148.242 209.41h-57.01v9.536h57.01v-9.536z"
      ></path>
      <path
        fill="#FEE79B"
        d="M110.236 209.41h-9.502v9.536h9.502v-9.536z"
      ></path>
      <path
        fill="#FFA200"
        d="M148.242 209.41h-19.003v9.536h19.003v-9.536z"
      ></path>
      <path
        fill="#FF6C00"
        d="M148.242 216.562h-57.01v2.383h57.01v-2.383z"
      ></path>
      <path
        fill="#FFCA10"
        d="M155.368 199.876H98.36v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M117.362 199.876h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M155.369 199.876h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M155.368 207.027H98.359v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M148.242 190.34h-57.01v9.535h57.01v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M110.236 190.34h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M148.242 190.34h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M148.242 197.491h-57.01v2.384h57.01v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M160.119 180.806H103.11v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M122.113 180.805h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M160.12 180.805h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M160.119 187.957H103.11v2.383h57.009v-2.383z"
      ></path>
      <path
        fill="#FFCA10"
        d="M155.368 171.27H98.36v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M117.362 171.27h-9.501v9.536h9.501v-9.536z"
      ></path>
      <path
        fill="#FFA200"
        d="M155.369 171.27h-19.003v9.536h19.003v-9.536z"
      ></path>
      <path
        fill="#FF6C00"
        d="M155.368 178.422H98.359v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M221.09 277.47h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M183.084 277.47h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M221.091 277.47h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M221.09 284.621h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M225.841 267.935h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M187.835 267.935h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M225.842 267.935h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M225.841 275.086h-57.008v2.384h57.008v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M221.09 258.399h-57.009v9.536h57.009v-9.536z"
      ></path>
      <path
        fill="#FEE79B"
        d="M183.084 258.399h-9.501v9.536h9.501v-9.536z"
      ></path>
      <path
        fill="#FFA200"
        d="M221.091 258.399h-19.003v9.536h19.003v-9.536z"
      ></path>
      <path
        fill="#FF6C00"
        d="M221.09 265.551h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M213.965 248.865h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M175.959 248.865h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M213.966 248.865h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M213.965 256.016h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M225.841 239.329h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M187.835 239.329h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M225.842 239.329h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M225.841 246.481h-57.008v2.383h57.008v-2.383z"
      ></path>
      <path
        fill="#FFCA10"
        d="M221.09 229.794h-57.009v9.536h57.009v-9.536z"
      ></path>
      <path
        fill="#FEE79B"
        d="M183.084 229.794h-9.501v9.536h9.501v-9.536z"
      ></path>
      <path
        fill="#FFA200"
        d="M221.091 229.794h-19.003v9.536h19.003v-9.536z"
      ></path>
      <path
        fill="#FF6C00"
        d="M221.09 236.946h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M225.841 220.259h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M187.835 220.259h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M225.842 220.259h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M225.841 227.41h-57.008v2.384h57.008v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M221.09 210.724h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M183.084 210.724h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M221.091 210.724h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M221.09 217.876h-57.009v2.383h57.009v-2.383z"
      ></path>
      <path
        fill="#FFCA10"
        d="M214.015 201.189h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M176.009 201.189h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M214.016 201.189h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M214.015 208.34h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M221.139 191.654H164.13v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M183.134 191.654h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M221.141 191.654h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M221.14 198.805h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M214.015 182.119h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M176.009 182.119h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M214.016 182.119h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M214.015 189.27h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M225.891 172.584h-57.008v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M187.885 172.583h-9.501v9.536h9.501v-9.536z"
      ></path>
      <path
        fill="#FFA200"
        d="M225.892 172.583h-19.003v9.536h19.003v-9.536z"
      ></path>
      <path
        fill="#FF6C00"
        d="M225.891 179.735h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M221.139 163.048H164.13v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M183.134 163.048h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M221.141 163.048h-19.003v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M221.14 170.199h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M288.129 260.394H231.12v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M250.123 260.394h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M288.129 267.545H231.12v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M292.879 250.859h-57.008v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M254.873 250.859h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M292.879 258.01H235.87v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M288.129 241.324H231.12v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M250.123 241.324h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M288.129 248.475H231.12v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M281.002 231.789h-57.008v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M242.997 231.789h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path fill="#FFA200" d="M281.003 231.789H262v9.535h19.003v-9.535z"></path>
      <path
        fill="#FF6C00"
        d="M281.003 238.94h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M292.879 222.254h-57.008v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M254.873 222.254h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M292.879 229.405H235.87v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M288.129 212.718H231.12v9.536h57.009v-9.536z"
      ></path>
      <path
        fill="#FEE79B"
        d="M250.123 212.719h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M288.129 219.87H231.12v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M292.879 203.183h-57.008v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M254.873 203.183h-9.501v9.536h9.501v-9.536z"
      ></path>
      <path
        fill="#FF6C00"
        d="M292.879 210.335H235.87v2.383h57.009v-2.383z"
      ></path>
      <path
        fill="#FFCA10"
        d="M288.129 193.648H231.12v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M250.123 193.648h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M288.129 200.799H231.12v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M281.052 184.113h-57.008v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M243.046 184.113h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M281.053 184.113H262.05v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M281.052 191.264h-57.008v2.384h57.008v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M288.178 174.578h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M250.172 174.578h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M288.178 181.729h-57.009v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M281.052 165.043h-57.008v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M243.046 165.043h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FFA200"
        d="M281.053 165.043H262.05v9.535h19.003v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M281.052 172.194h-57.008v2.384h57.008v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M292.928 155.509H235.92v9.535h57.008v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M254.923 155.508h-9.501v9.535h9.501v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M292.929 162.659H235.92v2.384h57.009v-2.384z"
      ></path>
      <path
        fill="#FFCA10"
        d="M288.178 145.973h-57.009v9.535h57.009v-9.535z"
      ></path>
      <path
        fill="#FEE79B"
        d="M250.172 145.973h-9.502v9.535h9.502v-9.535z"
      ></path>
      <path
        fill="#FF6C00"
        d="M288.178 153.124h-57.009v2.384h57.009v-2.384z"
      ></path>
    </g>
    <path
      fill="#E31B23"
      d="M266.788 118.325v23.973h-16.713v-4.511h6.28v-19.462h10.433zM43.45 109.72H33v32.578h10.45V109.72zm189.879 25.346H83.599v7.577h149.73v-7.577zm-81.034-87.274l-.443-13.712h.63v-1.197h-.67l-.48-14.827h-.653V10h-1.197v8.056h-.631l-.717 14.827h-.915v1.197h.858l-.664 13.712a10.454 10.454 0 00-8.02 10.156v.597h20.918v-.597a10.454 10.454 0 00-8.016-10.156zm7.415 11.671h-19.72v1.196h19.72v-1.196z"
    ></path>
    <path
      fill="#E31B23"
      d="M139.99 62.824h7l-4.301 81.534h14.326l-4.305-81.534h7v-1.197h-19.72v1.197zm-11.593 63.758c-7.115-6.521-16.65-10.11-26.892-10.11s-19.777 3.589-26.892 10.11h-3.876v10.572h61.542v-10.572h-3.882z"
    ></path>
    <path
      fill="#E31B23"
      d="M74.72 128.84h-1.254v2.534h1.254v-2.534zm3.048 0h-1.254v2.533h1.254v-2.533zm3.048 0h-1.254v2.533h1.254v-2.533zm3.044 0h-1.254v2.533h1.254v-2.533zm3.048 0h-1.254v2.533h1.254v-2.533zm3.044 0h-1.254v2.533h1.254v-2.533zm3.048 0h-1.255v2.533H93v-2.533zm3.046 0h-1.254v2.533h1.254v-2.533zm3.045 0h-1.254v2.533h1.254v-2.533zm3.051 0h-1.254v2.533h1.254v-2.533zm3.047 0h-1.253v2.533h1.253v-2.533zm3.048 0h-1.254v2.533h1.254v-2.533zm3.043 0h-1.254v2.533h1.254v-2.533zm3.049 0h-1.254v2.533h1.254v-2.533zm3.043 0h-1.253v2.533h1.253v-2.533zm3.048 0h-1.254v2.533h1.254v-2.533zm3.045 0h-1.254v2.533h1.254v-2.533zm3.047 0h-1.254v2.533h1.254v-2.533zm3.048 0h-1.253v2.533h1.253v-2.533zm-.633 4.021H74.09v1.254h54.837v-1.254zm86.988 3.69V88.679h-4.412c0 4.983-7.808 9.014-17.457 9.014v38.861h21.869v-.003zm-30.278-.001v-32.865c-8.047 0-14.557-3.361-14.557-7.516h-3.682v40.381h18.239zm58.937-34.048h-24.646v41.699h24.646v-41.699z"
    ></path>
    <path
      fill="#E31B23"
      d="M253.12 119.9h-11.007v30.329h11.007V119.9zM55.825 147.354v-45.117L40.83 95.566v53.966l14.994-2.178z"
    ></path>
    <path
      fill="#E31B23"
      d="M53.538 101.329v-4.31l-10.42-3.993v4.443l10.42 3.86zm80.749 35.755v-24.425h7.148v26.197l-7.148-1.772zm32.196-16.534h-9.847v15.964h9.847V120.55zm-96.851-12.737H57.014v36.391h12.617v-36.391z"
    ></path>
    <path
      fill="#E31B23"
      d="M88.698 136.021h-24.04v7.536h24.04v-7.536zm111.137-10.264h-11.691v11.758h11.691v-11.758z"
    ></path>
  </svg>
)

export default Icon
