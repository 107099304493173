/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import ImageOverlap from "components/image-overlap"

const Two = () => {
    const data = useStaticQuery(graphql`
    query VolunteersTwoQuery {
      image: file(relativePath: { eq: "volunteers-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <ImageOverlap
            bg="primary"
            color="background"
            imageSide="left"
            image={data.image.childImageSharp.fluid}
        >
            “I volunteer because it makes me feel good but during the pandemic, I volunteered because there are those who need us more than ever”.
        </ImageOverlap>
    )
}

export default Two
