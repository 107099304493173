/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#fff"
      d="M48.593 142.071c-35.542-35.371-35.489-92.667 0-127.985a4.722 4.722 0 015.819-.66L72.15 24.46a4.685 4.685 0 011.978 2.515 4.66 4.66 0 01-.104 3.193l-8.868 22.066a4.687 4.687 0 01-1.928 2.263 4.722 4.722 0 01-2.908.656l-15.273-1.52c-5.76 15.867-5.64 33.355 0 48.891l15.273-1.52a4.72 4.72 0 012.908.656 4.686 4.686 0 011.928 2.263l8.869 22.066a4.661 4.661 0 01.103 3.193 4.686 4.686 0 01-1.978 2.515l-17.738 11.034a4.72 4.72 0 01-5.819-.66zm40.993-112.07a17.372 17.372 0 010 17.718c-1.08 1.823-3.587 2.168-5.122.703l-1.635-1.56a3.281 3.281 0 01-.62-3.924 8.698 8.698 0 000-8.155 3.28 3.28 0 01.62-3.925l1.635-1.56c1.535-1.464 4.041-1.119 5.122.704zm25.119-24.834c16.458 19.502 16.445 47.901 0 67.387a3.308 3.308 0 01-4.804.254l-1.586-1.514a3.264 3.264 0 01-.255-4.46 43.476 43.476 0 000-55.947 3.264 3.264 0 01.255-4.46l1.586-1.514a3.308 3.308 0 014.804.254zm-12.581 12.24a34.78 34.78 0 010 42.907 3.31 3.31 0 01-4.887.355l-1.592-1.52c-1.204-1.148-1.367-3.02-.351-4.338a26.08 26.08 0 000-31.901c-1.016-1.317-.853-3.19.351-4.339l1.592-1.52a3.31 3.31 0 014.887.356z"
    ></path>
  </svg>
)

export default Icon
