/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
		height="150"
		viewBox="0 0 150 150"
        fill="none"
        className={className}
    >
        <path
            fill="#7A68B0"
            d="M39.955 73.364c5.233-4.11 11.846-7.174 19.534-8.947-1.4-.907-2.617-1.793-3.712-2.72a33.43 33.43 0 01-3.935-3.425c-6.288 4.13-10.528 8.744-11.887 15.092zm63.22-37.78C103.175 20.351 90.741 8 75.386 8 60.051 8 47.617 20.352 47.617 35.584c0 15.233 12.434 27.605 27.77 27.605 15.354 0 27.788-12.352 27.788-27.605zM75.394 68.065c-6.167 0-16.228 1.25-21.34 3.566l1.624 15.233c4.401.544 8.56 3.123 11.075 7.334 4.138 6.892 2.353 15.536-4.077 19.666l-14.706 9.712V143h54.828v-19.384l-14.828-9.792c-6.37-4.111-8.154-12.755-4.016-19.626 2.535-4.251 6.734-6.83 11.136-7.354l1.622-15.213c-5.963-2.377-15.111-3.566-21.318-3.566z"
        ></path>
        <path
            fill="#7A68B0"
            d="M16.729 113.119c-1.602 3.687-.953 8.885.385 10.88 3.672 5.36 10.203 6.69 15.497 3.284l27.14-17.912c3.976-2.559 4.97-8.08 2.374-12.412-2.597-4.332-7.952-6.085-12.11-3.849l-10.77 5.985-2.638-4.695 8.946-4.977-1.34-12.613c-7.119 5.218-22.23 24.199-27.484 36.309zm84.055-20.009c-4.158-2.257-9.513-.504-12.11 3.849-2.596 4.332-1.602 9.853 2.374 12.412l27.14 17.912c5.294 3.406 11.846 2.076 15.497-3.284 1.359-1.975 2.008-7.173.385-10.881-5.253-12.089-20.365-31.09-27.464-36.288l-1.339 12.613 8.945 4.977-2.637 4.695-10.791-6.005zm-2.251-35.12c-.953 1.33-2.17 2.58-3.63 3.789-1.076.886-2.232 1.773-3.612 2.64 7.688 1.773 14.28 4.835 19.514 8.946-1.522-7.556-7.526-12.372-12.272-15.374z"
        ></path>
    </svg>
);

export default Icon;
