/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"

import Menu from "components/menu"

const Header = () => (
  <Box
    as="header"
    sx={{
      bg: "none",
      py: 2,
      px: 3,
      left: 0,
      position: "fixed",
      top: 0,
      width: "100%",
      zIndex: 1080,
    }}
  >
    <Flex
      sx={{
        alignItems: "center",
        flexWrap: "nowrap",
        justifyContent: "flex-end",
      }}
    >
      <Menu />
    </Flex>
  </Box>
)

export default Header
