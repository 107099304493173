/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { InfographicImage } from "components/infographic"
import Icon from "components/svgs/newcomer-services-icon-1"

const One = () => {
  const data = useStaticQuery(graphql`
    query NewcomerServicesOneQuery {
      image: file(relativePath: { eq: "newcomer-services-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <InfographicImage
      bg="primary"
      color="background"
      imageSide="right"
      image={data.image.childImageSharp.fluid}
      Icon={Icon}
      iconSize={["100%", "100%"]}
      value=""
    >
      <Box sx={{ pb: [0, 6], pt: [0, 4] }}>
        1368 newcomers acclimated to life in Canada through English language
        classes.
      </Box>
    </InfographicImage>
  )
}

export default One
