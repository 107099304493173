import buttons from "./buttons"
import styles from "./styles"

export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512, 725],
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Jaldi', sans-serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [
    "0.675rem",
    "0.75rem",
    "0.875rem", // H6
    "1.125rem", // p / H5
    "1.375rem", // H4
    "1.875rem", // H3
    "2.5rem", // H2
    "4rem", // H1
    "6.25rem",
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.5,
  },
  colors: {
    text: "#646464",
    background: "#fff",
    primary: "#E31B23",
    secondary: "#FBB034",
    accent: "#7A68B0",
    highlight: "#0055FF",
    dark: "#292929",
    light: "#646464",
    muted: "#f8f8f8",
  },
  breakpoints: ["40em", "56em", "64em"],
  radii: {
    default: "0.3125rem",
  },
  sizes: {
    container: 1200,
  },
  styles,
  buttons,
}
