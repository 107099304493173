/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
		height="150"
		viewBox="0 0 150 150"
        fill="none"
        className={className}
    >
        <path
            fill="#FBB034"
            d="M75.003 47.047c10.528 0 19.066-8.522 19.066-19.032C94.07 17.523 85.531 9 75.003 9c-10.529 0-19.049 8.523-19.049 19.033-.018 10.51 8.52 19.014 19.049 19.014zM35.201 88.855a6.314 6.314 0 004.305-1.684l17.165-15.774v15.058h36.627V71.397l17.165 15.774a6.314 6.314 0 004.305 1.683 6.386 6.386 0 004.699-2.058c2.386-2.579 2.206-6.607-.377-8.989L92.652 53.511a7.21 7.21 0 00-1.04-.788 5.587 5.587 0 00-3.892-1.575H62.285a5.568 5.568 0 00-3.91 1.593c-.36.215-.7.483-1.023.77L30.897 77.807a6.357 6.357 0 00-.377 8.989 6.35 6.35 0 004.681 2.058zm9.973 15.111a8.851 8.851 0 00-2.296 10.277l8.968 20.429a8.938 8.938 0 008.162 5.336 8.764 8.764 0 003.569-.752c4.502-1.97 6.565-7.216 4.592-11.728l-6.35-14.449 6.96-6.069-13.812-11.585-9.793 8.541zm42.996 9.095l-6.35 14.449c-1.973 4.494.071 9.74 4.591 11.727a9.052 9.052 0 003.57.752c3.426 0 6.708-1.987 8.16-5.335l8.969-20.429a8.877 8.877 0 00-2.296-10.277l-9.811-8.559-13.811 11.602 6.977 6.07z"
        ></path>
    </svg>
);

export default Icon;
