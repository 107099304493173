/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { InfographicImage } from "components/infographic"
import Icon from "components/svgs/independent-living-seniors-icon-5"

const One = () => {
  const data = useStaticQuery(graphql`
    query IndependentLivingFourQuery {
      image: file(relativePath: { eq: "independent-living-seniors-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <InfographicImage
      bg="secondary"
      color="dark"
      imageSide="right"
      image={data.image.childImageSharp.fluid}
      Icon={Icon}
      value="9,137"
    >
      seniors improved their mental and physical health through our recreational programs, supportive housing, nutritious meals, transportation and personal care
    </InfographicImage>
  )
}

export default One
