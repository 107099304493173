/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import ImageOverlap from "components/image-overlap"

const One = () => {
  const data = useStaticQuery(graphql`
      query UrbanHealthFiveQuery {
        image: file(relativePath: { eq: "urban-health-5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 445) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

  return (
    <ImageOverlap
      bg="accent"
      color="white"
      image={data.image.childImageSharp.fluid}
      imageSide="left">
      Nasrin Safary, PAID worker, fondly remembers the joy one of her clients felt when talking about decorating his own apartment with roses
    </ImageOverlap>
  )
}

export default One