/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"

import LogoInfo from "components/logo-info"

const One = () => {
  const data = useStaticQuery(graphql`
    query OurMergerOneQuery {
      cnh: file(relativePath: { eq: "logo-cnh.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ths: file(relativePath: { eq: "logo-ths.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      nlss: file(relativePath: { eq: "logo-nlss.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      nlh: file(relativePath: { eq: "logo-nlh.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mow: file(relativePath: { eq: "logo-mow.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sschto: file(relativePath: { eq: "logo-sschto.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      <LogoInfo
        image={data.cnh.childImageSharp.fixed}
        color="#335D3E"
        As={Styled.h2}
        heading="Central Neighbourhood House"
      >
        <Styled.p>
          In 1911, Central Neighbourhood House was founded in response to the
          appalling living conditions of newcomers to Canada living in downtown
          Toronto. Inadequate housing, poverty and lack of city services like
          sewers, water and medical care combined to create an environment of
          despair. The second oldest settlement house in Toronto, Central
          Neighbourhood House was established on the principle that if you want
          to improve the conditions of people living in poverty, you have to
          live and work with them as neighbours. For 109 years, CNH has done
          just that; working with people in an environment of respect to improve
          quality of life on every level.
        </Styled.p>
      </LogoInfo>
      <LogoInfo
        image={data.ths.childImageSharp.fixed}
        color="#335D3E"
        As={Styled.h3}
        heading="Toronto Homemaking Services"
      >
        <Styled.p>
          Toronto Homemaking Service was a joint venture of Central
          Neighbourhood House, Senior Link and WoodGreen Community Services.
          This partnership was formed to provide a greater foundation for
          community-based personal support services operating with the Toronto
          Central Community Care Access Centre. In 2010, the Toronto Homemaking
          Service merged with Central Neighbourhood House to improve access to
          wraparound services and streamline the entire program. Today, our
          Personal Support Worker (PSW) has more than 200 staff.
        </Styled.p>
      </LogoInfo>
      <LogoInfo
        image={data.nlss.childImageSharp.fixed}
        color="#08A94D"
        As={Styled.h2}
        heading="Neighbourhood Link Support Services"
      >
        <Styled.p>
          In 1975, residents of Toronto’s east end decided that it was time to
          take action against the isolation of many of Toronto’s seniors. They
          shared a passion and compassion for their senior neighbours in need
          and felt their personal touch would be more effective than other
          available alternatives. Neighbourhood Link Support Services has always
          centered on the originating principle: that people sometimes need
          practical support to be able to choose to live independently and with
          dignity in their community. The programs and services have expanded
          over the years to support newcomers, youth and the marginally housed.
        </Styled.p>
      </LogoInfo>
      <LogoInfo
        image={data.nlh.childImageSharp.fixed}
        color="#08A94D"
        As={Styled.h3}
        heading="Neighbourhood Link Homes"
      >
        <Styled.p>
          Founded in 1984, Neighbourhood Link Homes operates five non-profit
          housing projects in east Toronto. These supportive housing units
          accommodate the special needs of seniors who are either unable to live
          on their own or who are no longer able to manage in their family home.
          Neighbourhood Link Homes connects seniors living in the unit to the
          essential services offered by The Neighbourhood Group.
        </Styled.p>
      </LogoInfo>
      <LogoInfo
        image={data.mow.childImageSharp.fixed}
        color="#08A94D"
        As={Styled.h3}
        heading="True Davidson Meals on Wheels"
      >
        <Styled.p>
          The True Davidson Meals on Wheels program was founded in East Toronto
          in 1973. Named after Gertrude “True” Davidson, the first mayor of East
          York, the program began with three weekly deliveries. but quickly
          doubled to reflect the need of the community. Over the years, the menu
          has changed to reflect the diversity of the residents who live in the
          East York community. In May 2014, True Davidson merged with
          Neighbourhood Link.
        </Styled.p>
      </LogoInfo>
      <LogoInfo
        image={data.sschto.childImageSharp.fixed}
        color="#7A68B0"
        As={Styled.h2}
        heading="St. Stephen’s Community House"
      >
        <Styled.p>
          St. Stephen’s Community House was started by the Anglican Diocese in
          1962 as a settlement house providing community services, primarily to
          youth. In 1964, St. Stephen’s added English classes for new
          immigrants, along with counselling, and job placement services. On May
          24, 1974, St. Stephen’s became an independent, non-denominational,
          incorporated not-for-profit charitable organization, and a United Way
          Greater Toronto anchor agency. Today the agency addresses the most
          pressing issues in our community: hunger, homelessness, unemployment,
          isolation, conflict, violence, substance use, youth alienation and
          integration of immigrants.
        </Styled.p>
      </LogoInfo>
    </Fragment>
  )
}

export default One
