/** @jsx jsx */
import { jsx } from "theme-ui"

import { Row, Col } from "components/layout"
import { Large, Medium } from "components/type"

import PiggyBank from "components/svgs/community-development-icon-2"
import Child from "components/svgs/community-development-icon-3"
import BackPack from "components/svgs/community-development-icon-4"

const styles = {
  col: { width: ["100%", "30%"], px: [3, 4], my: [3, 4] },
  value: {
    color: "dark",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "heading",
  },
  icon: { mr: 3, height: "50px", width: "auto" },
  text: { fontWeight: "heading", color: "dark", textAlign: "center" },
}

const Four = () => (
  <Row
    sx={{ justifyContent: "center", my: 4, flexDirection: ["column", "row"] }}
  >
    <Col sx={styles.col}>
      <Large sx={styles.value}>
        <PiggyBank sx={styles.icon} />
        21%
      </Large>
      <Medium sx={styles.text}>
        That’s the increase in childcare fees since 2014 – six times the
        increase in inflation.
      </Medium>
    </Col>
    <Col sx={styles.col}>
      <Large sx={styles.value}>
        <Child sx={styles.icon} />
        $21,000
      </Large>
      <Medium sx={styles.text}>
        That’s the cost for infant care in Toronto - almost 1/3 of the city’s
        median household income.
      </Medium>
    </Col>
    <Col sx={styles.col}>
      <Large sx={styles.value}>
        <BackPack sx={styles.icon} />
        26%
      </Large>
      <Medium sx={styles.text}>
        That’s the number of children in Toronto who belong to low-income
        families – yet only 8% of families in Toronto receive childcare
        subsidies.
      </Medium>
    </Col>
  </Row>
)

export default Four
