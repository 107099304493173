/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
	height="38"
	viewBox="0 0 38 38"
    fill="none"
    className={className}
  >
    <path
      fill="#E31B23"
      d="M7.076 19.654H0a19 19 0 003.604 10.512c.363-.268 2.101-1.476 5.291-2.463a21.665 21.665 0 01-1.82-8.05zm12.356 17.271c1.52-1.11 4.909-3.911 7.245-8.298a35.133 35.133 0 00-7.245-.92v9.218zm-8.619-8.345c2.337 4.42 5.75 7.235 7.277 8.346v-9.224c-2.887.034-5.31.394-7.277.877zm1.809 5.07a24.787 24.787 0 01-3.139-4.709c-3.032.915-4.704 2.038-5.05 2.287 3.128 3.736 7.66 6.252 12.782 6.73a26.783 26.783 0 01-4.593-4.307zm-.125-29.334A26.547 26.547 0 0116.997 0C11.902.473 7.443 2.97 4.37 6.687c.106.08 1.788 1.316 5.07 2.314a24.831 24.831 0 013.058-4.685zm-3.635 5.93C5.48 9.195 3.74 7.899 3.55 7.753A19.235 19.235 0 000 18.307h7.076a22.04 22.04 0 011.786-8.061zm16.161-5.93a24.805 24.805 0 013.045 4.658c2.94-.835 4.82-1.828 5.447-2.187C30.321 3.014 25.693.474 20.523 0a26.466 26.466 0 014.5 4.316zm5.423 13.992H38a18.775 18.775 0 00-3.643-10.454c-.502.305-2.473 1.428-5.708 2.369a22.06 22.06 0 011.797 8.085zM8.42 19.654a20.23 20.23 0 001.785 7.682c2.112-.539 4.735-.944 7.884-.98v-6.702h-9.67zm20.194 8.074c3.403.984 5.413 2.173 5.8 2.413A19.003 19.003 0 0038 19.654h-7.555a21.634 21.634 0 01-1.831 8.074zM18.089 11.605c-3.165-.035-5.8-.446-7.917-.988a20.582 20.582 0 00-1.753 7.69h9.67v-6.702zM24.9 33.65A26.816 26.816 0 0120.247 38c5.35-.347 10.096-2.912 13.334-6.788-.557-.325-2.487-1.37-5.56-2.239A24.84 24.84 0 0124.9 33.65zM18.089.873c-1.36.986-4.915 3.858-7.318 8.493 1.977.49 4.415.858 7.318.893V.873zm1.343 0v9.38a35.088 35.088 0 007.295-.931c-2.402-4.61-5.94-7.467-7.295-8.449zm0 17.435h9.67a20.6 20.6 0 00-1.771-7.732 36.47 36.47 0 01-7.899 1.024v6.708zm0 8.053a36.54 36.54 0 017.86 1.015 20.201 20.201 0 001.809-7.722h-9.669v6.707z"
    ></path>
  </svg>
)

export default Icon
