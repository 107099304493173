/** @jsx jsx */
import { jsx } from "theme-ui"
import BackgroundImage from "gatsby-background-image"

import { Container, Row, Col } from "components/layout"
import Panel from "components/panel"
import { Medium } from "components/type"

const ImageOverlap = ({ bg, color, image, imageSide, children }) => (
  <Panel bg={bg} color={color} sx={{ my: [4, 6] }}>
    <Container>
      <Row sx={{ alignItems: "stretch" }}>
        <Col
          sx={{
            width: ["100%", "50%"],
            py: 5,
            order: imageSide === "left" ? 2 : 1,
          }}
        >
          <Medium>{children}</Medium>
        </Col>
        <Col
          sx={{
            width: ["100%", "50%"],
            display: ["none", "flex"],
            order: imageSide === "left" ? 1 : 2,
          }}
        >
          <BackgroundImage
            fluid={image}
            backgroundColor="#fff"
            sx={{
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "100%",
              my: [0, -5],
            }}
          />
        </Col>
      </Row>
    </Container>
  </Panel>
)

ImageOverlap.defaultProps = {
  bg: "primary",
  color: "background",
  imageSide: "left",
}

export { default as ThreeImageOverlap } from "./three-images"
export default ImageOverlap
