/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#fff"
      d="M75 7a68 68 0 1068 68A68.072 68.072 0 0075 7zm0 116.167a7.086 7.086 0 01-6.947-8.466 7.078 7.078 0 015.565-5.565 7.087 7.087 0 017.272 3.012A7.083 7.083 0 0175 123.167zm0-42.5a5.667 5.667 0 01-4.006-1.66l-17-17a5.667 5.667 0 018.012-8.008l15.34 15.334h37.321a5.667 5.667 0 010 11.334H75zM41 75a7.084 7.084 0 11-14.168 0A7.084 7.084 0 0141 75zm34-48.167A7.083 7.083 0 1175 41a7.083 7.083 0 010-14.167z"
    ></path>
  </svg>
)

export default Icon
