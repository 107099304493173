/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import { InfographicImage } from "components/infographic"
import Icon from "components/svgs/independent-living-seniors-icon-2"

const One = () => {
    const data = useStaticQuery(graphql`
    query IndependentLivingTwoQuery {
      image: file(relativePath: { eq: "independent-living-seniors-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <InfographicImage
            bg="secondary"
            color="dark"
            imageSide="right"
            image={data.image.childImageSharp.fluid}
            Icon={Icon}
            value="130,000"
        >
            visits by Personal Support Workers each year help seniors feel safe and secure knowing that help is always available
        </InfographicImage>
    )
}

export default One
