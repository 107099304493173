/** @jsx jsx */
import { jsx, Flex } from "theme-ui"

const Row = props => (
  <Flex
    {...props}
    sx={{
      mx: -3,
      ...props.sx,
    }}
  >
    {props.children}
  </Flex>
)

export default Row
