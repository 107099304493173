/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
		height="150"
		viewBox="0 0 150 150"
        fill="none"
        className={className}
    >
        <path
            fill="#292929"
            d="M65.994 79.57a49.511 49.511 0 01-.258-5.068c0-12.685 4.638-22.965 10.362-22.965 3.463 0 6.525 3.764 8.407 9.545l10.57-10.556C93.05 33.114 87.954 16.61 76.102 3 58.888 22.76 55.925 48.63 55.925 74.498c0 4.92.108 9.846.4 14.724l9.67-9.653zm20.214-10.14c.168 1.63.258 3.326.258 5.068 0 12.684-4.638 22.965-10.362 22.965-3.463 0-6.525-3.764-8.407-9.545l-10.57 10.556C59.152 115.88 64.247 132.39 76.1 146c17.215-19.761 20.178-45.629 20.178-71.498 0-4.92-.108-9.846-.4-14.724l-9.67 9.653z"
        ></path>
        <path
            fill="#292929"
            d="M117.074 41.695c1.103-.623 2.167-1.475 3.11-2.417l16.151-16.13c1.972-1.97 2.588-4.547 1.378-5.756l-2.519-2.516-2.516-2.512c-1.21-1.209-3.79-.593-5.762 1.377l-16.151 16.13c-.948.946-1.796 2.009-2.421 3.106-2.597 4.576-3.889 8.318-4.53 10.757L44.697 102.78c-2.442.64-6.189 1.931-10.772 4.525-1.102.623-2.166 1.475-3.11 2.417l-16.15 16.13c-1.972 1.97-2.588 4.547-1.378 5.756l2.52 2.516 2.515 2.512c1.21 1.209 3.79.593 5.762-1.377l16.151-16.13c.948-.946 1.796-2.009 2.42-3.105 2.598-4.577 3.89-8.319 4.531-10.758l59.121-59.046c2.442-.64 6.189-1.93 10.767-4.525z"
        ></path>
    </svg>
);

export default Icon;
