/** @jsx jsx */
import { jsx, Flex, Box, Button, Styled } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import VisuallyHidden from "@reach/visually-hidden"

import { Container, Row, Col } from "components/layout"

// Social
import Facebook from "components/svgs/facebook"
import Twitter from "components/svgs/twitter"

// Logos
import Tng from "components/svgs/logo-tng"
import Sschto from "components/svgs/logo-sschto"
import Nlss from "components/svgs/logo-nlss"
import Cnh from "components/svgs/logo-cnh"

// Contact
import Map from "components/svgs/footer-map"
import Phone from "components/svgs/footer-phone"
import Envelope from "components/svgs/footer-envelope"
import Globe from "components/svgs/footer-globe"

import Wheelchair from "components/svgs/footer-wheelchair"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      uw: file(relativePath: { eq: "logo-united-way.png" }) {
        childImageSharp {
          fixed(width: 350) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      carf: file(relativePath: { eq: "logo-carf.png" }) {
        childImageSharp {
          fixed(width: 350) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Box as="footer">
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          my: 3,
        }}
      >
        <Button
          variant="social"
          as="a"
          href="https://www.facebook.com/TheNeighbourhoodGroup/"
        >
          <Facebook />
          Facebook
        </Button>
        <Button
          variant="social"
          as="a"
          href="https://twitter.com/NeighbourhdGrp"
        >
          <Twitter />
          Twitter
        </Button>
      </Flex>
      <Box sx={{ bg: "muted", py: 4 }}>
        <Container>
          <Row sx={{ mb: 3 }}>
            <Col sx={{ width: ["100%", "50%"] }}>
              <Button
                variant="primary"
                as="a"
                href="https://cause2give.unxvision.com/EDMWeb/DonationForm.aspx?FormID=1175"
                sx={{ display: "block", fontSize: [3, 4, 4], py: 3 }}
              >
                Donate Now
              </Button>
            </Col>
            <Col sx={{ width: ["100%", "50%"] }}>
              <Button
                variant="secondary"
                as="a"
                href="http://theneighbourhoodgroup.org/"
                sx={{ display: "block", fontSize: [3, 4, 4], py: 3 }}
              >
                View Our Main Site
              </Button>
            </Col>
          </Row>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "space-around",
              my: 5,
              flexWrap: ["wrap", "wrap", "nowrap"],
            }}
          >
            <Tng sx={{ mb: 3, width: ["50%", "50%", "25%"] }} />
            <Nlss sx={{ mb: 3, width: ["50%", "50%", "25%"] }} />
            <Cnh sx={{ mb: 3, width: ["50%", "50%", "25%"] }} />
            <Sschto sx={{ mb: 3, width: ["50%", "50%", "25%"] }} />
          </Flex>
          <VisuallyHidden>
            <Styled.h2>Contact Information</Styled.h2>
          </VisuallyHidden>
          <Row sx={{ my: 5, flexWrap: ["wrap", "wrap", "nowrap"] }}>
            <Col
              sx={{
                width: ["100%", "50%", "25%"],
                textAlign: ["center", "left", "left"],
              }}
            >
              <Map />
              <Styled.h3 sx={{ fontWeight: "heading" }}>Address</Styled.h3>
              <Styled.p>
                <Styled.a href="#">
                  349 Ontario St.,
                  <br />
                  Toronto, ON M5A 2V8
                </Styled.a>
              </Styled.p>
            </Col>
            <Col
              sx={{
                width: ["100%", "50%", "25%"],
                textAlign: ["center", "left", "left"],
              }}
            >
              <Phone />
              <Styled.h3 sx={{ fontWeight: "heading" }}>Phone</Styled.h3>
              <Styled.p>
                <Styled.a href="tel:4169254363">416.925.4363</Styled.a>
              </Styled.p>
            </Col>
            <Col
              sx={{
                width: ["100%", "50%", "25%"],
                textAlign: ["center", "left", "left"],
              }}
            >
              <Envelope />
              <Styled.h3 sx={{ fontWeight: "heading" }}>Email</Styled.h3>
              <Styled.p>
                <Styled.a href="mailto:execassistant@tngcs.org">
                  execassistant@tngcs.org
                </Styled.a>
              </Styled.p>
            </Col>
            <Col
              sx={{
                width: ["100%", "50%", "25%"],
                textAlign: ["center", "left", "left"],
              }}
            >
              <Globe />
              <Styled.h3 sx={{ fontWeight: "heading" }}>Website</Styled.h3>
              <Styled.p>
                <Styled.a href="https://www.theneighbourhoodgroup.org">
                  www.theneighbourhoodgroup.org
                </Styled.a>
              </Styled.p>
            </Col>
          </Row>
        </Container>
      </Box>
      <Container sx={{ py: 5 }}>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: ["center", "space-around"],
            my: 5,
            flexDirection: ["column", "row"],
          }}
        >
          <Img fixed={data.carf.childImageSharp.fixed} alt="CARF" />
          <Img
            fixed={data.uw.childImageSharp.fixed}
            alt="United Way Greater Toronto"
          />
        </Flex>
        <Styled.p
          sx={{
            alignItems: "center",
            fontSize: [2, 2, 2],
            textAlign: "center",
            color: "primary",
            display: "flex",
            justifyContent: "center",
            fontWeight: "heading",
            width: "100%",
          }}
        >
          <Wheelchair sx={{ mr: 2 }} />
          WCAG 2.1 Level AA Compliant
        </Styled.p>
        <Styled.p sx={{ fontSize: [2, 2, 2], textAlign: "center" }}>
          &copy;2020 The Neighbourhood Group, Report by{" "}
          <strong>
            <a href="https://floating-point.com">Floating-Point</a>
          </strong>
          .
        </Styled.p>
        <Styled.p sx={{ fontSize: [2, 2, 2], textAlign: "center" }}>
          Charitable Registration Number: 1068 87284 RR0002M
        </Styled.p>
      </Container>
    </Box>
  )
}

export default Footer
