/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
  >
    <rect
      width="28.285"
      height="3.239"
      x="2.465"
      y="1.21"
      fill="#fff"
      rx="1.62"
      transform="rotate(40.956 2.465 1.21)"
    ></rect>
    <rect
      width="28.285"
      height="3.239"
      fill="#fff"
      rx="1.62"
      transform="scale(-1 1) rotate(40.956 -12.472 -28.453)"
    ></rect>
  </svg>
)

export default Icon
