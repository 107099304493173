/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#fff"
      d="M133.062 68.849V21.185c0-5.089-4.122-9.185-9.175-9.185H19.218c-5.052 0-9.174 4.127-9.174 9.185v105.63c0 5.089 4.122 9.185 9.174 9.185h104.669c5.084 0 9.175-4.127 9.175-9.185h7.873V86.04l-7.873-17.191zm-1.395 48.657H42.092l-.526-27.866-13.142-49.06V24.226h80.927l-.062 14.957 22.347 48.874v29.449h.031z"
    ></path>
    <path
      fill="#fff"
      d="M122.989 49.145h-23.06l8.647 17.905h23.06c-2.355-6.517-5.238-12.537-8.647-17.905zm-34.28-9.217H37.66l3.192 9.216h41.781v29.014H51.019l4.557 13.033h55.79L88.709 39.928z"
    ></path>
  </svg>
)

export default Icon
