/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
		height="150"
		viewBox="0 0 150 150"
        fill="none"
        className={className}
    >
        <path
            fill="#E31B23"
            d="M141.049 88.164l-7.218-1.161c-.874-4.806-2.828-9.384-5.701-13.552l3.218-8.655c.759-2.027-.414-3.507-2.575-3.257l-11.079 1.208c-4.873-3.645-10.643-6.628-17.011-8.77 6.644-4.099 11.08-11.387 11.08-19.7C111.74 21.454 101.212 11 88.247 11c-10.137 0-18.78 6.4-22.067 15.328-7.747 3.804-13.103 11.707-13.103 20.84 0 2.346.368 4.6 1.012 6.719-19.953 6.628-33.952 21.523-33.952 38.742v.159c-4.023 1.002-9.494 1.139-12.666-3.986-1.126-1.845-1.425-1.116-1.471-.57 0 0 .299 17.242 16.505 16.422 2.114 5.261 5.563 10.113 10.068 14.303V139h12.16l4.965-9.247c8.184 3.371 17.562 5.307 27.539 5.307 9.976 0 19.378-1.936 27.561-5.307l4.988 9.247h12.16v-20.043c1.793-1.685 3.448-3.484 4.874-5.352l9.792 1.572c1.931.318 3.77-1.003 4.069-2.916l3.264-20.042a3.476 3.476 0 00-2.896-4.055zm-52.802-67.62c7.632 0 13.862 6.148 13.862 13.733 0 2.96-.966 5.671-2.575 7.903-2.138-9.68-10.367-17.105-20.481-18.175 2.46-2.14 5.677-3.462 9.194-3.462zm14.16 44.526c-.459 1.23-1.839 1.89-3.08 1.435a65.26 65.26 0 00-22.067-3.827 65.26 65.26 0 00-22.068 3.827 2.626 2.626 0 01-.827.136 2.418 2.418 0 01-2.276-1.571 2.359 2.359 0 011.448-3.052 70.31 70.31 0 0115.125-3.599c-3.585-2.482-5.93-6.605-5.93-11.251 0-7.562 6.206-13.734 13.861-13.734 7.632 0 13.861 6.15 13.861 13.734 0 4.578-2.275 8.61-5.747 11.115a69.965 69.965 0 0116.275 3.712c1.219.456 1.885 1.822 1.425 3.075zm15.815 24.074c-2.574 0-4.689-2.073-4.689-4.647 0-2.55 2.092-4.646 4.689-4.646 2.575 0 4.69 2.073 4.69 4.647 0 2.573-2.115 4.646-4.69 4.646z"
        ></path>
    </svg>
);

export default Icon;
