/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    fill="none"
    viewBox="0 0 150 150"
    className={className}
  >
    <path
      fill="#fff"
      d="M109.347 40.56c-9.595-1.673-26.481 3.842-34.346 8.243-7.864-4.401-24.753-9.919-34.348-8.244-21.94 3.827-29.01 33.31-25.14 55.014C19.383 117.276 32.067 143 57.828 143c3.257 0 6.514-1.234 9.301-2.767a16.405 16.405 0 0115.747 0c2.787 1.533 6.044 2.767 9.298 2.767 25.761 0 38.446-25.725 42.313-47.428 3.87-21.703-3.195-51.187-25.14-55.014zM94.346 29.446c8.253-8.177 6.405-23.271 6.405-23.271s-15.234-1.833-23.487 6.347c-8.255 8.18-6.405 23.27-6.405 23.27s15.231 1.834 23.487-6.346z"
    ></path>
  </svg>
)

export default Icon
