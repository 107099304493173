/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <path
      fill="#fff"
      d="M128.54 38.36h-21.134a1.407 1.407 0 01-1.409-1.408v-1.409A22.542 22.542 0 0083.454 13H66.547a22.543 22.543 0 00-22.543 22.543v1.409a1.409 1.409 0 01-1.41 1.409H21.46A14.09 14.09 0 007.371 52.45v25.36a8.453 8.453 0 008.454 8.454h46.213a1.42 1.42 0 001.127-.564 14.011 14.011 0 0123.603 0 1.42 1.42 0 001.127.564h46.213a8.457 8.457 0 007.871-5.203c.429-1.03.65-2.135.65-3.25V52.45a14.09 14.09 0 00-14.089-14.09zM66.547 24.273h16.907a11.272 11.272 0 0111.159 9.66 1.408 1.408 0 01-1.398 1.611h-36.43a1.408 1.408 0 01-1.397-1.612 11.272 11.272 0 0111.158-9.66zM75 115.853a17.107 17.107 0 01-15.498-18.316c0-.446 0-.885.04-1.325a1.408 1.408 0 00-1.41-1.493h-39.49a2.817 2.817 0 00-2.817 2.417c-.344 2.396-.761 4.83-1.173 7.259-1.938 11.339-3.764 22.053 1.41 28.179a13.936 13.936 0 0011.265 4.413h95.346a13.947 13.947 0 0011.238-4.402c5.179-6.137 3.347-16.851 1.409-28.178-.412-2.429-.829-4.864-1.173-7.26a2.812 2.812 0 00-2.789-2.428h-39.49a1.413 1.413 0 00-1.409 1.493c0 .44.04.88.04 1.325A17.107 17.107 0 0175 115.853z"
    ></path>
    <path
      fill="#fff"
      d="M75 107.399c3.89 0 7.045-4.416 7.045-9.863 0-5.447-3.154-9.862-7.045-9.862-3.89 0-7.044 4.415-7.044 9.862S71.11 107.4 75 107.4z"
    ></path>
  </svg>
)

export default Icon
