/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    className={className}
    fill="none"
  >
    <path
      fill="currentColor"
      d="M149 17.1v115.8c0 8.722-7.147 15.791-15.964 15.791H15.964C7.147 148.69 0 141.621 0 132.899V17.101C0 8.379 7.147 1.31 15.964 1.31h117.072C141.853 1.31 149 8.38 149 17.1zm-29.268 5.264h-37.24c-7.089 0-10.67 8.508-5.645 13.479l10.638 10.522-65.03 64.324a3.918 3.918 0 000 5.583l10.32 10.207a4.022 4.022 0 005.644 0l65.03-64.322 10.639 10.524c4.998 4.943 13.626 1.472 13.626-5.583V30.26c0-4.361-3.573-7.896-7.982-7.896z"
    ></path>
  </svg>
)

export default Icon
