/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    className={className}
  >
    <g fill="#fff" clip-path="url(#clip0)">
      <path d="M19.354 49.918C20.949 43.31 19.216 22 19.216 22H16.94v21.31h-2.13V22h-2.64v21.31H9.86V22H7.22v21.31H5.086V22H2.81s-1.733 21.31-.137 27.918c1.602 6.608 5.974 8.924 5.974 8.924s-7.92 67.732 2.373 67.732c10.292 0 2.372-67.732 2.372-67.732s4.366-2.316 5.96-8.924zm63.669 51.996c15.657-6.53 23.134-24.704 16.7-40.594-6.435-15.89-24.344-23.477-40-16.947-15.658 6.53-23.134 24.705-16.7 40.594 6.435 15.89 24.343 23.477 40 16.947z"></path>
      <path d="M71.375 24.819c-26.3 0-47.62 21.638-47.62 48.327 0 26.69 21.32 48.328 47.62 48.328 26.3 0 47.621-21.638 47.621-48.328S97.674 24.82 71.375 24.82zm24.972 73.67c-6.67 6.769-15.539 10.495-24.972 10.495-9.433 0-18.303-3.726-24.972-10.495-6.67-6.768-10.341-15.77-10.341-25.343s3.671-18.574 10.34-25.343c6.67-6.768 15.54-10.494 24.973-10.494s18.303 3.726 24.972 10.494c6.67 6.769 10.341 15.77 10.341 25.343 0 9.574-3.671 18.568-10.34 25.343zM147 42.096c0-9.706-3.238-14.793-6.511-17.458a10.641 10.641 0 00-6.717-2.366c-2.393 0-4.786.789-6.718 2.366-3.273 2.665-6.511 7.752-6.511 17.458 0 14.974 9.53 13.404 10.685 18.553-.75 6.469-6.883 66.351 2.496 66.351h.11c9.378 0 3.245-59.875 2.495-66.35C137.471 55.5 147 57.07 147 42.095z"></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0h145v105H0z"
          transform="translate(2 22)"
        ></path>
      </clipPath>
    </defs>
  </svg>
)

export default Icon
