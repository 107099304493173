/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

const Small = props => (
  <Styled.p
    {...props}
    sx={{
      fontSize: [0, 1],
      ...props.sx,
    }}
  >
    {props.children}
  </Styled.p>
)

export default Small
