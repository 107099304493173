/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

import ImageOverlap from "components/image-overlap"

const Two = () => {
  const data = useStaticQuery(graphql`
    query ChildrenYouthTwoQuery {
      image: file(relativePath: { eq: "children-youth-services-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ImageOverlap
      bg="accent"
      color="background"
      imageSide="left"
      image={data.image.childImageSharp.fluid}
    >
      The peer-led Youth Council from Youth Awoken develops programing that
      engages young people in a wide variety of activities and workshops to help
      them cope with substance use, family dynamics, sexual awareness and mental
      health.
    </ImageOverlap>
  )
}

export default Two