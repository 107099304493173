/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const Panel = ({ children, bg, color, nospace, className }) => (
  <Box
    sx={{
      bg,
      color,
      my: 4,
      px: 3,
      py: nospace ? 0 : 4,
      "h1, h2, h3, h4, h5, h6, a": {
        color,
      },
      "& h2:first-of-type": {
        mt: 0,
      },
    }}
    className={className}
  >
    {children}
  </Box>
)

Panel.defaultProps = {
  bg: "primary",
  color: "background",
  nospace: false,
}

export default Panel
