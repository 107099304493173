export default {
  primary: {
    color: "background",
    bg: "primary",
    borderRadius: "default",
    fontWeight: "heading",
    transition: "all 100ms ease-in",
    "&:hover": {
      bg: "dark",
      color: "background",
      cursor: "pointer",
    },
  },
  secondary: {
    variant: "buttons.primary",
    bg: "secondary",
    color: "dark",
  },
  accent: {
    variant: "buttons.primary",
    bg: "accent",
    color: "background",
  },
  underline: {
    variant: "buttons.primary",
    bg: "rgba(0,0,0,0)",
    border: "thin solid",
    borderColor: "transparent",
    borderBottomColor: "primary",
    borderRadius: 0,
    color: "primary",
    "&::after": {
      content: '" >"',
      display: "inline-block",
      ml: 1,
    },
    "&:hover": {
      bg: "primary",
      border: "thin solid",
      borderRadius: "default",
      color: "background",
      cursor: "pointer",
    },
  },
  underlineDark: {
    variant: "buttons.underline",
    borderBottomColor: "dark",
    color: "dark",
    "&:hover": {
      bg: "dark",
      borderRadius: "default",
      color: "background",
    },
  },
  underlineLight: {
    variant: "buttons.underline",
    borderBottomColor: "background",
    color: "background",
    "&:hover": {
      bg: "background",
      borderRadius: "default",
      color: "dark",
    },
  },
  ghost: {
    variant: "buttons.primary",
    bg: "rgba(0,0,0,0)",
    border: "thin solid",
    borderColor: "dark",
    color: "dark",
    "&:hover": {
      bg: "secondary",
      borderColor: "secondary",
      color: "dark",
      cursor: "pointer",
    },
  },
  return: {
    variant: "buttons.ghost",
    color: "background",
    borderColor: "background",
    fontSize: [2, 3, 4],
    py: [1, 2, 3],
    px: [1, 2, 3],
    "&:hover": {
      bg: "background",
      borderColor: "background",
      color: "accent",
      cursor: "pointer",
    },
  },
  menu: {
    variant: "buttons.ghost",
    display: "block",
    borderWidth: "medium",
    width: "100%",
    "&:hover": {
      bg: "dark",
      color: "background",
    },
  },
  accordion: {
    background: "none",
    color: "dark",
    border: "none",
    borderRadius: "default",
    fontSize: [3, 4, 5],
    fontWeight: 700,
    mb: 2,
    py: 2,
    px: 3,
    "&:hover": {
      bg: "dark",
      color: "background",
      cursor: "pointer",
    },
  },
  social: {
    variant: "buttons.primary",
    bg: "background",
    color: "dark",
    display: "inline-flex",
    alignItems: "center",
    mx: 3,
    "&>svg": {
      mr: 2,
      height: "1.3rem",
      width: "auto",
    },
    "&:hover": {
      bg: "muted",
      color: "dark",
    },
  },
}
