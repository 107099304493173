/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import PropTypes from "prop-types"

const ExtraLarge = ({ color, children, sx, as }, props) => (
  <Styled.p
    {...props}
    as={as}
    sx={{
      color,
      fontFamily: "heading",
      fontSize: [6, 7, 8],
      fontWeight: "heading",
      ...sx,
    }}
  >
    {children}
  </Styled.p>
)

ExtraLarge.propTypes = {
  color: PropTypes.string,
}

ExtraLarge.defaultProps = {
  color: "accent",
}

export default ExtraLarge
