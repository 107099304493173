/** @jsx jsx */
// @ts-ignore
import React, { useState, Fragment } from "react"
import { jsx, Box, Button, useThemeUI } from "theme-ui"
import { Link } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion"
import { DialogContent, DialogOverlay } from "@reach/dialog"
import { useTransition, animated } from "react-spring"
import VisuallyHidden from "@reach/visually-hidden"
import External from "components/svgs/external"

import Bars from "components/svgs/bars"
import Close from "components/svgs/close"

import "@reach/accordion/styles.css"
import "@reach/dialog/styles.css"

import { menuItems, styles } from "./data"

const Menu = () => {
  const { theme } = useThemeUI()
  const AnimatedDialogOverlay = animated(DialogOverlay)
  const AnimatedDialogContent = animated(DialogContent)
  const [showDialog, setShowDialog] = useState(false)
  const transitions = useTransition(showDialog, null, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
  })
  const path = typeof window !== "undefined" ? window.location.pathname : ""

  return (
    <Fragment>
      <Button
        variant="accent"
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 3,
          mr: 2,
        }}
        onClick={() => setShowDialog(!showDialog)}
      >
        <VisuallyHidden>{showDialog ? "Close " : "Open "}</VisuallyHidden>Menu{" "}
        {showDialog ? (
          <Close sx={styles.menuIcon} />
        ) : (
          <Bars sx={styles.menuIcon} />
        )}
      </Button>
      {transitions.map(
        ({ item, key, props: styles }) =>
          item && (
            <AnimatedDialogOverlay
              key={key}
              style={{
                background: theme.colors.secondary,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                opacity: styles.opacity,
              }}
            >
              <AnimatedDialogContent
                aria-label="Menu"
                style={{
                  background: "none",
                  transform: styles.y.interpolate(
                    value => `translate3d(0px, ${value}px, 0px)`
                  ),
                  padding: "3rem 1rem",
                  margin: "0 auto",
                  width: "auto",
                  maxWidth: "90%",
                }}
              >
                <Accordion
                  collapsible
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {menuItems.map(item => (
                    <Fragment key={item.label}>
                      {item.subItems.length > 0 ? (
                        <AccordionItem sx={{ mb: 3, textAlign: "center" }}>
                          <Button as={AccordionButton} variant="accordion">
                            {item.label}
                          </Button>
                          <AccordionPanel>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              {item.subItems.map(subItem => (
                                <Button
                                  as={Link}
                                  variant="menu"
                                  key={subItem.label}
                                  to={subItem.to}
                                  disabled={subItem.to === path}
                                  sx={{
                                    m: 2,
                                    width: "calc(50% - 1rem)",
                                    bg: subItem.to === path ? "dark" : null,
                                    color:
                                      subItem.to === path ? "background" : null,
                                  }}
                                >
                                  {subItem.label}
                                </Button>
                              ))}
                            </Box>
                          </AccordionPanel>
                        </AccordionItem>
                      ) : (
                        <>
                          {item.external ? (
                            <Button
                              variant="accordion"
                              href={item.to}
                              as="a"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {item.label}
                              <External
                                sx={{
                                  ml: 3,
                                  height: "1.2rem",
                                  width: "1.2rem",
                                }}
                              />
                            </Button>
                          ) : (
                            <Button variant="accordion" as={Link} to={item.to}>
                              {item.label}
                            </Button>
                          )}
                        </>
                      )}
                    </Fragment>
                  ))}
                </Accordion>
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </Fragment>
  )
}

export default Menu
